// stylelint-disable no-descending-specificity

@import "./styles/theme";

.Flex-flex {
  display: -ms-flexbox;
  display: flex;
}

.Flex-inlineFlex {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.Flex-alignContent--flexStart {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.Flex-alignContent--flexEnd {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.Flex-alignContent--center {
  -ms-flex-line-pack: center;
  align-content: center;
}

.Flex-alignContent--spaceBetween {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.Flex-alignContent--spaceAround {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.Flex-alignContent--stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.Flex-alignItems--flexStart {
  -ms-flex-align: start;
  align-items: flex-start;
}

.Flex-alignItems--flexEnd {
  -ms-flex-align: end;
  align-items: flex-end;
}

.Flex-alignItems--center {
  -ms-flex-align: center;
  align-items: center;
}

.Flex-alignItems--baseline {
  -ms-flex-align: baseline;
  align-items: baseline;
}

.Flex-alignItems--stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.Flex-direction--row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.Flex-direction--rowReversed {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.Flex-direction--column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.Flex-direction--columnReverse {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.Flex-justifyContent--flexStart {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.Flex-justifyContent--flexEnd {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.Flex-justifyContent--center {
  -ms-flex-pack: center;
  justify-content: center;
}

.Flex-justifyContent--spaceBetween {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.Flex-justifyContent--spaceAround {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.Flex-wrap--nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.Flex-wrap--wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Flex-wrap--wrapReverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.Box-hideIfEmpty:empty {
  display: none;
}

.Box-divider--border-top-1,
.Box-divider--surface-top-1 {
  box-shadow: inset 0 1px #CFD7DF;
}

.Box-divider--border-bottom-1 {
  box-shadow: inset 0 -1px #CFD7DF;
}

.Box-divider--border-left-1 {
  box-shadow: inset 1px 0 #CFD7DF;
}

.Box-divider--border-right-1 {
  box-shadow: inset -1px 0 #CFD7DF;
}

.Box-divider--border-vertical-1 {
  box-shadow: inset 0 -1px #CFD7DF, inset 0 1px #CFD7DF;
}

.Box-divider--border-horizontal-1 {
  box-shadow: inset -1px 0 #CFD7DF, inset 1px 0 #CFD7DF;
}

.Box-divider--border-all-1 {
  box-shadow: inset -1px -1px #CFD7DF, inset 1px 1px #CFD7DF;
}

.Box-divider--surface-top-1 {
  box-shadow: inset 0 1px #E6EBF1;
}

.Box-divider--surface-bottom-1 {
  box-shadow: inset 0 -1px #E6EBF1;
}

.Box-divider--surface-left-1 {
  box-shadow: inset 1px 0 #E6EBF1;
}

.Box-divider--surface-right-1 {
  box-shadow: inset -1px 0 #E6EBF1;
}

.Box-divider--surface-vertical-1 {
  box-shadow: inset 0 -1px #E6EBF1, inset 0 1px #E6EBF1;
}

.Box-divider--surface-horizontal-1 {
  box-shadow: inset -1px 0 #E6EBF1, inset 1px 0 #E6EBF1;
}

.Box-divider--surface-all-1 {
  box-shadow: inset -1px -1px #E6EBF1, inset 1px 1px #E6EBF1;
}

.Box-divider--white-top-1 {
  box-shadow: inset 0 1px #FFF;
}

.Box-divider--white-bottom-1 {
  box-shadow: inset 0 -1px #FFF;
}

.Box-divider--white-left-1 {
  box-shadow: inset 1px 0 #FFF;
}

.Box-divider--white-right-1 {
  box-shadow: inset -1px 0 #FFF;
}

.Box-divider--white-vertical-1 {
  box-shadow: inset 0 -1px #FFF, inset 0 1px #FFF;
}

.Box-divider--white-horizontal-1 {
  box-shadow: inset -1px 0 #FFF, inset 1px 0 #FFF;
}

.Box-divider--white-all-1 {
  box-shadow: inset -1px -1px #FFF, inset 1px 1px #FFF;
}

.Box-divider--border-top-2 {
  box-shadow: inset 0 2px #CFD7DF;
}

.Box-divider--border-bottom-2 {
  box-shadow: inset 0 -2px #CFD7DF;
}

.Box-divider--border-left-2 {
  box-shadow: inset 2px 0 #CFD7DF;
}

.Box-divider--border-right-2 {
  box-shadow: inset -2px 0 #CFD7DF;
}

.Box-divider--border-vertical-2 {
  box-shadow: inset 0 -2px #CFD7DF, inset 0 2px #CFD7DF;
}

.Box-divider--border-horizontal-2 {
  box-shadow: inset -2px 0 #CFD7DF, inset 2px 0 #CFD7DF;
}

.Box-divider--border-all-2 {
  box-shadow: inset -2px -2px #CFD7DF, inset 2px 2px #CFD7DF;
}

.Box-divider--surface-top-2 {
  box-shadow: inset 0 2px #E6EBF1;
}

.Box-divider--surface-bottom-2 {
  box-shadow: inset 0 -2px #E6EBF1;
}

.Box-divider--surface-left-2 {
  box-shadow: inset 2px 0 #E6EBF1;
}

.Box-divider--surface-right-2 {
  box-shadow: inset -2px 0 #E6EBF1;
}

.Box-divider--surface-vertical-2 {
  box-shadow: inset 0 -2px #E6EBF1, inset 0 2px #E6EBF1;
}

.Box-divider--surface-horizontal-2 {
  box-shadow: inset -2px 0 #E6EBF1, inset 2px 0 #E6EBF1;
}

.Box-divider--surface-all-2 {
  box-shadow: inset -2px -2px #E6EBF1, inset 2px 2px #E6EBF1;
}

.Box-divider--white-top-2 {
  box-shadow: inset 0 2px #FFF;
}

.Box-divider--white-bottom-2 {
  box-shadow: inset 0 -2px #FFF;
}

.Box-divider--white-left-2 {
  box-shadow: inset 2px 0 #FFF;
}

.Box-divider--white-right-2 {
  box-shadow: inset -2px 0 #FFF;
}

.Box-divider--white-vertical-2 {
  box-shadow: inset 0 -2px #FFF, inset 0 2px #FFF;
}

.Box-divider--white-horizontal-2 {
  box-shadow: inset -2px 0 #FFF, inset 2px 0 #FFF;
}

.Box-divider--white-all-2 {
  box-shadow: inset -2px -2px #FFF, inset 2px 2px #FFF;
}

.Margin-top--2 {
  margin-top: 2px;
}

.Margin-top--4 {
  margin-top: 4px;
}

.Margin-top--8 {
  margin-top: 8px;
}

.Margin-top--12 {
  margin-top: 12px;
}

.Margin-top--16 {
  margin-top: 16px;
}

.Margin-top--20 {
  margin-top: 20px;
}

.Margin-top--24 {
  margin-top: 24px;
}

.Margin-top--32 {
  margin-top: 32px;
}

.Margin-top--48 {
  margin-top: 48px;
}

.Margin-top--64 {
  margin-top: 64px;
}

.Margin-right--2 {
  margin-right: 2px;
}

.Margin-right--4 {
  margin-right: 4px;
}

.Margin-right--8 {
  margin-right: 8px;
}

.Margin-right--12 {
  margin-right: 12px;
}

.Margin-right--16 {
  margin-right: 16px;
}

.Margin-right--20 {
  margin-right: 20px;
}

.Margin-right--24 {
  margin-right: 24px;
}

.Margin-right--32 {
  margin-right: 32px;
}

.Margin-right--48 {
  margin-right: 48px;
}

.Margin-right--64 {
  margin-right: 64px;
}

.Margin-bottom--2 {
  margin-bottom: 2px;
}

.Margin-bottom--4 {
  margin-bottom: 4px;
}

.Margin-bottom--8 {
  margin-bottom: 8px;
}

.Margin-bottom--12 {
  margin-bottom: 12px;
}

.Margin-bottom--16 {
  margin-bottom: 16px;
}

.Margin-bottom--20 {
  margin-bottom: 20px;
}

.Margin-bottom--24 {
  margin-bottom: 24px;
}

.Margin-bottom--32 {
  margin-bottom: 32px;
}

.Margin-bottom--48 {
  margin-bottom: 48px;
}

.Margin-bottom--64 {
  margin-bottom: 64px;
}

.Margin-left--2 {
  margin-left: 2px;
}

.Margin-left--4 {
  margin-left: 4px;
}

.Margin-left--12 {
  margin-left: 12px;
}

.Margin-left--16 {
  margin-left: 16px;
}

.Margin-left--20 {
  margin-left: 20px;
}

.Margin-left--24 {
  margin-left: 24px;
}

.Margin-left--32 {
  margin-left: 32px;
}

.Margin-left--48 {
  margin-left: 48px;
}

.Margin-left--64 {
  margin-left: 64px;
}

.Margin-vertical--2 {
  margin-bottom: 2px;
  margin-top: 2px;
}

.Margin-vertical--4 {
  margin-bottom: 4px;
  margin-top: 4px;
}

.Margin-vertical--8 {
  margin-bottom: 8px;
  margin-top: 8px;
}

.Margin-vertical--12 {
  margin-bottom: 12px;
  margin-top: 12px;
}

.Margin-vertical--16 {
  margin-bottom: 16px;
  margin-top: 16px;
}

.Margin-vertical--20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.Margin-vertical--24 {
  margin-bottom: 24px;
  margin-top: 24px;
}

.Margin-vertical--32 {
  margin-bottom: 32px;
  margin-top: 32px;
}

.Margin-vertical--48 {
  margin-bottom: 48px;
  margin-top: 48px;
}

.Margin-vertical--64 {
  margin-bottom: 64px;
  margin-top: 64px;
}

.Margin-horizontal--2 {
  margin-left: 2px;
  margin-right: 2px;
}

.Margin-horizontal--4 {
  margin-left: 4px;
  margin-right: 4px;
}

.Margin-horizontal--8 {
  margin-left: 8px;
  margin-right: 8px;
}

.Margin-horizontal--12 {
  margin-left: 12px;
  margin-right: 12px;
}

.Margin-horizontal--16 {
  margin-left: 16px;
  margin-right: 16px;
}

.Margin-horizontal--20 {
  margin-left: 20px;
  margin-right: 20px;
}

.Margin-horizontal--24 {
  margin-left: 24px;
  margin-right: 24px;
}

.Margin-horizontal--32 {
  margin-left: 32px;
  margin-right: 32px;
}

.Margin-horizontal--48 {
  margin-left: 48px;
  margin-right: 48px;
}

.Margin-horizontal--64 {
  margin-left: 64px;
  margin-right: 64px;
}

.Margin-all--2 {
  margin: 2px;
}

.Margin-all--4 {
  margin: 4px;
}

.Margin-all--8 {
  margin: 8px;
}

.Margin-all--12 {
  margin: 12px;
}

.Margin-all--16 {
  margin: 16px;
}

.Margin-all--20 {
  margin: 20px;
}

.Margin-all--24 {
  margin: 24px;
}

.Margin-all--32 {
  margin: 32px;
}

.Margin-all--48 {
  margin: 48px;
}

.Margin-all--64 {
  margin: 64px;
}

.Padding-top--2 {
  padding-top: 2px;
}

.Padding-top--4 {
  padding-top: 4px;
}

.Padding-top--8 {
  padding-top: 8px;
}

.Padding-top--12 {
  padding-top: 12px;
}

.Padding-top--16 {
  padding-top: 16px;
}

.Padding-top--20 {
  padding-top: 20px;
}

.Padding-top--24 {
  padding-top: 24px;
}

.Padding-top--32 {
  padding-top: 32px;
}

.Padding-top--48 {
  padding-top: 48px;
}

.Padding-top--64 {
  padding-top: 64px;
}

.Padding-right--2 {
  padding-right: 2px;
}

.Padding-right--4 {
  padding-right: 4px;
}

.Padding-right--8 {
  padding-right: 8px;
}

.Padding-right--12 {
  padding-right: 12px;
}

.Padding-right--16 {
  padding-right: 16px;
}

.Padding-right--20 {
  padding-right: 20px;
}

.Padding-right--24 {
  padding-right: 24px;
}

.Padding-right--32 {
  padding-right: 32px;
}

.Padding-right--48 {
  padding-right: 48px;
}

.Padding-right--64 {
  padding-right: 64px;
}

.Padding-bottom--2 {
  padding-bottom: 2px;
}

.Padding-bottom--4 {
  padding-bottom: 4px;
}

.Padding-bottom--8 {
  padding-bottom: 8px;
}

.Padding-bottom--12 {
  padding-bottom: 12px;
}

.Padding-bottom--16 {
  padding-bottom: 16px;
}

.Padding-bottom--20 {
  padding-bottom: 20px;
}

.Padding-bottom--24 {
  padding-bottom: 24px;
}

.Padding-bottom--32 {
  padding-bottom: 32px;
}

.Padding-bottom--48 {
  padding-bottom: 48px;
}

.Padding-bottom--64 {
  padding-bottom: 64px;
}

.Padding-left--2 {
  padding-left: 2px;
}

.Padding-left--4 {
  padding-left: 4px;
}

.Padding-left--8 {
  padding-left: 8px;
}

.Padding-left--12 {
  padding-left: 12px;
}

.Padding-left--16 {
  padding-left: 16px;
}

.Padding-left--20 {
  padding-left: 20px;
}

.Padding-left--24 {
  padding-left: 24px;
}

.Padding-left--32 {
  padding-left: 32px;
}

.Padding-left--48 {
  padding-left: 48px;
}

.Padding-left--64 {
  padding-left: 64px;
}

.Padding-vertical--2 {
  padding-bottom: 2px;
  padding-top: 2px;
}

.Padding-vertical--4 {
  padding-bottom: 4px;
  padding-top: 4px;
}

.Padding-vertical--8 {
  padding-bottom: 8px;
  padding-top: 8px;
}

.Padding-vertical--12 {
  padding-bottom: 12px;
  padding-top: 12px;
}

.Padding-vertical--16 {
  padding-bottom: 16px;
  padding-top: 16px;
}

.Padding-vertical--20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.Padding-vertical--24 {
  padding-bottom: 24px;
  padding-top: 24px;
}

.Padding-vertical--32 {
  padding-bottom: 32px;
  padding-top: 32px;
}

.Padding-vertical--48 {
  padding-bottom: 48px;
  padding-top: 48px;
}

.Padding-vertical--64 {
  padding-bottom: 64px;
  padding-top: 64px;
}

.Padding-horizontal--2 {
  padding-left: 2px;
  padding-right: 2px;
}

.Padding-horizontal--4 {
  padding-left: 4px;
  padding-right: 4px;
}

.Padding-horizontal--8 {
  padding-left: 8px;
  padding-right: 8px;
}

.Padding-horizontal--12 {
  padding-left: 12px;
  padding-right: 12px;
}

.Padding-horizontal--16 {
  padding-left: 16px;
  padding-right: 16px;
}

.Padding-horizontal--20 {
  padding-left: 20px;
  padding-right: 20px;
}

.Padding-horizontal--24 {
  padding-left: 24px;
  padding-right: 24px;
}

.Padding-horizontal--32 {
  padding-left: 32px;
  padding-right: 32px;
}

.Padding-horizontal--48 {
  padding-left: 48px;
  padding-right: 48px;
}

.Padding-horizontal--64 {
  padding-left: 64px;
  padding-right: 64px;
}

.Padding-all--2 {
  padding: 2px;
}

.Padding-all--4 {
  padding: 4px;
}

.Padding-all--8 {
  padding: 8px;
}

.Padding-all--12 {
  padding: 12px;
}

.Padding-all--16 {
  padding: 16px;
}

.Padding-all--20 {
  padding: 20px;
}

.Padding-all--24 {
  padding: 24px;
}

.Padding-all--32 {
  padding: 32px;
}

.Padding-all--48 {
  padding: 48px;
}

.Padding-all--64 {
  padding: 64px;
}

.Card-root {
  background-color: #FFF;
  border-radius: 4px;
  overflow: hidden;
}

.Card-shadow--small {
  box-shadow: 0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
}

.Card-shadow--medium {
  box-shadow:
    0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.Card-shadow--large {
  box-shadow:
    0 15px 35px 0 rgba(50, 50, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.07);
}

.Card-shadow--extraLarge {
  box-shadow:
    0 50px 100px 0 rgba(50, 50, 93, 0.1),
    0 15px 35px 0 rgba(50, 50, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.07);
}

.Text-display--block {
  display: block;
}

.Text-display--inline {
  display: inline;
}

.Text-fontSize--11 {
  font-size: 11px;
}

.Text-fontSize--12 {
  font-size: 12px;
}

.Text-fontSize--13 {
  font-size: 13px;
}

.Text-fontSize--14 {
  font-size: 14px;
}

.Text-fontSize--15 {
  font-size: 15px;
}

.Text-fontSize--16 {
  font-size: 16px;
}

.Text-fontSize--20 {
  font-size: 20px;
}

.Text-fontSize--24 {
  font-size: 24px;
}

.Text-fontSize--32 {
  font-size: 32px;
}

.Text-fontWeight--light {
  font-weight: 300;
}

.Text-fontWeight--regular {
  font-weight: 400;
}

.Text-fontWeight--medium {
  font-weight: 700;
}

.Text-fontWeight--bold {
  font-weight: 700;
}

.Text-lineHeight--16 {
  line-height: 16px;
}

.Text-lineHeight--20 {
  line-height: 20px;
}

.Text-lineHeight--24 {
  line-height: 24px;
}

.Text-lineHeight--28 {
  line-height: 28px;
}

.Text-lineHeight--32 {
  line-height: 32px;
}

.Text-lineHeight--40 {
  line-height: 40px;
}

.Text-typeface--upper {
  text-transform: uppercase;
}

.Text-typeface--mono {
  font-family: Source Code Pro, Courier New, Courier, monospace;
}

.Text-wordBreak--all {
  word-break: break-all;
}

.Text-wordBreak--normal {
  word-break: normal;
}

.Text-wordBreak--word {
  word-break: break-word;
}

.Text-wrap--wrap {
  white-space: normal;
}

.Text-wrap--noWrap {
  white-space: nowrap;
}

.bp3-button:not([class*='bp3-intent-']):not([class*='bp3-minimal']) {
  box-shadow:
    0 0 0 1px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);

  &:hover {
    box-shadow:
      0 0 0 1px rgba(50, 50, 93, 0.1),
      0 2px 5px 0 rgba(50, 50, 93, 0.1),
      0 3px 9px 0 rgba(50, 50, 93, 0.08),
      0 1px 1.5px 0 rgba(0, 0, 0, 0.08),
      0 1px 2px 0 rgba(0, 0, 0, 0.08);
  }
}

.Table {
  max-width: 100%;
  width: 100%;
}

.Table-cell--align--center {
  text-align: center;
}

.Table-cell--align--left {
  text-align: left;
}

.Table-cell--align--right {
  text-align: right;
}

.Table-cell--verticalAlign--bottom {
  vertical-align: bottom;
}

.Table-cell--verticalAlign--middle {
  vertical-align: middle;
}

.Table-cell--verticalAlign--top {
  vertical-align: top;
}

.Table-cell--width--auto {
  width: auto;
}

.Table-cell--width--maximized {
  width: 100%;
}

.Table-cell--width--minimized {
  width: 1px;
}

.Table-cell--maxWidth--auto {
  max-width: auto;
}

.Table-cell--maxWidth--maximized {
  max-width: 100%;
}

.Table-cell--maxWidth--minimized {
  max-width: 1px;
}

.Table-cell--minWidth--auto {
  min-width: auto;
}

.Table-cell--minWidth--maximized {
  min-width: 100%;
}

.Table-cell--minWidth--minimized {
  min-width: 1px;
}

.Table-cell--wrap--noWrap {
  white-space: nowrap;
}

.Table-cell--wrap--wrap {
  white-space: normal;
}

.db-ListViewItem {
  color: #525F7F;
  height: 100%;
}

.db-ListViewItem--hasLink {
  cursor: pointer;
}

.db-ListViewItem-header {
  background-color: #F6F9FC;
}

.db-ListViewItem-cell {
  box-shadow: inset 0 -1px #E6EBF1;
}

.db-ListView--withoutFooter .db-ListViewItem:last-of-type .db-ListViewItem-cell {
  box-shadow: none;
}

.db-ListViewItem-cellContent .db-ListViewItem-text {
  white-space: inherit;
}

.db-ListViewItem-cell--breakWord .db-ListViewItem-cellContent {
  white-space: normal;
  overflow: auto;
}

.db-ListViewItem-cell--breakWord {
  white-space: normal;
  word-break: break-word;
}

.db-ListViewItem-cell--truncate .db-ListViewItem-cellContent {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.db-ListViewItem-cell--truncate .db-ListViewItem-cellContent,
.db-ListViewItem-cell--truncate .db-ListViewItem-link {
  min-width: inherit;
  max-width: inherit;
}

.db-ListViewItem-link,
.db-ListViewItem-link .db-ListViewItem-cellContent {
  height: 100%;
}

.db-ListViewItem-cell:first-child .db-ListViewItem-cellContent {
  padding-left: 20px;
}

.db-ListViewItem-cell:last-child .db-ListViewItem-cellContent {
  padding-right: 20px;
}

.db-ListViewItem--hasLink:hover,
.db-ListViewItem--hasLink:hover .db-ListViewItem-link .db-ListViewItem-text {
  color: #32325D;
}

.db-ListViewItem-link {
  text-decoration: none;
  color: inherit;
  width: 100%;
  display: block;
}

.db-ListViewItem-link--sortable {
  background: none;
  border: 0;
}

.db-ListViewItem-link--sortable:focus {
  box-shadow:
    0 0 0 1px rgba(50, 151, 211, 0.2),
    0 0 0 2px rgba(50, 151, 211, 0.25),
    0 2px 5px 0 rgba(50, 50, 93, 0.1),
    0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.db-ListViewItem-sortIcon--disabled {
  opacity: 0;
}

.db-ListViewItem-link--sortable:active .db-ListViewItem-sortIcon--disabled,
.db-ListViewItem-link--sortable:hover .db-ListViewItem-sortIcon--disabled {
  opacity: 1;
}

.db-ListViewItem--hasLink:active,
.db-ListViewItem--selected,
.db-ListViewItem-link--sortable:active {
  background-color: #F7F8FD;
}

.db-ListViewItem-actionsSeparator {
  background-color: #E6EBF1;
}

.db-ListViewItem .db-ListViewItem-actions {
  max-height: 20px;
  margin-top: -2px;
}

.db-ListViewItem--expanded,
.db-ListViewItem--expanded .db-ListViewItem-cell {
  box-shadow: none;
}

.db-ListViewItem--expandable .db-ListViewItem-cell {
  position: relative;
}

.db-ListViewItem--expandable .db-ListViewItem-cell:first-child::before {
  content: '';
  left: 20px;
  position: absolute;
  top: 13px;
  height: 10px;
  width: 6px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMiAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+cmlnaHQtLWdyYXk8L3RpdGxlPjxwYXRoIGQ9Ik0xMS4zMzMgOC40MUwzLjc3OC42ODFjLS44OS0uOTEtMi4yMjItLjkxLTMuMTExIDAtLjg5LjkwOS0uODkgMi4yNzMgMCAzLjE4Mmw2IDYuMTM2LTYgNi4xMzZjLS44OS45MS0uODkgMi4yNzMgMCAzLjE4Mi40NDQuNDU1IDEuMTEuNjgyIDEuNTU1LjY4Mi42NjcgMCAxLjExMS0uMjI3IDEuNTU2LS42ODJsNy41NTUtNy43MjdjLjg5LS45MS44OS0yLjI3MyAwLTMuMTgyeiIgZmlsbD0iIzZCN0M5MyIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
  background-size: 100% 100%;
  transform: rotate(0);
  transition: transform 0.25s ease-in-out;
}

.db-ListViewItem--expanded .db-ListViewItem-cell:first-child::before {
  transform: rotate(90deg);
}

.db-ListViewItem--expandable .db-ListViewItem-cell:first-child {
  padding-left: 20px;
}

.db-ListViewItem--hasLink .db-ListViewItem-cellContent .db-ListViewItem-overflowMenu .bs-ButtonLegacy-fill {
  box-shadow: none;
}

.db-ListViewItem--hasLink:hover .db-ListViewItem-overflowMenu .bs-ButtonLegacy-fill {
  box-shadow:
    0 0 0 1px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.1),
    0 3px 9px 0 rgba(50, 50, 93, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.08),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.db-ListViewItem .db-BatchCell {
  opacity: 0;
}

.db-ListViewItem .db-BatchCell.db-BatchCell--isVisible,
.db-ListViewItem .db-BatchCell:focus,
.db-ListViewItem:hover .db-BatchCell {
  opacity: 1;
}

.db-ListViewItem .db-BatchCell-container .db-ListViewItem-cellContent {
  height: 100%;
}

.Text-color--dark {
  color: #32325D;
}

body .ReactTable {
  border: none;
  background: #FFF;

  .rt-thead.-header {
    background: $nb-grey-300;
    color: $nb-grey-900;
    font-size: 13px;
    box-shadow: inset 0 -1px #E6EBF1;
    font-weight: 600;
    position: sticky;
    top: 0;

    .rt-th {
      border-right: none;
    }
  }

  .rt-table {
    max-height: 75vh;
  }
}

.ReactTable .rt-tbody .rt-td {
  border-right: none;
  padding: 8px;
  color: $nb-grey-700;
  text-align: center;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-color: $nb-grey-300;
}

.ReactTable .rt-thead {
  overflow-y: scroll;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 8px;
}

body .ReactTable .rt-thead.-header .rt-th,
body .ReactTable .rt-tbody .rt-td {
  &:last-child {
    padding-right: 20px;
  }

  &:first-child {
    padding-left: 20px;
  }

  span.highlight {
    color: $nb-purple-500;
  }
}

.Text-color--blue2 {
  color: #106BA3;
}

.cellCheckbox {
  text-align: center;

  > label {
    display: inline-flex;
    margin: 0;
  }
}

.alignRight {
  text-align: right !important;
}

.alignLeft {
  text-align: left !important;
}

.textColorGreen {
  color: #24B47E;
}

.textColorRed {
  color: #E25950;
}

.textColorCyan {
  color: #3297D3;
}

.phoneNumberInput {
  padding: 2px;
  overflow: hidden;

  input {
    border: none;
    appearance: none;
    margin: 5px;
    outline: none !important;
    width: 75%;
    max-width: 105px;
  }

  .inputPrefix {
    width: 10px !important;
  }

  .countryCode {
    width: 40px !important;
    border-right: 1px solid gray;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.phoneNumberInputFullHeight {
    height: fit-content;

    input {
      max-width: none;
      width: 75%;
    }
  }
}

.Toastify__toast {
  border-radius: 3px;
  font-size: 13px;

  &.Toastify__toast--success {
    background-color: #24B47E;
  }

  &.Toastify__toast--error {
    background-color: #E25950;
  }
}

.borderless {
  border-radius: 0;
}
