@import './typography';

@mixin mobile {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tabletAndUp {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tabletWithSidebar {
  @media (max-width: 1054px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin mediumDevice {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin smallDesktop {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin hideOnMobile {
  @media (max-width: 575px) {
    display: none;
    @content;
  }
}

@mixin hideOnTablet {
  @media (max-width: 768px) {
    display: none;
    @content;
  }
}

@mixin settingMediumDevice {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin settingDesktop {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin buttonStyle($background, $textColor) {
  background-color: $background;
  padding: 15px 0;

  &:active,
  &:hover {
    background-color: rgba($background, 0.9);
  }

  &:disabled {
    background-color: rgba($background, 0.5);
  }

  span {
    font-size: 17.5px;
    line-height: 24px;
    font-weight: bold;
    color: $textColor;
  }

  @media (max-width: 575px) {
    span {
      font-size: 20px;
      line-height: 27px;
    }
  }
}

@mixin wireButtonStyle($background, $borderColor, $textColor) {
  background-color: $background;
  border: 1.5px solid $borderColor;
  padding: 15px 0;

  &:active,
  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
  }

  span {
    font-size: 17.5px;
    line-height: 24px;
    font-weight: bold;
    color: $textColor;
  }

  span > svg {
    color: $textColor;
  }

  @media (max-width: 575px) {
    span {
      font-size: 20px;
      line-height: 27px;
    }
  }
}

@mixin questionMarkStyle($width, $color, $fontSize) {
  border-radius: $width;
  border: 1px solid $color;
  width: $width;
  height: $width;
  line-height: $width;
  font-size: $fontSize;
  margin-left: $width/2;
  min-width: $width;
  min-height: $width;
  padding: 0;
  color: $color;
  transform: translateY(-2px);
  box-shadow: none;

  &:focus {
    outline: none;
  }

  > span {
    line-height: $width;
    transform: translateY(1px);
  }
}

@mixin fontStyles($fontSize) {
  @if $fontSize == $text-xl {
    line-height: $leading-snug;
  }

  @else if $fontSize == $text-2xl {
    line-height: $leading-tight;
    letter-spacing: $tracking-tight;
  }

  @else if $fontSize == $text-3xl {
    line-height: $leading-tighter;
    letter-spacing: $tracking-tight;
  }

  @else if $fontSize == $text-4xl {
    line-height: $leading-tight;
    letter-spacing: $tracking-tight;
  }

  @else if $fontSize == $text-5xl {
    line-height: $leading-tightest;
    letter-spacing: $tracking-tighter;
  }

  @else if $fontSize == $text-6xl {
    line-height: $leading-none;
    letter-spacing: $tracking-tighter;
  }

  @else if $fontSize == $text-7xl {
    line-height: $leading-none;
    letter-spacing: $tracking-tightest;
  }

  @else {
    line-height: $leading-normal;
  }

  font-size: $fontSize;
  font-weight: 500;
}

@mixin setPrint($size, $color, $weight: $font-medium) {
  @include fontStyles($size);

  color: $color;

  @if $weight {
    font-weight: $weight;
  }
}

@mixin lineRule($color) {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 1px solid $color;
    z-index: 1;
  }
}

@mixin equallySpaced {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  > div {
    width: 100%;
  }
}

@mixin textOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin shakeX($duration: 1s) {
  @-webkit-keyframes shakex {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }

  @keyframes shakex {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }

  -webkit-animation-name: shakex;
  animation-name: shakex;
  animation-duration: $duration;
}
