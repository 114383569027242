@import '~styles/_theme';

.actions {
  :global {
    .bp3-popover-content {
      padding: 0;
    }
  }

  .popover {
    width: $spacing-54;

    .menuContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      svg {
        margin-right: $spacing-3;
        margin-top: $spacing-px-3;
      }

      :global(.bp3-spinner) {
        margin-right: $spacing-3;
        margin-top: $spacing-px-3;
      }

      :global(.bp3-spinner-animation) {
        transform-origin: 50% 50%;
      }

      :global(.bp3-spinner-animation > svg) {
        margin-right: 0 !important;
        margin-top: 0 !important;
      }

      span {
        line-height: $leading-normal;
        word-wrap: break-word;
        white-space: normal;
        flex: 1;
      }
    }
  }
}
