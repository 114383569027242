@import '~styles/_theme';

.unverifiedMessageContainer {
  margin-top: $spacing-2;
  background: $nb-yellow-100;
  border: $border-1 solid $nb-yellow-400;
  box-shadow: $shadow-sm;
  border-radius: $rounded-base;
  padding: $spacing-4 $spacing-5 !important;
  display: flex;
  flex-direction: row;

  svg {
    color: $nb-yellow-900;
  }

  .warningIcon {
    margin-right: $spacing-3;
  }

  .closeIcon:hover {
    cursor: pointer;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 2;

    @include setPrint($text-sm, $nb-grey-700);

    .header {
      @include setPrint($text-sm, $nb-grey-900, $font-semibold);

      margin-bottom: $spacing-2;
    }

    .resendBtn {
      padding: $spacing-0;
      min-height: min-content;
      text-decoration: underline;
      color: $nb-yellow-900;
      display: inline;

      &:hover {
        background: none;
      }

      span {
        @include setPrint($text-sm, $nb-yellow-900);
      }
    }
  }
}
