@import '~styles/_theme';

.container {
  border-radius: 6px;
}

.container :global(.bp3-popover-content) {
  padding: $spacing-0;
  border-radius: 6px;
}

.content {
  padding: $spacing-5;
  max-width: 360px;
  line-height: 1.5;
  padding-bottom: $nb-spacing-0;

  .title {
    font-size: $text-base;
    font-weight: $font-semibold;
    color: $nb-grey-900;
  }

  .description {
    margin-top: $spacing-2;
    font-size: $text-sm;
    font-weight: $font-medium;
    color: $nb-grey-700;
  }
}

.buttonContainer {
  padding: $spacing-5;
  padding-top: $spacing-4;
  background-color: $nb-white;
  border-radius: $rounded-none $rounded-none $rounded-base $rounded-base;
  display: flex;
  justify-content: space-between;

  .button {
    font-size: $text-sm;
    font-weight: $font-semibold;
    border-radius: $rounded-base;
    box-shadow: $shadow-sm;
    width: 100%;
    height: $spacing-8;

    &:last-of-type {
      margin-left: $spacing-3;
    }

    &:global(.bp3-intent-danger) {
      background: $nb-red-700;
    }
  }

  .button.primary {
    color: $nb-grey-800;
    background: $nb-white;
  }
}

.target {
  width: 100%;
}

.variant_white {
  &.container {
    margin-left: $spacing-5;
    padding: 0;

    @include mobile {
      max-width: $spacing-70;
    }
  }

  .content {
    padding: $spacing-5;
    padding-bottom: $spacing-4;
  }

  .buttonContainer {
    padding-top: 0;
    background-color: $white;
    border: none;
  }
}
