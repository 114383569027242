@import '~styles/_theme';

.draftContainer,
.cancelledContainer {
  .button {
    background-color: $white !important;
  }
}

.cancelAlert {
  padding: 0;

  .content {
    padding: $spacing-5;
    max-width: $spacing-96;
    line-height: 1.5;

    .title {
      @include setPrint($nb-text-base, $nb-grey-900, $nb-font-semibold);
    }

    .description {
      margin-top: $spacing-2;
      font-size: $text-sm;
      font-weight: $font-medium;
      color: $nb-grey-700;
    }
  }

  .buttonContainer {
    padding: $spacing-4 $spacing-5;
    background-color: $nb-grey-100;
    border: $nb-border-light-grey-1px;
    border-radius: $rounded-none $rounded-none $rounded-base $rounded-base;
    display: flex;
    justify-content: space-between;

    .button {
      font-size: $text-sm;
      font-weight: $font-semibold;
      border-radius: $rounded-base;
      box-shadow: $shadow-sm;
      width: 100%;
      height: $spacing-8;

      &:last-of-type {
        margin-left: $spacing-3;
      }
    }

    .button.primary {
      color: $nb-grey-800;
    }
  }
}

.testPaymentLabel {
  color: $nb-red-700;
}

.pmBadge {
  height: $spacing-6;
  min-width: $spacing-20;
  text-align: center;
  display: flex;
  justify-content: center;

  @include mobile {
    height: auto;
    min-width: $spacing-20;
  }

  span {
    font-size: $text-xs;
    font-weight: $nb-font-medium;
  }
}

.cardInfoContainer {
  > span {
    margin-right: 12px;
  }

  color: $nb-grey-900 !important;
  display: flex;
  align-items: center;

  .brandName {
    text-transform: capitalize;
  }

  .ownedBy {
    @include setPrint($text-sm, $nb-grey-600, $nb-font-regular);

    strong {
      font-weight: $font-semibold;
    }
  }
}

.cardContainer {
  padding: 0;
  color: $nb-grey-900;
  height: $spacing-20;
  margin-bottom: $spacing-2;
  border-radius: $spacing-2;
  box-shadow: $shadow-base;
  width: 100%;
  overflow: hidden;
  border: $border-2 solid transparent;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .container {
    display: flex;
    align-items: center;
    padding: 0 $spacing-6;
    background-color: $white;
    position: relative;
    width: 100%;

    @include mobile {
      flex: 1 0 100%;
    }

    .legalTypeIconContainer {
      flex: 0 0 40px;
      background: $nb-grey-100;
      height: $spacing-10;
      min-height: $spacing-10;
      border-radius: $rounded-full;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $spacing-4;

      @include mobile {
        visibility: hidden;
        flex: 0 0 16px;
        margin: 0;
      }

      .legalTypeIcon {
        color: $nb-svg-grey;
      }
    }

    .detailsContainer {
      display: flex;
      position: relative;
      align-items: center;
      min-width: 0;
      flex: 8 0 80%;

      @include setPrint($nb-text-sm, $nb-grey-900);

      .detailInfoContainer {
        flex: 2 0 50%;
        display: flex;
        align-self: center;
        justify-content: space-between;

        @include mediumDevice {
          width: 100%;
          flex: 2.5 0 25%;
          padding-right: $spacing-4;
        }
      }



      .recipientName {
        padding-right: $spacing-4;
      }

      span.testPaymentLabel {
        @include setPrint($text-xs, $nb-red-700, $font-semibold);
      }

      .amount {
        display: flex;
        align-items: center;
        text-align: right;
        word-break: break-all;
      }
    }

    .paymentInfo {
      @include setPrint($nb-text-sm, $nb-grey-900, $nb-font-regular);
      @include textOverflow;
      flex: 1 0 50%;
      padding-left: $spacing-6;

      @include mediumDevice {
        display: none;
      }

      @include mobile {
        margin-left: 0;
        padding-left: 0;
      }

      p,
      span {
        @include textOverflow;
      }

      .description {
        text-transform: uppercase;

        @include setPrint($nb-text-xs, $nb-grey-600, $nb-font-regular);
      }
    }

    .cardLeft {
      // so that when child overflows it still following flex def
      min-width: 0;
      flex: 2 0 50%;
      display: flex;
      justify-content: flex-start;

      @include mediumDevice {
        flex: 1 1 35%;
      }
    }

    .cardRight {
      padding-left: $spacing-6;
      flex: 1 0 50%;
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      @include mediumDevice {
        flex: 1 0 60%;
      }

      .statusContainer {
        flex: 1 1 100px;
        min-width: 100px;
      }

      .paymentAmount {
        flex: 1 0 30%;
        padding-left: $spacing-6;

        @include mediumDevice {
          padding-right: $spacing-10;
        }
      }

      .trackingInfoStatusContainer {
        flex: 1 0 100px;
        min-width: 100px;
        max-width: 120px;
        > span {
          width: 100%;
        }
      }

      .actionsContainer {
        flex: 1 0 10%;
        display: flex;
        justify-content: flex-end;
      }

      .button {
        margin-left: 12px;
        background-color: $nb-white;
        border: $nb-border-grey-1px;
        box-shadow: $shadow-sm;
        height: 38px;
        border-radius: $spacing-2;

        svg {
          color: $nb-svg-grey;
        }
      }
    }

    .nameContainer {
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 128px;
      white-space: nowrap;
    }

    .arrivalDateContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-self: end;

      .arrivalDate {
        margin-left: 10px;
      }
    }



    .editBtnContainer {
      width: 36px;

      .editBtn {
        @include nbWireButtonStyle($nb-white, $nb-button-primary-border-color, $nb-grey-600);

        padding: 0;
        min-width: 100%;
        min-height: 33px;
      }
    }
  }

  &.draftContainer {
    border: $border-1 dashed $nb-grey-300;
  }

  &.draftContainer,
  &.cancelledContainer {
    border-color: $nb-grey-300;

    .container {
      background-color: $nb-grey-100;

      .legalTypeIconContainer {
        background-color: $nb-grey-100;
      }
    }
  }

  &.testPaymentContainer {
    background-color: $nb-white !important;
    border: $border-1 solid $nb-orange-400;
  }

  &.highLight {
    background: $nb-red-100;
  }

  &.selectedContainer {
    @include nbSelectedBorderStyle();
  }

  &:hover {
    cursor: pointer;
  }
}

@media (max-width: 1060px) {
  $containerPaddingY: 15px;
  $containerPaddingX: 20px;

  .mobileContainer {
    .cardContainer {
      background-color: $nb-white;
      height: auto;

      &.cardContainerOpen {
        background: $nb-grey-100;
      }

      .container {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 12px 16px;

        &.containerOpen {
          padding-bottom: 0;
        }

        .cardTop {
          display: flex;
          justify-content: space-between;
          flex: 1;
          width: 100%;
          position: relative;
        }

        .paymentInfo {
          display: flex;
        }

        .cardAction {
          position: absolute;
          top: 0;
          right: 0;

          button {
            padding: 0;
            width: 20px !important;
            height: 20px !important;
            min-width: 20px !important;
            min-height: 20px !important;
          }
        }

        .trackingInfoStatusContainer {
          align-content: flex-end;
        }

        .nameAndAmount {
          display: flex;
          flex-direction: column;
          color: $nb-grey-700;

          @include mobile {
            margin-bottom: $spacing-1;
          }
        }

        .statusAndPaymentDate {
          display: flex;
          width: 100%;
          padding-left: 56px;
          justify-content: space-between;
          align-items: center;

          @include mobile {
            padding-left: 34px;
          }

          .arrivalDate {
            @include setPrint($text-xs, $nb-grey-600, $font-medium);
          }
        }
      }

      .horizontalLine {
        width: 100%;
        height: 1px;
      }

      .collapseContainer {
        width: 100%;
        box-shadow: $nb-inner-1px-top-grey-300;

        .detailsContainer {
          padding: 12px 16px;

          &:focus {
            outline: none;
          }

          .detailsItemContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $spacing-3;

            @include setPrint($text-sm, $nb-grey-600, $font-medium);

            .detailItemValue {
              color: $nb-grey-900;
            }
          }

          .crossBorderWarning {
            margin-bottom: $spacing-3;
          }

          .repeatsUntilContainer {
            margin-top: 10px;
            margin-bottom: 5px;
            color: $nb-red-700;
            font-weight: bold;
            text-align: center;
          }

          .buttonsGroup {
            display: grid;
            grid-gap: $spacing-3;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;

            .viewDetailsBtn,
            .editBtn {
              @include nbWireButtonStyle($nb-white, $nb-button-primary-border-color, $nb-button-primary);

              width: 100%;
              padding: 0;
              min-height: 33px;
              font-weight: 500;

              span {
                font-size: $text-sm;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.mobileContainer {
  .cardTop {
    padding-bottom: 3px;
  }
}

.checkboxContainer {
  display: flex;
  flex: 1 0 auto;
  align-items: center;

  :global(.bp3-checkbox) {
    margin-bottom: 0;
  }
}
