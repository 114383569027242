@import '~styles/_theme';

.main {
  display: inline-block;
}

.filterTag {
  padding: 0 $spacing-3;
  height: $spacing-8;
  border-radius: $rounded-full;

  @include setPrint($nb-text-sm, $nb-grey-800);

  &:global(.bp3-button:not([class*="bp3-intent-"])) {
    background-color: $nb-grey-300;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    @include setPrint($text-sm, $nb-grey-800);

    &.open {
      color: $white;
      background-color: $nb-grey-600;
    }
  }
}

.popover {
  border: $nb-border-light-grey-1px;
  border-radius: $rounded-base;
  box-shadow: $shadow-lg;
  width: $spacing-70;

  &:global(.bp3-popover.bp3-minimal) {
    margin-top: $spacing-2 !important;
  }

  & :global(.bp3-popover-content) {
    padding: 0;
  }

  .popoverMain {
    padding: $spacing-4;

    .popoverTitle {
      @include setPrint($nb-text-base, $nb-grey-900, $nb-font-semibold);

      margin-bottom: $spacing-4;
    }
  }

  .popoverActions {
    padding: $spacing-3 $spacing-4;
    border-top: $nb-border-light-grey-1px;
    display: flex;

    .button {
      font-size: $text-sm;
      height: $spacing-8;
    }

    .button + .button {
      margin-left: $spacing-3;
    }

    .clearButton {
      background-color: $nb-white;
    }
  }
}
