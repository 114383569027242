@import "~styles/_theme";

.header,
.headerDivider {
  display: flex;
  width: 100%;
  position: relative;

  .heading {
    @include setPrint($nb-text-base, $nb-dark-violet, $nb-font-semibold);

    white-space: nowrap;
    position: relative;
    margin-bottom: $spacing-4;
  }
}

.header {
  justify-content: left;
}

.headerDivider {
  justify-content: space-between;
  margin: $spacing-6 0;

  @include lineRule($nb-grey-700);

  .heading {
    padding-right: $spacing-5;
    margin-bottom: $spacing-4;
  }
}

:global {
  .sectionHeaderCollapse {
    padding: 0;
    min-height: 15px;

    .bp3-icon {
      color: $nb-red-600;
    }

    &:focus {
      outline: 0;
    }
  }
}
