@import '~styles/_theme';

.container {
  @include tablet {
    flex-direction: column;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .detailContainer {
    @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);

    position: absolute;

    .paginateNum {
      font-weight: $nb-font-medium;
    }
  }

  ul {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul,
  li,
  a {
    &:focus {
      outline: none;
    }
  }

  .pageContainer,
  .breakLinkContainer,
  .previous,
  .next {
    width: $spacing-10;
    height: $spacing-10;
  }

  .previous,
  .next,
  .pageLink {
    @include setPrint($nb-text-sm, $nb-grey-700);

    a {
      height: $spacing-4;
    }

    svg {
      color: $nb-svg-grey;
      stroke-width: 2.5;
    }
  }

  .previous {
    margin-right: $spacing-6;
  }

  .next {
    margin-left: $spacing-6;
  }

  .disabledArrow,
  .breakLink {
    color: $nb-grey-600;

    &:hover {
      color: $nb-grey-600;

      &,
      a {
        cursor: default;
        text-decoration: none;
      }
    }

    svg {
      color: $nb-svg-grey;
    }
  }

  .disabledArrow:hover {
    cursor: not-allowed;

    svg {
      cursor: not-allowed;
    }
  }

  .activePageContainer {
    background: transparent;

    .active {
      @include setPrint($nb-text-sm, $nb-red-700);
    }

    &.circle {
      border-radius: $rounded-full;
      border: $border-1 solid $nb-red-600;
    }
  }
}

@include tablet {
  .container {
    .detailContainer {
      display: none;
    }
  }
}

@include mobile {
  .container {
    .detailContainer {
      position: relative;
    }

    .pageContainer,
    .breakLinkContainer {
      display: none;
    }

    .previous,
    .next {
      margin-top: $spacing-2;
      margin-left: $spacing-0;
      margin-right: $spacing-0;
    }
  }
}

.topBar {
  box-shadow: $nb-inner-1px-top-grey-300;

  @include tablet {
    flex-direction: row;
  }

  @include mobile {
    justify-content: space-between;
  }

  .detailContainer {
    @include tablet {
      display: block;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  ul {
    @include mobile {
      margin: $spacing-0;
      padding-right: $spacing-0;
    }
  }

  .pageContainer,
  .previous,
  .breakLinkContainer,
  .next {
    width: $spacing-10;
    height: $spacing-15;

    @include mobile {
      width: $spacing-8;
      height: $spacing-10;
      margin-top: $spacing-0;
    }
  }

  .activePageContainer {
    box-shadow: inset 0 2px 0 $nb-red-600;
  }
}
