@import '~styles/_theme';

.verificationForm {
  margin: $spacing-0 $spacing-5;

  .phoneField {
    margin-bottom: $spacing-2 !important;
  }

  .fieldHelperText {
    @include setPrint($text-sm, $nb-grey-700);

    // stylelint-disable-next-line no-descending-specificity
    strong {
      @include setPrint($text-sm, $nb-red-700, $font-semibold);
    }
  }


  .waOptions {
    display: flex;
    flex-direction: column;
    text-align: center;

    .sendOptionsHeader {
      @include setPrint($text-sm, $nb-grey-700, $nb-font-medium);
    }

    .waModule {
      margin: $spacing-5 0;
    }
  }


  p {
    @include setPrint($text-sm, $nb-grey-700, $font-medium);

    margin-bottom: $spacing-6;
  }

  .sendOptions {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: $spacing-6;
  }
}

.boxedInput {
  display: flex;
  justify-content: center;
  margin: $spacing-8 0 $spacing-3 0;

  // stylelint-disable-next-line no-descending-specificity
  > div + div {
    margin: 0 0 0 $spacing-3;
  }

  .error input {
    border: $border-1 solid $nb-red-600 !important;
  }

  // stylelint-disable-next-line no-descending-specificity
  input {
    background: $white !important;

    @include setPrint($text-base, $nb-grey-900, $font-medium);

    border: $nb-border-light-grey-1px;
    box-sizing: border-box;
    border-radius: $rounded-base;
    padding: $spacing-2;
    text-align: center;
    max-width: $spacing-11;
    width: $spacing-11 !important;
  }
}

.sendIcon {
  margin-top: $spacing-6;
  display: flex;
  justify-content: center;
  align-items: center;

  // stylelint-disable-next-line no-descending-specificity
  > span {
    border-radius: $rounded-full;
    background-color: $nb-light-blue-200;
    width: $spacing-16;
    height: $spacing-16;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .smsIcon {
    color: $nb-light-blue-700;
  }
}
