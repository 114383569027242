@import '~styles/_theme';

.sideContainer {
  padding: $spacing-12 $spacing-16;

  figure {
    width: 100%;
    margin: 0 0 $spacing-16 0;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  ul {
    @include setPrint($nb-text-sm, $nb-violet-200, $nb-font-regular);

    list-style-type: none;

    li {
      display: flex;

      p.header {
        @include setPrint($nb-text-base, $white, $nb-font-bold);

        margin-bottom: $spacing-1;
      }
    }

    svg {
      padding: 2px;
      color: $nb-dark-violet;
      margin-top: 2px;
      margin-right: $spacing-4;
      background: $nb-red-500;
      border: none;
      border-radius: $rounded-full;
    }

    li + li {
      margin-top: $spacing-4;
    }
  }
}

.formHeader {
  margin-bottom: $spacing-6;

  figure {
    width: 100%;
    margin: 0 0 $spacing-4 0;

    img {
      display: block;
      width: auto;
      height: $spacing-16;
      margin: 0 auto;
    }
  }

  h1 {
    @include nb-h1;
    @include setPrint($nb-text-3xl, $nb-dark-violet, $nb-font-bold);

    text-align: center;
    margin: 0;
  }

  p {
    @include setPrint($nb-text-base, $nb-grey-600, $nb-font-regular);

    text-align: center;
    margin: 0;

    em {
      @include setPrint($nb-text-base, $nb-red-700, $nb-font-medium);

      font-style: unset;
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
