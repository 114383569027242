@import '~styles/_theme';

.content {
  display: flex;
  align-items: center;

  .label {
    margin-left: $spacing-2;
    font-weight: $nb-font-medium;
  }
}
