@import '~styles/_colors.module.scss';
@import '~styles/_spacing';

.saveBtnCotentContainer {
  display: flex;
  flex-direction: row;
  // spinner
  .spinner {
    svg {
      stroke-width: 8;
    }

    path:first-of-type {
      stroke: rgba($nb-grey-600, 0.25);
    }

    path:nth-of-type(2) {
      stroke: $nb-grey-600;
    }
  }

  .savingContent {
    margin-left: $spacing-2;
  }

  &.whiteDisableBtnClassName {
    .spinner {
      path:first-of-type {
        stroke: rgba($white, 0.25);
      }

      path:nth-of-type(2) {
        stroke: $white;
      }
    }
  }
}
