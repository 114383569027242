@import '~styles/_theme';

.container {
  background: $white;
  box-shadow: $shadow-base;
  border-radius: $border-8;
  padding: $spacing-0;
  display: flex;
  flex: row;
  height: $spacing-20;
  align-items: center;
  border: $border-2 solid transparent;

  &:hover {
    cursor: pointer;
  }

  &.selectedContainer {
    @include nbSelectedBorderStyle();
  }

  .legalTypeIconContainer {
    background: $nb-grey-100;
    height: $spacing-10;
    width: $spacing-10;
    min-height: $spacing-10;
    min-width: $spacing-10;
    border-radius: $rounded-full;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $spacing-4;
    margin-left: $spacing-6;

    .legalTypeIcon {
      color: $nb-svg-grey;
    }
  }

  .detailsBtn {
    text-align: right;

    &:hover {
      background: transparent;
    }

    span {
      @include setPrint($text-sm, $nb-grey-800);

      @include mobile {
        color: $nb-red-700;
      }
    }

    svg {
      color: $nb-grey-800;
      stroke-width: 2.5;
    }
  }

  .detailsContainer {
    display: grid;
    grid-template-columns: minmax(100px, 1.5fr) 1fr minmax(30px, 2fr);
    grid-gap: $spacing-8;
    flex: 1;
    align-items: center;

    .accountInfoContainer {
      .recipientNameContainer {
        display: flex;
        align-items: center;
        max-width: $spacing-54;

        .recipientName {
          @include setPrint($text-sm, $nb-grey-900);

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .starring {
          min-width: $spacing-4;
          min-height: $spacing-4;
          margin-left: $spacing-2;
          padding: $spacing-0;

          &:hover {
            background: $white;
          }

          > svg {
            color: $nb-svg-grey;
            min-width: $spacing-4;
            min-height: $spacing-4;

            &.starred {
              fill: $nb-yellow-700;
              stroke: $nb-yellow-700;
            }
          }
        }
      }

      .bankAndActionContainer {
        .recipientBankContainer {
          @include setPrint($text-xs, $nb-grey-600);

          display: flex;

          .bankCurrency {
            font-weight: $nb-font-medium;
          }
        }

        .mobileViewDetailsContainer {
          display: none;
        }
      }
    }

    .recipientType,
    .country {
      @include setPrint($text-sm, $nb-grey-600);

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:global(.bp3-skeleton) {
        width: min-content;
      }
    }
  }

  .viewDetailsContainer {
    display: flex;
    align-items: center;
    height: 100%;

    .detailsBtn {
      height: 100%;
      width: 100%;
      padding-right: $spacing-6;
    }
  }
}

@include tablet {
  .container {
    .detailsContainer {
      grid-template-columns: minmax(150px, 1fr) 1fr;

      .country {
        display: none;
      }
    }
  }
}

@include mobile {
  .container {
    padding: $spacing-0;
    height: $spacing-17;

    .legalTypeIconContainer {
      margin-left: $spacing-4;
    }

    .detailsContainer {
      display: block;
      max-width: calc(100% - #{$spacing-21});

      .accountInfoContainer {
        .recipientNameContainer {
          justify-content: space-between;
          max-width: 100%;

          .recipientName {
            @include setPrint($nb-text-base, $nb-grey-900, $nb-font-semibold);
          }
        }

        .bankAndActionContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .recipientBankContainer {
            @include setPrint($nb-text-sm, $nb-grey-600);

            &:global(.bp3-skeleton) {
              width: $spacing-27;
            }
          }

          .mobileViewDetailsContainer {
            display: block;

            .detailsBtn {
              padding: $spacing-0;
              min-height: min-content;

              span {
                @include setPrint($nb-text-sm, $nb-red-700);
              }
            }
          }
        }
      }

      .recipientType,
      .country {
        display: none;
      }
    }

    .viewDetailsContainer {
      display: none;
    }
  }
}
