@import '~styles/_theme';

.filterSection {
  padding-top: $spacing-5;
  padding-bottom: $spacing-5;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .headerLeft {
    display: flex;
    align-items: center;

    .headerTitle {
      margin-right: $spacing-3;

      @include setPrint($nb-text-sm, $nb-grey-900);
    }
  }

  .headerRight {
    button {
      padding: $spacing-0;
      margin-left: $spacing-4;
      height: auto;
      min-height: unset;

      span,
      svg {
        color: $nb-grey-600;
        font-weight: $nb-font-medium;
      }
    }

    .clearBtn {
      transition: transform 0.25s ease-in-out;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .searchInput {
    margin-top: $spacing-2;
    padding: $spacing-px-2;
    margin-left: -$spacing-px-2;
    margin-right: -$spacing-px-2;

    input {
      height: 38px;
      border: none;
    }

    :global(.bp3-input-left-container) svg {
      margin-top: $spacing-px-10;
      margin-left: $spacing-3;
      margin-right: $spacing-2;
      color: $nb-svg-grey;
    }
  }

  .checkboxList {
    position: relative;
    margin-top: $spacing-4;

    label {
      color: $nb-grey-800;
    }

    .loading {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.7);

      :global(.bp3-spinner-head) {
        stroke: $nb-rose-600;
      }
    }
  }
}
