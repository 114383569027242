@import '~styles/_theme';

.container {
  padding: $spacing-4;
  background-color: $nb-grey-100;
  border: $nb-border-grey-1px;
  border-radius: $rounded-base;

  .progressBar {
    :global(.bp3-progress-meter) {
      background-color: $nb-rose-500 !important;
    }

    width: calc(100% - #{$spacing-2});
    background-color: $nb-grey-400 !important;
  }

  .toggleBtn {
    border: none !important;
    background: none !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    padding: 0 !important;

    svg {
      color: $nb-grey-500;
    }
  }

  .topSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-4;

    p {
      @include nb-paragraph;
    }

    p.amountDisplay {
      color: $nb-grey-800;
      font-weight: $nb-font-medium;
    }
  }

  .collapse {
    :global(.bp3-collapse-body) {
      margin-top: $spacing-4;
    }

    p {
      @include nb-paragraph;

      font-size: $nb-text-sm;
      color: $nb-grey-600;

      > a {
        text-decoration: underline;
        font-weight: $font-semibold;
        color: $nb-grey-600;
        display: contents;
      }
    }

    .detailsBalance {
      margin-top: $spacing-4;
      display: flex;
      text-align: left;
      justify-content: space-between;

      p {
        @include nb-paragraph;

        color: $nb-grey-800;
        display: block;
        width: 50%;

        .balanceItem {
          margin-top: $spacing-1;
          font-weight: $nb-font-medium;
        }
      }
    }
  }
}
