@import '~styles/_theme';

.input {
  :global(.bp3-input-action) {
    height: 100%;
  }

  .rightButton {
    margin: 0;
    padding: 0 $spacing-4;
    height: 100%;
  }

  .rightButton :global(.bp3-button-text) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textBtn {
    margin: 3px;
    height: 38px;
    max-height: auto;
    box-shadow: $shadow-sm;
    color: $nb-grey-800;
  }

  .cleanBtn {
    box-shadow: none !important;
    background-color: $white !important;
    border: $border-0;
    color: $nb-grey-700 !important;
    padding: 0 !important;

    svg {
      color: $nb-grey-700 !important;
      width: $text-base;
      height: $text-base;
    }
  }

  :global(.bp3-input):disabled {
    border: $border-0;

    @include setPrint($text-base, $nb-grey-700, $nb-font-regular);
  }

  &.dark {
    :global(.bp3-input):disabled {
      background-color: $nb-grey-300;
    }
  }

  &.light {
    :global(.bp3-input):disabled {
      background-color: $nb-grey-100;
    }
  }

  &.outlined {
    :global(.bp3-input):disabled {
      border: $nb-border-light-grey-1px;
      background-color: $nb-white;

      @include setPrint($text-base, $nb-grey-900, $font-medium);
    }
  }

  &.clean {
    :global(.bp3-input):disabled {
      border: $border-0;
      background-color: $nb-white;
      padding: 0 !important;
      height: $spacing-6;
      border-radius: 0;
      width: $spacing-28;
      cursor: pointer;

      @include setPrint($text-base, $nb-grey-900, $nb-font-regular);
    }
  }
}

.clean {
  display: inline-flex !important;
  justify-content: space-evenly;
  align-items: center;
}
