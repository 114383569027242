//
// Reap Design System – Spacing
//

$spacing-px: 1px;
$spacing-px-2: 2px;
$spacing-px-3: 3px;
$spacing-0: 0;
$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 0.75rem; // 12px
$spacing-4: 1rem; // 16px
$spacing-5: 1.25rem; // 20px
$spacing-6: 1.5rem; // 24px
$spacing-7: 1.75rem; // 28px
$spacing-8: 2rem; // 32px
$spacing-9: 2.25rem; // 36px
$spacing-10: 2.5rem; // 40px
$spacing-11: 2.75rem; // 44px (Input height)
$spacing-12: 3rem; // 48px
$spacing-13: 3.25rem; // 52px
$spacing-14: 3.5rem; // 56px
$spacing-15: 3.75rem; // 60px
$spacing-16: 4rem; // 64px
$spacing-17: 4.25rem; // 68px
$spacing-18: 4.5rem; // 72px
$spacing-20: 5rem; // 80px
$spacing-21: 5.25rem; // 84px
$spacing-22: 5.5rem; // 88px
$spacing-23: 5.75rem; // 92px
$spacing-24: 6rem; // 96px
$spacing-25: 6.25rem; // 100px
$spacing-26: 6.5rem; // 104px
$spacing-27: 6.75rem; // 108px
$spacing-28: 7rem; // 112px
$spacing-29: 7.25rem; // 116px
$spacing-30: 7.5rem; //120px;
$spacing-32: 8rem; // 128px
$spacing-35: 8.75rem; // 140px
$spacing-36: 9rem; // 144px
$spacing-40: 10rem; // 160px
$spacing-42: 10.5rem; // 168px
$spacing-44: 11rem; // 176px
$spacing-45: 11.25rem; // 180px
$spacing-46: 11.5rem; //186px;
$spacing-48: 12rem; // 192px
$spacing-50: 12.5rem; // 200px
$spacing-52: 13rem; // 208px
$spacing-53: 13.25rem; // 212px
$spacing-54: 13.5rem; // 216px
$spacing-60: 15rem; // 240px
$spacing-64: 16rem; // 256px
$spacing-69: 17.25rem; // 276px
$spacing-70: 17.5rem; // 280px
$spacing-74: 18.5rem; // 296px
$spacing-77: 19.25rem; // 308px
$spacing-79: 19.75rem; // 316px
$spacing-80: 20rem; // 320px
$spacing-84: 21rem; // 336px
$spacing-86: 21.5rem; // 344px
$spacing-87: 21.75rem; // 348px
$spacing-88: 22rem; // 352px
$spacing-90: 22.5rem; // 360px
$spacing-96: 24rem; // 384px
$spacing-100: 25rem; // 400px
$spacing-102: 25.5rem; // 408px
$spacing-103: 26rem; // 412px
$spacing-108: 27rem; // 432px
$spacing-112: 28rem; //448px;
$spacing-116: 29rem; // 464px
$spacing-120: 30rem; // 480px
$spacing-123: 30.75rem; // 492px
$spacing-128: 32rem; // 512px
$spacing-140: 35rem; // 560px
$spacing-150: 37.5rem; // 600px
$spacing-160: 40rem; // 640px
$spacing-180: 45rem; // 720px
$spacing-192: 48rem; // 768px
$spacing-196: 49rem; // 784px
$spacing-200: 50rem; // 784px
$spacing-222: 55.5rem; // 888px
$spacing-240: 60rem; // 960px;
$spacing-px-10: 10px;
$spacing-px-6: 6px;
