@import '~styles/_theme';

.formGroup {
  margin: 0;
}

.formGroup + .formGroup {
  margin-top: $spacing-6;
}

.input + .input {
  margin-top: $spacing-2;
}

.phoneInputContainer {
  height: $spacing-11;
  width: 100%;

  &:global(.intl-tel-input) {
    border: none;
  }
}

.intlPhoneInput {
  width: 100%;
}

.radioGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .radioButton {
    padding: $spacing-4 0;
    margin: 0;
    border: $nb-border-grey-1px;
    border-radius: $rounded-lg;
    box-shadow: $shadow-sm;

    @include setPrint($nb-text-sm, $nb-grey-700);

    input[type='radio'],
    :global(.bp3-control-indicator) {
      display: none;
    }

    p {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: $spacing-6;
      height: $spacing-6;
    }

    &.active {
      background-color: $nb-red-100;
      border: $border-1 solid $nb-red-400;
    }
  }

  .radioButton + .radioButton {
    margin-left: $spacing-3;
  }
}
