@import '~styles/_theme';

.filterSection {
  font-weight: $nb-font-medium;

  .title {
    @include setPrint($nb-text-sm, $nb-grey-900, $nb-font-medium);

    margin: 0;
  }

  .content :global(.bp3-collapse-body) {
    margin-top: $spacing-4;
  }
}
