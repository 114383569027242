@import '~styles/_theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  border-radius: $rounded-full;
  border: none;
  box-shadow: none;
  background: $nb-warm-grey-400;
  width: 0.625rem;
  height: 0.625rem;
  margin: $spacing-2 0.375rem;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.active {
    background: $nb-warm-grey-600;
    opacity: 1;
  }

  &.hidden {
    visibility: hidden;
  }

  &:focus {
    outline: none;
  }
}

.arrowButton {
  border-radius: $rounded-full;
  border: none;
  display: flex;
  align-items: center;
  background: transparent;
  color: $nb-grey-600;
  margin: 0 $spacing-4;
  cursor: pointer;

  &:hover {
    background-color: $nb-grey-300;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.disabled:hover {
    background-color: transparent;
  }

  &:focus {
    outline: none;
  }
}
