@import '~styles/_theme';

.container {
  margin-top: $nb-spacing-6;
  margin-bottom: $nb-spacing-6;

  .expandableRow {
    display: flex;
    justify-content: space-between;

    &.hidden {
      display: none;
    }

    &:hover {
      color: $nb-dark-purple;
      cursor: pointer;
    }
  }

  .notes {
    width: 100% !important;
    min-height: 45px;

    &.hidden {
      display: none;
    }
  }
}
