@import '~styles/_theme';

.shareButton {
  height: auto;
  padding: $spacing-4 $spacing-1 * 1.5;

  .icon {
    width: $spacing-12;
    height: $spacing-12;
    border-radius: $rounded-full;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $nb-warm-grey-200;
  }
}
