// Borders

// Border Radius
$rounded-none: 0;
$rounded-xs: 0.125rem; // 2px
$rounded-sm: 0.25rem; // 4px
$rounded-base: 0.375rem; // 6px
$rounded-lg: 0.5rem; // 8px
$rounded-xl: 0.625rem; // 10px
$rounded-2xl: 0.75rem; // 12px
$rounded-full: 9999px;

// Border Width
$border-0: 0;
$border-1: 1px;
$border-2: 2px;
$border-4: 4px;
$border-6: 6px;
$border-8: 8px;

// Shadows
// Note: please use these variables for all box shadows.
// They map directly to the Figma designs with one caveat:
// Figma supports CSS box-shadow blur-radius, but DOES NOT support spread-radius.
// See: https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow
$shadow-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
$shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$shadow-base: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
$shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
  0 10px 10px -5px rgba(0, 0, 0, 0.04);
$shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
$shadow-inner-sm: inset 1px 0 0 rgba(0, 0, 0, 0.06);
$shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
$shadow-outline: 0 0 0 3px rgba(73, 158, 243, 0.25); // $blue-400, 25% opacity
$shadow-none: none;
$shadow-black: 0 0 0 black;
$shadow-outline-lg: 0 0 0 10px rgba(73, 158, 243, 0.25);
$inner-1px-bottom-light: inset 0 -1px 0 #DDE5EE;
$inner-1px-top-light: inset 0 1px 0 #DDE5EE;

// Opacity
$opacity-0: 0;
$opacity-25: 0.25;
$opacity-50: 0.5;
$opacity-75: 0.75;
$opacity-100: 1;

// Z-index
$z-0: 0;
$z-10: 10;
$z-20: 20;
$z-30: 30;
$z-40: 40;
$z-50: 50;
$z-auto: auto;

$dashboard_sidebar_dock_max_width: 767px;
