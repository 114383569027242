@import '~styles/_theme';

.oauthConnect {
  text-align: center;

  > button {
    width: 100%;
    height: $spacing-12;
  }

  .orSeparator {
    position: relative;
    margin-top: $spacing-6;
    margin-bottom: $spacing-6;

    @include nb-h6;

    &::before {
      width: 100%;
      height: 1px;
      content: ' ';
      background: $nb-warm-grey-300;
      position: absolute;
      left: 0;
      top: 50%;
    }

    .label {
      position: relative;
      z-index: 1;
      background: $nb-white;
      padding: $spacing-0 $spacing-3;
    }
  }
}
