@import '~styles/_theme';

.container {
  display: inline-block;
  line-height: 150% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.base {
    height: $spacing-6;
    padding-left: calc(#{$spacing-2} + #{2 * $spacing-px});
    padding-right: calc(#{$spacing-2} + #{2 * $spacing-px});
    display: inline-flex;
    align-items: center;

    @include setPrint($text-xs, $nb-black, $nb-font-regular);
  }

  &.xs {
    height: $spacing-5;
    padding-top: $spacing-px;
    padding-left: calc(#{$spacing-1} + #{2 * $spacing-px});
    padding-right: calc(#{$spacing-1} + #{2 * $spacing-px});

    @include fontStyles($text-xs);
  }

  &.xs2 {
    height: $spacing-5;
    padding: 0 $spacing-2;
    display: inline-flex;
    align-items: center;

    @include fontStyles($text-xs);
  }

  &.sm {
    height: $spacing-6;
    padding: 0 $spacing-2;

    @include fontStyles($text-sm);
  }

  &.lg {
    height: calc(#{$spacing-6} + #{2 * $spacing-px});
    padding: 0 $spacing-3;

    @include fontStyles($text-lg);
  }

  &.round {
    border-radius: 12px;
  }

  &.grey {
    background-color: $nb-grey-100;
    color: $nb-grey-700;

    &.outline {
      background-color: transparent;
      border: $border-1 solid $nb-grey-700;
    }

    .icon {
      color: $nb-grey-700;
    }
  }

  &.deepgrey {
    background-color: $nb-grey-200;
    color: $nb-grey-900;

    &.outline {
      background-color: transparent;
      border: $border-1 solid $nb-grey-900;
    }

    .icon {
      color: $nb-grey-900;
    }
  }

  &.orange {
    background-color: $nb-orange-100;
    color: $nb-orange-800;
    font-weight: $font-semibold;

    &.outline {
      background-color: transparent;
      border: $border-1 solid $nb-orange-800;
    }

    .icon {
      color: $nb-orange-800;
    }
  }

  &.yellow {
    background-color: $nb-yellow-200;
    color: $nb-yellow-900;

    &.outline {
      background-color: transparent;
      border: $border-1 solid $nb-orange-900;
    }

    .icon {
      color: $nb-yellow-900;
    }
  }

  &.indigo {
    background-color: $nb-indigo-200;
    color: $nb-indigo-900;

    &.outline {
      background-color: transparent;
      border: $border-1 solid $nb-orange-900;
    }

    .icon {
      color: $nb-indigo-900;
    }
  }

  &.cyan {
    background-color: $nb-teal-100;
    color: $nb-teal-1000;

    &.outline {
      background-color: transparent;
      border: $border-1 solid $nb-teal-1000;
    }

    .icon {
      color: $nb-teal-1000;
    }
  }

  &.blue {
    background-color: $nb-light-blue-200;
    color: $nb-light-blue-900;

    &.outline {
      background-color: transparent;
      border: $border-1 solid $nb-light-blue-900;
    }

    .icon {
      color: $nb-light-blue-900;
    }
  }

  &.green {
    background-color: $nb-green-200;
    color: $nb-green-900;

    &.inverted {
      background-color: $nb-green-700;
      color: $white;
    }

    &.outline {
      color: $nb-green-800;
      background-color: transparent;
      border: $border-1 solid $nb-green-900;
    }

    .icon {
      color: $nb-green-700;
    }
  }

  &.red {
    background-color: $nb-rose-200;
    color: $nb-rose-900;

    &.inverted {
      background-color: $nb-rose-700;
      color: $white;
    }

    &.outline {
      background-color: transparent;
      border: $border-1 solid $nb-rose-900;
    }

    .icon {
      color: $nb-rose-900;
    }
  }

  &.purple {
    background-color: $nb-violet-200;
    color: $nb-violet-900;

    &.outline {
      background-color: transparent;
      border: $border-1 solid $nb-violet-900;
    }

    .icon {
      color: $nb-violet-900;
    }
  }

  &.warm-grey {
    background-color: $nb-warm-grey-200;
    color: $nb-grey-900;
  }

  &.teal {
    background-color: $nb-teal-200;
    color: $nb-teal-900;
  }

  &.strong {
    font-weight: $font-semibold;
  }

  &.translucent-black {
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
  }

  &.light-violet {
    background-color: $light-violet-200;
    color: $light-violet-900;

    &.outline {
      background-color: transparent;
      border: $border-1 solid $light-violet-400;
    }
  }

  &.includeLeadingDot {
    display: flex;
    align-items: center;
  }

  .leadingDot {
    font-size: $nb-text-2xl;
    margin-right: $nb-spacing-1;

    &.green {
      color: $nb-green-600;
    }

    &.yellow {
      color: $nb-yellow-600;
    }

    &.red {
      color: $nb-red-600;
    }

    &.lightViolet {
      color: $light-violet-500;
    }
  }

  &.withIcon {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-right: $nb-spacing-1;
      display: flex;
      align-items: center;

      svg > * {
        stroke-width: $nb-spacing-8;
      }
    }
  }
}
