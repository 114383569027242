// stylelint-disable no-descending-specificity
@import "~styles/_theme";

$buttonCornerRadius: 6px;

.placeholder.triggerOpen {
  box-shadow: $nb-shadow-outline !important;
}

.placeholder :global(.bp3-button-text) span {
  color: $nb-input-placeholder-color;
}

.rightIcons {
  left: 50%;
  display: flex;
  color: $nb-svg-grey !important;

  svg {
    stroke-width: 2;
    color: $nb-svg-grey !important;
  }

  > .successIcon {
    color: $nb-green-700 !important;
    margin-right: $spacing-3;
  }

  > .clearIcon {
    color: $nb-grey-600;
    background: $nb-grey-100;
    padding: $spacing-1;
    border-radius: $rounded-full;
    width: $spacing-5;
    height: $spacing-5;
  }
}

.backdrop {
  color: $nb-backdrop-color;
}

.drawer {
  border-top-left-radius: $rounded-2xl;
  border-top-right-radius: $rounded-2xl;

  :global(.searchDropdown__control) {
    @include setPrint($text-base, $nb-grey-500);

    background-color: transparent;
    border: none;
    border-top-left-radius: $rounded-base;
    border-top-right-radius: $rounded-base;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    cursor: text;
    margin-bottom: 0;
    padding: $spacing-2 $spacing-6;
    height: $spacing-16;
    box-shadow: $shadow-outline;
  }

  :global(.searchDropdown__control--is-focused) {
    box-shadow: none !important;
  }

  :global(.searchDropdown__value-container) {
    padding: 0;
  }

  :global(.searchDropdown__input) {
    @include setPrint($text-base, $nb-grey-900);

    padding-left: $spacing-1;
  }

  :global(.searchDropdown__single-value),
  :global(.searchDropdown__placeholder) {
    @include setPrint($text-base, $nb-input-placeholder-color);

    padding-left: $spacing-8;
    margin: unset 0;
  }

  :global(.searchDropdown__menu-list) {
    margin: $spacing-1 0;
    padding-top: 0;
    max-height: 50vh;

    @media (max-width: 575px) {
      height: 50vh;
      padding-bottom: 3rem;
    }
  }

  :global(.searchDropdown__menu) {
    margin: 0;
    border-radius: 0;
    border: none;
    bottom: 0;
  }

  :global(.searchDropdown__option) {
    padding: $spacing-2 $spacing-4;

    @include setPrint($nb-text-base, $nb-grey-900, $nb-font-regular);
  }

  :global(.searchDropdown__option--is-focused) {
    background: $nb-warm-grey-200 !important;
    color: $nb-grey-800;
  }

  :global(.searchDropdown__option--is-selected) {
    background: $nb-dark-violet !important;
    color: $nb-white;
  }

  :global(.bp3-icon.searchIcon) {
    margin-right: 10px;
    transform: translateY(2px);
    vertical-align: baseline;
  }
}

.searchDropdownPopover {
  @include setPrint($text-base, $nb-grey-800);

  :global(.searchDropdown__control) {
    @include setPrint($text-base, $nb-grey-500);

    background-color: $nb-white;
    border: none;
    border-top-left-radius: $rounded-base;
    border-top-right-radius: $rounded-base;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    cursor: text;
    margin-bottom: 0;
    padding: $spacing-2 $spacing-6;
    height: $spacing-16;
    box-shadow: $nb-shadow-outline !important;
  }

  :global(.searchDropdown__value-container) {
    padding: 0;
  }

  :global(.searchDropdown__input) {
    @include setPrint($text-base, $nb-grey-900);

    padding-left: $spacing-1;
  }

  :global(.searchDropdown__single-value),
  :global(.searchDropdown__placeholder) {
    @include setPrint($text-base, $nb-input-placeholder-color);

    padding-left: $spacing-8;
    margin: unset 0;
  }

  :global(.searchDropdown__menu-list) {
    margin: $spacing-1 0;
    padding-top: 0;
    max-height: 50vh;
    height: $spacing-50;
    background-color: $nb-white;

    @media (max-width: 575px) {
      height: 50vh;
      padding-bottom: 3rem;
    }
  }

  :global(.searchDropdown__menu) {
    margin: 0;
    border-radius: 0;
    border: none;
  }

  :global(.searchDropdown__option) {
    padding: $spacing-2 $spacing-4;

    @include setPrint($nb-text-base, $nb-grey-900, $nb-font-regular);
  }

  :global(.searchDropdown__option--is-selected) {
    background: $nb-dark-violet !important;
    color: $nb-white;
  }

  :global(.searchDropdown__option--is-focused) {
    background: $nb-warm-grey-200 !important;
    color: $nb-grey-800;
  }

  :global(.searchDropdown__group-heading) {
    font-size: $nb-text-sm;
    padding: $spacing-2 $spacing-4;
    margin-bottom: $spacing-0;
    text-transform: none;
    @include setPrint($nb-text-sm, $nb-grey-600, $nb-font-regular);
  }

  :global(.searchDropdown__group:not(:last-child)) {
    border-bottom: $nb-border-warm-grey-1px;
  }

  :global(.searchDropdown__group:first-child) {
    margin-top: -$spacing-1;
  }

  :global(.bp3-icon.searchIcon) {
    margin-right: 10px;
    transform: translateY(1px);
    vertical-align: baseline;
  }

  :global(.bp3-button.bp3-minimal) {
    background: $white;
    border: $nb-border-grey-1px;
    border-radius: $buttonCornerRadius;
    justify-content: space-between;
    padding: $spacing-2 $spacing-3;
    width: 100%;
    box-shadow: $shadow-sm;

    &:focus,
    &:hover {
      border-color: transparent;
      outline: 0;
      box-shadow: $nb-shadow-outline;
    }

    :global(.bp3-button-text) {
      border-color: $nb-grey-800;

      @include setPrint($text-base, $nb-grey-900, $nb-font-regular);
    }

    :global(.bp3-icon),
    svg {
      color: $nb-svg-grey;
    }

    @include mobile {
      &:focus,
      &:hover {
        border-color: transparent;
        outline: 0;
        box-shadow: $shadow-outline;
      }
    }
  }

  :global(.bp3-button.bp3-minimal).placeholder {
    .bp3-button-text {
      @include setPrint($text-base, $nb-grey-900);

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  :global(.bp3-button.bp3-minimal.bp3-active) {
    border-color: $nb-button-primary-border-color;
    outline: 0;
    box-shadow: $shadow-outline;
    color: $nb-grey-800;
  }

  :global(.bp3-button.bp3-disabled) {
    background: $nb-grey-200;
    outline: 0;
    box-shadow: $shadow-none;

    span {
      color: $nb-grey-700 !important;
    }

    &:focus,
    &:hover {
      background: $nb-grey-200;
      border-color: $nb-grey-400;
      outline: 0;
      box-shadow: $shadow-none;
    }
  }

  :global(.bp3-overlay) {
    display: block;
    position: relative;
  }

  :global(.bp3-transition-container) {
    transform: none !important;
    will-change: unset !important;
    width: 100%;

    :global(.bp3-popover) {
      border: none;
      border-radius: 14px 14px 0 0;
      box-shadow: none;
      width: 100%;

      :global(.bp3-popover-content) {
        border-radius: 14px 14px 0 0;
        padding: $spacing-0;
      }
    }
  }
}

.searchDropdownPopoverTopPlacement {
  @include setPrint($text-base, $nb-grey-800);

  :global(.searchDropdown__control) {
    @include setPrint($text-base, $nb-grey-500);

    background-color: $nb-white;
    border: none;
    border-radius: $rounded-base;
    cursor: text;
    margin-bottom: 0;
    padding: $spacing-2 $spacing-6;
    height: $spacing-16;
    box-shadow: $nb-shadow-outline !important;
  }
}

.shiftRightIcons {
  svg {
    stroke-width: 2;
    color: $nb-svg-grey !important;
    margin-right: -0.45rem;
  }
}
