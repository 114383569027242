@import '~styles/_theme';

.title {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.intervalString {
  text-transform: lowercase;
}

.label,
.billTo {
  @include setPrint($nb-text-sm, $nb-grey-600, $nb-font-medium);
}

.label {
  margin-bottom: $spacing-1;
}

.billToAndStatusBadge {
  display: flex;
  justify-content: space-between;
}

.billTo {
  @include mobile {
    margin-bottom: $spacing-1;
  }

  .recipientName {
    @include setPrint($text-base, $nb-grey-900);
  }

  .accountNumber {
    @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);
  }
}

.dateCaption {
  color: $nb-grey-600;
  margin-left: $spacing-1;

  @include mobile {
    margin-left: 0;
  }
}

.buttonsContainerRecurring {
  flex-direction: column;

  button {
    flex: 1;
    margin: 0 !important;

    &:first-child {
      margin-bottom: $spacing-3 !important;
    }
  }
}

.quickActions {
  max-width: $spacing-32;
  padding-top: $spacing-2;
  .cancelButton {
    @include setPrint($text-sm, $nb-grey-800, $font-semibold);

    padding: $spacing-1;
    box-shadow: $shadow-sm !important;
    border-radius: $rounded-base;
    width: 100%;
    border: $nb-border-grey-1px;
    font-weight: $nb-font-medium;
  }
}

.paymentDetailsContainer {
  @include setPrint($nb-text-base, $nb-grey-900, $nb-font-medium);

  .amount {
    @include setPrint($nb-text-2xl, $nb-grey-900, $nb-font-medium);

    margin-bottom: $spacing-6;

    :global {
      .amount_with_tag {
        .code {
          font-size: $text-base;
        }
      }
    }
  }

  .totalExplain {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;

    > div {
      flex: 1;
    }

    :global {
      .amount_with_tag {
        font-weight: $font-medium;
      }
    }
  }

  .discountedNote {
    border: $border-1 dashed $nb-green-400;
    border-radius: $rounded-base;
    margin-top: $spacing-1;
    padding: 6px 12px;
    background-color: $nb-green-100;

    @include setPrint($nb-text-sm, $nb-green-900, $nb-font-regular);

    strong {
      font-weight: $nb-font-medium;
    }
  }
}

.paymentInfoItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: $spacing-4;

  @include setPrint($nb-text-sm, $nb-grey-900, $nb-font-regular);

  svg {
    min-width: 30px;
    margin-right: $spacing-3;
  }
}

@include mobile {
  .billToAndStatusBadge {
    flex-direction: column;
  }

  .paymentDetailsContainer {
    .amount {
      margin-bottom: $spacing-4;
      font-size: $text-xl;
    }

    .totalExplain {
      flex-direction: column;

      > div {
        justify-content: space-between;
        font-size: $text-sm;
        font-weight: $font-medium;
      }
    }
  }

  .itemLabel {
    text-align: left;
  }

  .panelTitle {
    flex: 2;
    justify-content: center;

    .title {
      align-items: center;
    }
  }
}

.dateItem {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.fastPaymentBadge {
  display: inline-flex;
  align-items: center;
  font-weight: $font-semibold;
  margin-left: $spacing-3;

  @include mobile {
    margin-top: $spacing-1;
    margin-left: 0;
  }
}

// treat as image
.redDot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: $rounded-full;
  background-color: $nb-red-500;
  margin-right: 6px;
}

.loading {
  background: $white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconTooltip {
  position: relative;
  top: $spacing-px-2;
  color: white;
  background-color: black;
  padding: $spacing-2;
  border-radius: $rounded-sm;
}
