@import '~styles/_theme';

.mobilePortal {
  z-index: 10;
  min-height: 100vh;

  .mobileContainer {
    background: $white;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .mobileHeaderContainer {
      display: flex;
      flex-direction: row;
      height: $nb-spacing-16;
      align-items: center;
      padding: $nb-spacing-5 $nb-spacing-4;
      background: $white;
      width: 100%;
      box-shadow: $nb-inner-1px-bottom-warm-gray-300;
      z-index: 1;

      .backButton {
        padding: $nb-spacing-0;
      }

      .title {
        @include nb-h3($nb-dark-violet);

        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .content {
      padding: $nb-spacing-5 $nb-spacing-4;
      padding-bottom: $nb-spacing-4;
      width: 100%;
      overflow: scroll;
      flex: 1;
    }

    .mobileBottomActionContainer {
      border-top: $nb-border-light-grey-1px;
      align-items: center;
      width: 100%;
      bottom: 0;
      padding: $nb-spacing-4;

      .mobileBtn {
        width: 100%;
        height: 44px;
      }

      > * + * {
        margin-top: $spacing-3;
      }
    }
  }
}
