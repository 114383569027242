@import '~styles/_theme';

.container {
  display: flex;
  flex-direction: column;
  padding: $spacing-8 $spacing-8 $spacing-4 $spacing-8;
  border-radius: $rounded-lg $rounded-lg 0 0;
  min-height: $spacing-96;

  @include mobile {
    padding: $spacing-6 $spacing-4;
  }

  .content {
    display: flex;
    justify-content: center;

    @include mobile {
      flex-direction: column;
    }
  }

  .item {
    text-align: center;
    padding: $spacing-8;
    width: 33%;

    @include mobile {
      padding: $spacing-2 $spacing-0;
      text-align: unset;
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .itemIcon {
    padding-top: inherit;
  }
}

.header {
  display: flex;
  text-align: center;
  font-family: $nb-orbikular-font;

  @include setPrint($nb-text-xl, $light-violet-1000, $nb-font-bold);

  :last-child {
    margin-left: auto;
  }
}

.action {
  display: flex;
  border-radius: 0 0 $rounded-lg $rounded-lg;
  background-color: $nb-warm-grey-100;
  padding: $spacing-6 $spacing-8 $spacing-6 $spacing-8;
  margin-bottom: $spacing-6;

  @include mobile {
    flex-direction: column;
    padding: $spacing-6;
  }

  :last-child {
    display: flex;
    margin-left: auto;
    align-items: center;

    @include mobile {
      margin-left: unset;
    }
  }
}

.title {
  font-family: $nb-aeonik-font;

  @include setPrint($nb-text-lg, $light-violet-1000, $nb-font-bold);
}

.desc {
  padding-top: $spacing-1;
  font-family: $nb-aeonik-font;

  @include setPrint($nb-text-base, $nb-warm-grey-700, $nb-font-regular);
}
