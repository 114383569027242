@import '~styles/_theme';

.checkIcon,
.verifyBtn {
  position: relative;
  height: 34px;
  bottom: 39px;
  left: 100%;
  color: $nb-green-700;
  transform: translateX(calc(-100% - #{$spacing-9}));
}

.inputContainer {
  height: $spacing-11;
  box-shadow: $shadow-sm;
}

.drawer {
  padding-top: $spacing-2;
  border-top-left-radius: $rounded-2xl;
  border-top-right-radius: $rounded-2xl;
  overflow: auto;

  .drawerBtn {
    justify-content: flex-start;
    padding: $spacing-0 $spacing-6;
    margin: $spacing-px-3 $spacing-0;
    font-weight: $nb-font-regular;
    font-size: $nb-text-base;
  }
}

.backdrop {
  background-color: $nb-backdrop-color;
}

.prefixLabel {
  position: relative;
  display: block;
  z-index: 3;
  padding: 0 11px;
  left: 0;
  width: $spacing-32;
  line-height: 42px;
  top: 44px;
  margin-top: -44px;
  bottom: 0;
  text-align: center;
  transition: all 0.3s;
  white-space: nowrap;
  background-color: $nb-grey-100;
  color: $nb-grey-700;
  border: none;
  box-shadow: $nb-inner-1px-right-gray-400;
  border-bottom-left-radius: $rounded-lg;
  border-top-left-radius: $rounded-lg;
}

.offset {
  margin-bottom: 44px;
}
