@import '~styles/_theme';

.panelTitle {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    font-size: $nb-text-lg;
    color: $nb-dark-violet;
  }
}

.previewBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: $spacing-2;
  }
}

.title {
  @include setPrint($text-base, $nb-dark-violet, $font-semibold);
}

.basicInfo {
  width: 100%;
  margin-bottom: $spacing-6;

  @include mobile {
    align-items: flex-start;
  }

  .userInfo {
    position: relative;
  }

  .name {
    @include setPrint($text-base, $nb-grey-900, $font-semibold);
    @include textOverflow;

    max-width: 100%;
  }

  .email {
    @include setPrint($text-sm, $nb-grey-600);
    @include textOverflow;

    max-width: 100%;
    font-weight: $nb-font-regular;
    margin-bottom: $spacing-2;
  }

  .status {
    width: $spacing-24;
    height: $spacing-6;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;

    @include mobile {
      margin-top: $spacing-2;
    }
  }
}

.feeBar {
  margin-top: $spacing-2;
  background-color: $nb-grey-200;
}

.feeInfo {
  .infoIcon {
    @include questionMarkStyle(12px, $nb-red-500, 8px);
  }

  .feeDetail {
    margin-top: $spacing-2;
    display: flex;
    justify-content: space-between;

    .venderFee {
      text-align: right;
    }

    .label {
      @include setPrint($text-base, $nb-grey-900, $font-semibold);
    }

    .reminder {
      @include setPrint($text-xs, $nb-grey-700);

      .hint {
        margin-left: $spacing-1;

        @include mobile {
          margin: 0;
          display: block;
        }
      }
    }
  }
}

.tooltipContainer {
  @include setPrint($text-sm, $nb-grey-600);

  max-width: $spacing-80;

  .hightlight {
    color: $nb-purple-500;
  }
}

.paymentDetail {
  margin-top: $spacing-6;

  .copyLink {
    margin: $spacing-2 0 $spacing-6;
  }

  .paymentDetailItem {
    @include setPrint($text-sm, $nb-grey-900);

    display: flex;
    align-items: center;
    margin-top: $spacing-4;
    font-weight: $nb-font-regular;

    svg {
      color: $nb-grey-600;
      margin-right: $spacing-3;
    }
  }

  .deleteButton {
    margin-top: $spacing-6;

    span {
      color: $nb-dark-violet !important;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
}

.rcDeductBadge {
  height: $spacing-8;
  padding: $spacing-1 $spacing-3;
  border: $border-1 dashed $nb-green-400;
  border-radius: $rounded-base;
  background-color: $nb-green-100;
  white-space: nowrap;

  @include setPrint($text-sm, $nb-green-900, $font-medium);
}

.recurringBillTitle {
  display: flex;
  justify-content: space-between;

  @include setPrint($text-base, $nb-dark-violet, $font-semibold);
}

.recurringBillDesc {
  @include setPrint($text-base, $nb-grey-900);

  font-weight: $nb-font-regular;
}

.createdOn {
  @include setPrint($text-base, $nb-grey-600);

  font-weight: $nb-font-regular;
  margin: $spacing-1 0;
}

.recurringId {
  font-family: 'Menlo', monospace;
  color: $nb-grey-800 !important;
  border: $border-1 solid $nb-warm-grey-300 !important;
  background: $nb-warm-grey-100 !important;
  width: fit-content;
  border-radius: $rounded-base;
  padding: $spacing-1 $spacing-2;
  margin-top: $spacing-1;
}

.badgeHolder {
  align-self: flex-start;
  margin-top: $spacing-1;
}

.billinfoHeader {
  margin-top: $spacing-4;
  padding: $spacing-2 0;
  border-bottom: $border-1 solid $nb-warm-grey-300;
}

.infoBlock {
  padding: $spacing-4 0;
  border-bottom: $border-1 solid $nb-warm-grey-300;
  display: flex;
  justify-content: space-between;

  p:first-child {
    text-align: left;

    @include setPrint($text-sm, $nb-grey-600);
  }

  p:last-child {
    text-align: right;

    @include setPrint($text-sm, $nb-grey-900, $nb-font-regular);
  }
}

.paymentMethodInline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing-3;

  > div {
    display: flex;
    justify-content: left;

    p.expire {
      @include setPrint($text-sm, $nb-grey-600);

      margin-left: $spacing-4;
    }

    p.last4 {
      @include setPrint($text-sm, $nb-grey-900);

      margin-left: $spacing-3;
    }
  }
}

.paymentMethodBlock {
  margin-top: $spacing-6;
}

.paymentActivity {
  margin-top: $spacing-6;
}

.billPayments {
  .activity {
    margin-top: $spacing-2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .failText p {
      color: $nb-red-600 !important;
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .circleAtom {
    background: $nb-grey-500;
    color: $white;
    border-radius: $rounded-full;
    width: 32px;
    height: 32px;
    padding: 6px;
    overflow: visible;
  }

  .successAtom {
    background: $nb-green-600;
  }

  .failAtom {
    background: $nb-red-600;
  }

  .refundedAtom {
    background: $nb-light-blue-600;
  }

  .dueDate {
    margin-left: $spacing-3;
    font-size: $text-sm;
    font-weight: $nb-font-regular;
    color: $nb-grey-900 !important;
    margin-bottom: $spacing-1;
  }
}

.connectorAtom {
  width: 2px;
  background-color: $nb-warm-grey-400;
  min-height: 16px;
  margin: 6px 0;
  position: relative;
  left: 15px;
}

.reminders {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.declineBlock {
  margin-top: $spacing-2;
  margin-left: $spacing-8;
  width: calc(480px - #{$spacing-36});

  @include mobile {
    width: 75vw;
  }

  // stylelint-disable-next-line no-descending-specificity
  p {
    font-size: $text-sm;
    font-weight: $nb-font-regular;
    color: $nb-grey-600 !important;
  }

  .header {
    font-weight: 500;
    color: $nb-grey-800 !important;
  }

  button + button {
    margin-left: $spacing-4;
  }
}

.confirmModal {
  .chargeText {
    margin-bottom: $spacing-3;
  }

  .paymentMethodModal {
    margin: $spacing-6 0;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;

    p.expire {
      @include setPrint($text-sm, $nb-grey-600, $nb-font-regular);

      margin-left: $spacing-4;
    }

    p.last4 {
      @include setPrint($text-sm, $nb-grey-900);

      margin-left: $spacing-3;
    }
  }
}

.fxQuote {
  margin-top: $spacing-4;

  // stylelint-disable-next-line no-descending-specificity
  > div {
    border-top: $border-1 solid $nb-warm-grey-400;
    padding: $spacing-3 0;
    display: flex;
    justify-content: space-between;

    @include setPrint($text-sm, $nb-grey-600, $nb-font-medium);
  }

  &:last-child {
    border-bottom: $border-1 solid $nb-warm-grey-400;
  }

  .fxRate {
    @include setPrint($text-sm, $nb-grey-900, $nb-font-regular);
  }
}

.clickable {
  cursor: pointer;
}

.itemMenu {
  margin-left: $spacing-6;

  @include mobile {
    margin-left: 0;
  }

  // stylelint-disable-next-line no-descending-specificity
 
}

.tooltip {
  max-width: 312px;
  border-radius: $rounded-lg !important;
  box-shadow: $nb-shadow-tooltip !important;

  :global(.bp3-popover-content) {
    cursor: pointer;
    padding: $spacing-4;
    align-items: center;
    border-radius: $rounded-lg !important;

    @include setPrint($text-sm, $nb-grey-700, $nb-font-regular);

    p:first-child {
      @include setPrint($text-sm, $nb-grey-600, $nb-font-regular);

      text-transform: uppercase;
    }
  }
}
