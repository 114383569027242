@import '~styles/_theme';

.drawer > :global(.bp3-overlay > .bp3-drawer) {
  box-shadow: $shadow-xl;
  border-top-left-radius: $rounded-2xl;
  border-top-right-radius: $rounded-2xl;
  min-height: 65%;
  height: fit-content;
  max-height: 100%;
}

.dialog :global(.bp3-overlay > .bp3-dialog-container > .bp3-dialog) {
  box-shadow: $shadow-xl;
  background: $white;
  padding-bottom: $spacing-0;
}

.container {
  position: relative;
  padding: $spacing-8;
  display: flex;
  flex-direction: row;
  background: $white;
  width: 100%;
  border-radius: $rounded-2xl;

  .closeBtnContainer {
    position: absolute;
    top: $spacing-4;
    right: $spacing-4;
  }


  .mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      @include setPrint($nb-text-lg, $nb-grey-1000, $nb-font-regular);

      margin-bottom: $spacing-2;
    }

    .subTitle1 {
      @include setPrint($nb-text-sm, $nb-grey-600, $nb-font-regular);
    }

    .subTitle2 {
      @include setPrint($nb-text-sm, $nb-grey-600, $nb-font-regular);
    }

    &.center {
      text-align: center;
      align-items: center;
    }

    .childrenContainer {
      margin-top: $spacing-6;
      width: 100%;
    }

    .footerContainer {
      width: 100%;
      display:flex;
      margin-top: $spacing-6;

      &.left {
        flex-direction: row;
        button:nth-child(n+2) {
          margin-left: $nb-spacing-3;
        }
      }
      &.right {
        flex-direction: row-reverse;
        button:nth-child(n+2) {
          margin-right: $nb-spacing-3;
        }
      }
      &.fullWidth {
        flex-direction: row-reverse;
        button:nth-child(n+2) {
          margin-right: $nb-spacing-3;
        }
      }
    }
  }
}


.topIllustrationContainer {
  padding: 0 0 $spacing-8 0;
}

.topIllustrationFooter {
  padding: 0 $spacing-8;
}

.topIllustrationChildrenContainer {
  width: 100%;
}

@include mobile {
  .container {
    position: relative;
    padding: $spacing-6 $spacing-5 $spacing-4 $spacing-5;
    justify-content: center;
    height: 100%;

    .closeBtnContainer {
      position: absolute;
      top: $spacing-3;
      right: $spacing-3;
      width: $spacing-8 !important;
      height: $spacing-8 !important;
      min-width: $spacing-8 !important;
      min-height: $spacing-8 !important;
      background: $nb-warm-grey-200;
      border-radius: $rounded-full;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .closeBtnContainerWithIllustration{
      background: $white;
    }

    .warningIconContainer {
      width: $spacing-12;
      height: $spacing-12;
      min-width: $spacing-12;
      min-height: $spacing-12;
    }

    .mainContainer {
      margin-left: $spacing-0;
      align-items: center;
      height: 100%;
      max-height: 90vh;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: $spacing-4;

      .title {
        @include setPrint($nb-text-xl, $nb-grey-1000, $nb-font-medium);

        margin-bottom: $spacing-1;
      }

      .childrenContainer {
        margin-top: $spacing-4;
        height: 100%;
      }

      .footerContainer {
        display: inline-block;

        &.fullWidth {
          button:nth-child(2) {
            margin-top: $nb-spacing-3;
          }
        }
      }
    }
  }
  .topIllustrationContainer {
    padding: 0 0 $spacing-4 0;
  }
  .topIllustrationFooter {
    padding: 0 $spacing-8;
  }
}
