@import '~styles/_theme';

.paginate {
  background: $nb-white;
  border-bottom-left-radius: $rounded-lg;
  border-bottom-right-radius: $rounded-lg;
  margin: $spacing-0 auto;

  .pageContainer,
  .previousClassName,
  .nextClassName,
  .breakClassName {
    width: $spacing-10;
    height: $spacing-15;
    transform: translateY(-$spacing-px);
  }

  .previousClassName,
  .nextClassName {
    margin: $spacing-0;
  }

  .activatePage {
    border: $border-0;
    border-top: $border-2 solid $nb-rose-600;
    background: $nb-white;
    border-radius: $rounded-none;

    .activeLinkClassName {
      color: $nb-red-700;
    }
  }

  .pageLinkClassName:not(.activeLinkClassName) {
    transform: translateY($spacing-px);
  }
}

@include mobile {
  .paginate {
    margin: $spacing-0;
  }
}

.container {
  table.customerTable {
    .repaymentExpander {
      padding-right: $nb-spacing-6;
    }

    &.repaymentTable {
      thead th:nth-child(2),
      tbody td:nth-child(2) {
        width: 50%;
      }
    }
  }

  .emptyCustomersContainer {
    padding-top: $nb-spacing-8;
    padding-bottom: $nb-spacing-6;

    .emptyStateImg {
      width: $nb-spacing-62;
    }

    .emptyStateHeader {
      margin-top: $nb-spacing-6;
    }

    .emptyStateSubheader {
      width: 100%;
    }

    .emptyStateActionGroups {
      margin-top: $nb-spacing-4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .emptyStateActionBtn {
        width: 100%;
      }

      .emptyStateActionBtn:first-of-type {
        margin-right: $nb-spacing-0;
      }

      .emptyStateActionBtn:not(:first-of-type) {
        margin-top: $nb-spacing-2;
      }
    }
  }

  @include setPrint($nb-text-sm, $nb-grey-900, $nb-font-regular);
}

@include mobile {
  .container {
    .emptyCustomersContainer {
      padding-top: $nb-spacing-8;
      padding-bottom: $nb-spacing-6;

      .emptyStateImg {
        width: $nb-spacing-62;
      }

      .emptyStateHeader {
        margin-top: $nb-spacing-6;
      }

      .emptyStateSubheader {
        width: 100%;
      }

      .emptyStateActionGroups {
        margin-top: $nb-spacing-4;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .emptyStateActionBtn {
          width: 100%;
        }

        .emptyStateActionBtn:first-of-type {
          margin-right: $nb-spacing-0;
        }

        .emptyStateActionBtn:not(:first-of-type) {
          margin-top: $nb-spacing-2;
        }
      }
    }
  }
}

@include tabletWithSidebar {
  .container {
    .txActions {
      .switch {
        display: none;
      }
    }
  }
}

.customerItemMenu.customerItemMenu {
  padding: $spacing-1;
  box-shadow: $nb-shadow-lg;

  @include mobile {
    margin-top: $spacing-3;
  }

  li {
    border-radius: $rounded-base;
  }

  > div:not(:last-child) {
    border-bottom: $nb-border-light-grey-1px;
    padding-bottom: $spacing-1;

    @include mobile {
      border-bottom: none !important;
    }
  }

  > div:last-child {
    padding-top: $spacing-1;
  }
}

.menuItem {
  display: flex;
  align-items: center;
  color: $nb-grey-800;

  @include setPrint($nb-text-sm, $nb-grey-800, $nb-font-regular);

  @include mobile {
    padding: $spacing-2 $spacing-5;
  }

  // stylelint-disable-next-line no-descending-specificity
  svg {
    color: $nb-svg-grey;
    margin-right: $spacing-4;
  }
}

.drawer {
  border-top-left-radius: $rounded-lg;
  border-top-right-radius: $rounded-lg;
}

.deletePopup {
  padding: $spacing-4;
  background-color: $white;

  .buttonContainer {
    margin-top: $spacing-10;
    display: flex;

    button {
      width: 100%;
    }
  }
}

.deletePortalDialog {
  z-index: 999;
}

.deleteDialog {
  background-color: $white;
}
