@import '~styles/_theme';

.button {
  border: $nb-border-grey-1px;
  border-radius: $rounded-base;
  background-color: $white;
  padding: $spacing-3 $spacing-4;

  // override
  &:global(.bp3-button) {
    background-color: $white;
    box-shadow: $shadow-sm;
  }

  &:global(.bp3-button):hover {
    box-shadow: none;
  }

  &:global(.bp3-button).checked {
    background-color: $nb-button-primary-bg;
    border-color: $nb-button-primary-border-color;

    .label {
      color: $nb-grey-900;
    }
  }

  // override
  &:global(.bp3-button):not([class*='bp3-intent-']):not([class*='bp3-minimal']) {
    box-shadow: $shadow-sm;
  }

  & > :global(.bp3-button-text) {
    width: 100%;
  }

  // stylelint-disable-next-line no-descending-specificity
  & .label {
    font-size: $text-sm;
    line-height: $leading-normal;
    color: $nb-grey-800;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.center {
      justify-content: center;
    }
  }

  .checkbox {
    margin: 0;

    :global(.bp3-control-indicator) {
      margin-right: 0;
    }
  }

  .childContainer {
    display: flex;
    align-items: center;
  }
}
