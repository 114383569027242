@import '~styles/_theme';

.list {
  margin-top: $spacing-4;

  .selectBtn {
    padding: 0;
    border: $nb-border-grey-1px;
    border-radius: $rounded-full;
    box-shadow: $nb-shadow-sm;
    height: $spacing-6;
    width: $spacing-6;

    &.selected {
      background: linear-gradient(252.43deg, #FF5029 0%, #FD3A8B 100%);
      border: none;

      svg {
        color: $white;
      }
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: $spacing-3 $spacing-4;
    box-shadow: $nb-shadow-base;
    border: $nb-border-grey-1px;
    border-radius: $rounded-base;
    background: $white;
    color: $nb-grey-900;
    text-align: left;
    cursor: pointer !important;

    @include nb-feature-paragraph;

    &:focus {
      border: $border-2 solid $nb-rose-400 !important;
    }

    &.selected {
      border: $border-2 solid $nb-rose-400 !important;
      box-shadow: $nb-shadow-md !important;
      background-color: $nb-rose-100 !important;
    }
  }

  // stylelint-disable-next-line no-descending-specificity
  > button + button {
    margin-top: $spacing-4;
  }
}
