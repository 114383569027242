@import '~styles/_theme';

.input + .input {
  margin-top: $spacing-2;
}

.inputContainer {
  height: $spacing-11;
  box-shadow: $shadow-sm;
}

.invoiceAddCustomer {
  margin-bottom: 1.7rem;
}

.countrySelectSearchDropDown {
  @include setPrint($text-base, $nb-grey-800);

  :global(.searchDropdown__control) {
    @include setPrint($text-base, $nb-grey-500);

    background-color: $nb-white;
    border: none;
    border-radius: $rounded-base;
    cursor: text;
    margin-bottom: 0;
    padding: $spacing-2 $spacing-6;
    height: $spacing-16;
    box-shadow: $nb-shadow-outline !important;
  }
}
