@import '~styles/_theme';

.container {
  background-color: $nb-warm-grey-200;
  padding: $spacing-16 $spacing-8 0 $spacing-8;
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;

  @include tablet {
    padding: $spacing-6;
  }

  @include mobile {
    padding: $spacing-4;
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
    position: absolute;
    top: $spacing-4;
    right: $spacing-8;

    .search {
      flex: 1;
    }

    .navActions {
      padding-left: $spacing-6;
      display: flex;
      align-items: center;
      color: $nb-grey-200;
    }
  }

  .header {
    margin-bottom: $spacing-6;

    &.hidden {
      display: none;
    }
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actionsGroup {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .title {
      flex: 1;

      @include fontStyles($text-3xl);

      @include mobile {
        @include fontStyles($text-lg);
      }

      // migrate from CollectPayment/SignupContainer global css
      h1 {
        margin: 0;

        @include nb-h2;
      }
    }
  }

  .content {
    z-index: 1;
    position: relative;
    margin-bottom: $spacing-8;
  }

  .titleRightElementContainer {
    margin-left: $spacing-6;
    z-index: 0;

    .tempBtn {
      /* stylelint-disable-next-line no-descending-specificity */
      &:hover,
      &,
      &:disabled,
      &:disabled:hover {
        background: $nb-button-primary;
        color: $white;
      }

      @include fontStyles($text-sm);

      border-radius: $rounded-base;
      padding: 0 $spacing-3;
      min-height: $spacing-9;
      white-space: nowrap;

      @include mobile {
        padding: 0 $spacing-3;
        min-height: $spacing-8;
      }

      svg {
        color: $white;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .tempBtn:first-of-type {
      background: $nb-button-primary;
      border-right: $border-1 solid $nb-rose-800;
      margin-right: $spacing-0;
      padding: $spacing-px $spacing-4;
      min-height: $spacing-9;
      border-top-right-radius: $rounded-none;
      border-bottom-right-radius: $rounded-none;

      &:hover,
      &:focus {
        background: $nb-button-primary-hovered;
      }

      @include mobile {
        padding: 0 $spacing-3;
        min-height: $spacing-8;
      }

      &:only-child {
        border-top-right-radius: $rounded-base;
        border-bottom-right-radius: $rounded-base;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      & + span .tempBtn {
        min-width: 0;
        padding: 0 $spacing-2;
        border-top-left-radius: $rounded-none;
        border-bottom-left-radius: $rounded-none;

        svg {
          margin: 0;
        }
      }

      &:global(.bp3-button:not(:last-child)) {
        margin-right: $spacing-0;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    button:global(.rounded) {
      border-top-right-radius: $rounded-base;
      border-bottom-right-radius: $rounded-base;
    }

    :global(.bp3-popover-wrapper) {
      /* stylelint-disable-next-line no-descending-specificity */
      button:first-of-type {
        border-right: none;
      }
    }

    :global(.bp3-button-group) {
      background: $nb-button-primary;
      border-radius: $rounded-base;
      /* stylelint-disable-next-line no-descending-specificity */
      button {
        background: none !important;
      }
    }
  }
}

@include tablet {
  .container {
    padding: $spacing-5;

    .floatingTitleContainer {
      position: fixed;
      z-index: 3;
      top: 0;
      right: 0;
      padding: 0;
      width: calc(100% - #{$spacing-16});
      height: $spacing-16;
      left: $spacing-16;
      padding-right: $spacing-5;

      .title.floatingTitle {
        @include fontStyles($text-lg);

        color: $white;
      }
    }

    .titleRightElementContainer {
      .tempBtn {
        @include fontStyles($text-sm);

        padding: 0 $spacing-3;
      }

      .tempBtn:first-of-type {
        padding: $spacing-1 $spacing-3;

        svg {
          margin-right: $spacing-1;
        }
      }
    }

    .titleContainer {
      &.isAdminLogInAsUser {
        margin-top: 40px;
      }
    }

    .floatingContent {
      margin-top: 90px;
    }
  }
}

.remarkContainer {
  margin-top: $spacing-2;
}

.noMaxWidth {
  max-width: unset;
}