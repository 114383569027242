// stylelint-disable 

.expiry_date { font-size: 14px; line-height: 21px; fill: #DDE5EE; }
.last4 { fill: #FFF; font-weight: 500; font-size: 18px; line-height: 27px; }
.name { fill: #FFF; font-weight: 500; font-size: 16px; line-height: 24px; }
.small_default_text { 
    color: #262626;
    font-size: 12px;
    line-height: 20px;
    fill: #262626;
}
.default_tag { opacity: 0.8027; }
.large_star_text { font-size: 20px; }
.large_name_text { font-size: 14px; }

.reapExpire {
    font-size: 14px;
    line-height: 20px;
    fill: #525252;
}

.reapLast4, .reapName {
    font-size: 20px;
    line-height: 28px;
    fill: #262626;
}
.reapName {
    font-size: 16px;
    font-weight: normal;
}
.amex0 { clip-path: url(#AMEX_SVGID_2_); }
.amex1 { fill: #66B596; }
.amex2 { opacity: 0.5; clip-path: url(#AMEX_SVGID_4_); }
.amex3 { clip-path: url(#AMEX_SVGID_6_); }
.amex4 { clip-path: url(#AMEX_SVGID_8_); }
.amex5 { opacity: 0.6; enable-background: new; }
.amex6 { clip-path: url(#AMEX_SVGID_10_); }
.amex7 { clip-path: url(#AMEX_SVGID_12_); }
.amex8 { clip-path: url(#AMEX_SVGID_14_); fill: #FFF; }
.amex9 { clip-path: url(#AMEX_SVGID_16_); }
.amex10 { clip-path: url(#AMEX_SVGID_18_); fill: #FFF; }
.amex11 { clip-path: url(#AMEX_SVGID_20_); }
.amex12 { clip-path: url(#AMEX_SVGID_22_); fill: #FFF; }
.amex13 { clip-path: url(#AMEX_SVGID_24_); }
.amex14 { clip-path: url(#AMEX_SVGID_26_); fill: #FFF; }
.amex15 { clip-path: url(#AMEX_SVGID_28_); }
.amex16 { clip-path: url(#AMEX_SVGID_30_); fill: #FFF; }
.amex17 { clip-path: url(#AMEX_SVGID_32_); }
.amex18 { clip-path: url(#AMEX_SVGID_34_); fill: #FFF; }
.amex19 { clip-path: url(#AMEX_SVGID_36_); }
.amex20 { clip-path: url(#AMEX_SVGID_38_); fill: #FFF; }
.amex21 { clip-path: url(#AMEX_SVGID_40_); }
.amex22 { clip-path: url(#AMEX_SVGID_42_); fill: #FFF; }
.amex23 { clip-path: url(#AMEX_SVGID_44_); }
.amex24 { clip-path: url(#AMEX_SVGID_46_); fill: #FFF; }
.amex25 { clip-path: url(#AMEX_SVGID_48_); }
.amex26 { clip-path: url(#AMEX_SVGID_50_); fill: #FFF; }
.amex27 { clip-path: url(#AMEX_SVGID_52_); }
.amex28 { clip-path: url(#AMEX_SVGID_54_); fill: #FFF; }
.amex29 { clip-path: url(#AMEX_SVGID_56_); }
.amex30 { clip-path: url(#AMEX_SVGID_58_); fill: #FFF; }
.amex31 { clip-path: url(#AMEX_SVGID_60_); }
.amex32 { clip-path: url(#AMEX_SVGID_62_); fill: #FFF; }
.amex33 { clip-path: url(#AMEX_SVGID_64_); }
.amex34 { clip-path: url(#AMEX_SVGID_66_); fill: #FFF; }
.amex35 { clip-path: url(#AMEX_SVGID_68_); }
.amex36 { clip-path: url(#AMEX_SVGID_70_); fill: #FFF; }
.amex37 { clip-path: url(#AMEX_SVGID_72_); }
.amex38 { opacity: 0.8; clip-path: url(#AMEX_SVGID_74_); fill: #565656; }
.amex39 { opacity: 0.8027; }
.amex40 { fill: #FFF; }
.amex41 { font-family: 'Avenir-Heavy'; }
.amex42 { font-size: 8px; }
.amex43 { font-size: 18px; }
.amex44 { opacity: 0.5; }
.amex45 { font-size: 12px; }
.amex46 { clip-path: url(#AMEX_SVGID_76_); }
.amex47 { opacity: 0.1968; fill: #FFF; }
.amex48 { font-family: 'Avenir-Medium'; }
.amex49 { font-size: 10px; }

.amex_large0 { display: none; }
.amex_large1 { display: inline; }
.amex_large2 { clip-path: url(#SVGID_2_); }
.amex_large3 { fill: #66B596; }
.amex_large4 { opacity: 0.5; clip-path: url(#SVGID_4_); }
.amex_large5 { clip-path: url(#SVGID_6_); }
.amex_large6 { clip-path: url(#SVGID_8_); }
.amex_large7 { opacity: 0.6; enable-background: new; }
.amex_large8 { clip-path: url(#SVGID_10_); }
.amex_large9 { clip-path: url(#SVGID_12_); }
.amex_large10 { clip-path: url(#SVGID_14_); fill: #FFF; }
.amex_large11 { clip-path: url(#SVGID_16_); }
.amex_large12 { clip-path: url(#SVGID_18_); fill: #FFF; }
.amex_large13 { clip-path: url(#SVGID_20_); }
.amex_large14 { clip-path: url(#SVGID_22_); fill: #FFF; }
.amex_large15 { clip-path: url(#SVGID_24_); }
.amex_large16 { clip-path: url(#SVGID_26_); fill: #FFF; }
.amex_large17 { clip-path: url(#SVGID_28_); }
.amex_large18 { clip-path: url(#SVGID_30_); fill: #FFF; }
.amex_large19 { clip-path: url(#SVGID_32_); }
.amex_large20 { clip-path: url(#SVGID_34_); fill: #FFF; }
.amex_large21 { clip-path: url(#SVGID_36_); }
.amex_large22 { clip-path: url(#SVGID_38_); fill: #FFF; }
.amex_large23 { clip-path: url(#SVGID_40_); }
.amex_large24 { clip-path: url(#SVGID_42_); fill: #FFF; }
.amex_large25 { clip-path: url(#SVGID_44_); }
.amex_large26 { clip-path: url(#SVGID_46_); fill: #FFF; }
.amex_large27 { clip-path: url(#SVGID_48_); }
.amex_large28 { clip-path: url(#SVGID_50_); fill: #FFF; }
.amex_large29 { clip-path: url(#SVGID_52_); }
.amex_large30 { clip-path: url(#SVGID_54_); fill: #FFF; }
.amex_large31 { clip-path: url(#SVGID_56_); }
.amex_large32 { clip-path: url(#SVGID_58_); fill: #FFF; }
.amex_large33 { clip-path: url(#SVGID_60_); }
.amex_large34 { clip-path: url(#SVGID_62_); fill: #FFF; }
.amex_large35 { clip-path: url(#SVGID_64_); }
.amex_large36 { clip-path: url(#SVGID_66_); fill: #FFF; }
.amex_large37 { clip-path: url(#SVGID_68_); }
.amex_large38 { clip-path: url(#SVGID_70_); fill: #FFF; }
.amex_large39 { clip-path: url(#SVGID_72_); }
.amex_large40 { opacity: 0.8; clip-path: url(#SVGID_74_); fill: #565656; }
.amex_large41 { opacity: 0.8027; }
.amex_large42 { fill: #FFF; }
.amex_large43 { font-family: 'Avenir-Heavy'; }
.amex_large44 { font-size: 8px; }
.amex_large45 { font-size: 18px; }
.amex_large46 { opacity: 0.5; }
.amex_large47 { font-size: 12px; }
.amex_large48 { clip-path: url(#SVGID_76_); }
.amex_large49 { opacity: 0.1968; fill: #FFF; }
.amex_large50 { font-family: 'Avenir-Medium'; }
.amex_large51 { font-size: 10px; }
.amex_large52 { clip-path: url(#SVGID_78_); }
.amex_large53 { opacity: 0.5; clip-path: url(#SVGID_80_); }
.amex_large54 { clip-path: url(#SVGID_82_); }
.amex_large55 { clip-path: url(#SVGID_84_); }
.amex_large56 { clip-path: url(#SVGID_86_); }
.amex_large57 { clip-path: url(#SVGID_88_); }
.amex_large58 { clip-path: url(#SVGID_90_); fill: #FFF; }
.amex_large59 { clip-path: url(#SVGID_92_); }
.amex_large60 { clip-path: url(#SVGID_94_); fill: #FFF; }
.amex_large61 { clip-path: url(#SVGID_96_); }
.amex_large62 { clip-path: url(#SVGID_98_); fill: #FFF; }
.amex_large63 { clip-path: url(#SVGID_100_); }
.amex_large64 { clip-path: url(#SVGID_102_); fill: #FFF; }
.amex_large65 { clip-path: url(#SVGID_104_); }
.amex_large66 { clip-path: url(#SVGID_106_); fill: #FFF; }
.amex_large67 { clip-path: url(#SVGID_108_); }
.amex_large68 { clip-path: url(#SVGID_110_); fill: #FFF; }
.amex_large69 { clip-path: url(#SVGID_112_); }
.amex_large70 { clip-path: url(#SVGID_114_); fill: #FFF; }
.amex_large71 { clip-path: url(#SVGID_116_); }
.amex_large72 { clip-path: url(#SVGID_118_); fill: #FFF; }
.amex_large73 { clip-path: url(#SVGID_120_); }
.amex_large74 { clip-path: url(#SVGID_122_); fill: #FFF; }
.amex_large75 { clip-path: url(#SVGID_124_); }
.amex_large76 { clip-path: url(#SVGID_126_); fill: #FFF; }
.amex_large77 { clip-path: url(#SVGID_128_); }
.amex_large78 { clip-path: url(#SVGID_130_); fill: #FFF; }
.amex_large79 { clip-path: url(#SVGID_132_); }
.amex_large80 { clip-path: url(#SVGID_134_); fill: #FFF; }
.amex_large81 { clip-path: url(#SVGID_136_); }
.amex_large82 { clip-path: url(#SVGID_138_); fill: #FFF; }
.amex_large83 { clip-path: url(#SVGID_140_); }
.amex_large84 { clip-path: url(#SVGID_142_); fill: #FFF; }
.amex_large85 { clip-path: url(#SVGID_144_); }
.amex_large86 { clip-path: url(#SVGID_146_); fill: #FFF; }
.amex_large87 { clip-path: url(#SVGID_148_); }
.amex_large88 { opacity: 0.5047; clip-path: url(#SVGID_150_); fill: #565656; }
.amex_large89 { opacity: 0.8022; }
.amex_large90 { font-size: 20px; }
.amex_large91 { clip-path: url(#SVGID_152_); }
.amex_large92 { opacity: 0.1968; clip-path: url(#SVGID_154_); fill: #FFF; }

.mc0 { fill: #231F20; }
.mc1 { opacity: 0.4037; }
.mc2 { opacity: 0.1494; fill: #F9F9F9; enable-background: new; }
.mc3 { opacity: 0.1; fill: #F9F9F9; enable-background: new; }
.mc4 { opacity: 0.5; fill: #F9F9F9; enable-background: new; }
.mc5 { opacity: 0.4; fill: #F9F9F9; enable-background: new; }
.mc6 { opacity: 5e-02; fill: #F9F9F9; enable-background: new; }
.mc7 { opacity: 1e-02; fill: #F9F9F9; fill-opacity: 0.75; enable-background: new; }
.mc8 { opacity: 0.3; fill: #F9F9F9; enable-background: new; }
.mc9 { fill: #F9F9F9; fill-opacity: 0.12; }
.mc10 { fill: #F9F9F9; fill-opacity: 0.75; }
.mc11 { opacity: 0.6; fill: #F9F9F9; enable-background: new; }
.mc12 { opacity: 0.504; fill: #F9F9F9; enable-background: new; }
.mc13 { opacity: 0.3982; fill: #F9F9F9; fill-opacity: 0.75; enable-background: new; }
.mc14 { opacity: 0.7; fill: #F9F9F9; enable-background: new; }
.mc15 { fill: #F9F9F9; }
.mc16 { opacity: 0.8; fill: #F9F9F9; enable-background: new; }
.mc17 { opacity: 1e-02; fill: #F9F9F9; enable-background: new; }
.mc18 { opacity: 0.6953; fill: #EB001B; enable-background: new; }
.mc19 { opacity: 0.6953; fill: #F79E1B; enable-background: new; }
.mc20 { opacity: 0.6953; fill: #FF5F00; enable-background: new; }
.mc21 { opacity: 0.8; fill: #565656; enable-background: new; }
.mc22 { opacity: 0.1968; fill: #FFF; enable-background: new; }
.mc23 { fill: #FFF; }
.mc24 { font-family: 'Avenir-Heavy'; }
.mc25 { font-size: 18px; }
.mc26 { font-size: 8px; }

.mc_large0 { fill: #231F20; }
.mc_large1 { opacity: 0.4037; }
.mc_large2 { opacity: 0.1494; fill: #F9F9F9; enable-background: new; }
.mc_large3 { opacity: 0.1; fill: #F9F9F9; enable-background: new; }
.mc_large4 { opacity: 0.5; fill: #F9F9F9; enable-background: new; }
.mc_large5 { opacity: 0.4; fill: #F9F9F9; enable-background: new; }
.mc_large6 { opacity: 5e-02; fill: #F9F9F9; enable-background: new; }
.mc_large7 { opacity: 1e-02; fill: #F9F9F9; fill-opacity: 0.75; enable-background: new; }
.mc_large8 { opacity: 0.3; fill: #F9F9F9; enable-background: new; }
.mc_large9 { fill: #F9F9F9; fill-opacity: 0.12; }
.mc_large10 { fill: #F9F9F9; fill-opacity: 0.75; }
.mc_large11 { opacity: 0.6; fill: #F9F9F9; enable-background: new; }
.mc_large12 { opacity: 0.504; fill: #F9F9F9; enable-background: new; }
.mc_large13 { opacity: 0.3982; fill: #F9F9F9; fill-opacity: 0.75; enable-background: new; }
.mc_large14 { opacity: 0.7; fill: #F9F9F9; enable-background: new; }
.mc_large15 { fill: #F9F9F9; }
.mc_large16 { opacity: 0.8; fill: #F9F9F9; enable-background: new; }
.mc_large17 { opacity: 1e-02; fill: #F9F9F9; enable-background: new; }
.mc_large18 { clip-path: url(#MC_LARGE_SVGID_2_); }
.mc_large19 { opacity: 0.6953; clip-path: url(#MC_LARGE_SVGID_4_); fill: #EB001B; }
.mc_large20 { clip-path: url(#MC_LARGE_SVGID_6_); }
.mc_large21 { opacity: 0.6953; clip-path: url(#MC_LARGE_SVGID_8_); fill: #F79E1B; }
.mc_large22 { clip-path: url(#MC_LARGE_SVGID_10_); }
.mc_large23 { opacity: 0.6953; clip-path: url(#MC_LARGE_SVGID_12_); fill: #FF5F00; }
.mc_large24 { clip-path: url(#MC_LARGE_SVGID_14_); }
.mc_large25 { opacity: 0.5047; clip-path: url(#MC_LARGE_SVGID_16_); fill: #565656; }
.mc_large26 { opacity: 0.8022; }
.mc_large27 { fill: #FFF; }
.mc_large28 { font-family: 'Avenir-Heavy'; }
.mc_large29 { font-size: 10px; }
.mc_large30 { font-size: 20px; }
.mc_large31 { clip-path: url(#MC_LARGE_SVGID_18_); }
.mc_large32 { opacity: 0.1968; clip-path: url(#MC_LARGE_SVGID_20_); fill: #FFF; }

.visa0 { clip-path: url(#VISA_SVGID_2_); }
.visa1 { fill: #1A1F71; }
.visa2 { clip-path: url(#VISA_SVGID_4_); }
.visa3 { fill: #F7B600; }
.visa4 { clip-path: url(#VISA_SVGID_6_); }
.visa5 { opacity: 0.7498; fill: #2583FD; }
.visa6 { opacity: 0.4; enable-background: new; }
.visa7 { clip-path: url(#VISA_SVGID_8_); }
.visa8 { clip-path: url(#VISA_SVGID_10_); }
.visa9 { clip-path: url(#VISA_SVGID_12_); fill: #FFF; }
.visa10 { clip-path: url(#VISA_SVGID_14_); }
.visa11 { clip-path: url(#VISA_SVGID_16_); fill: #FFF; }
.visa12 { clip-path: url(#VISA_SVGID_18_); }
.visa13 { clip-path: url(#VISA_SVGID_20_); fill: #FFF; }
.visa14 { clip-path: url(#VISA_SVGID_22_); }
.visa15 { clip-path: url(#VISA_SVGID_24_); fill: #FFF; }
.visa16 { clip-path: url(#VISA_SVGID_26_); }
.visa17 { opacity: 0.5; clip-path: url(#VISA_SVGID_28_); fill: #FFF; }
.visa18 { clip-path: url(#VISA_SVGID_30_); }
.visa19 { opacity: 0.8; clip-path: url(#VISA_SVGID_32_); fill: #565656; }
.visa20 { clip-path: url(#VISA_SVGID_34_); }
.visa21 { opacity: 0.1968; clip-path: url(#VISA_SVGID_36_); fill: #FFF; }
.visa22 { fill: #FFF; }
.visa23 { font-family: 'Avenir-Heavy'; }
.visa24 { font-size: 18px; }
.visa25 { opacity: 0.8027; }
.visa26 { font-size: 8px; }

.visa_large0 { clip-path: url(#VISA_LARGE_SVGID_2_); }
.visa_large1 { clip-path: url(#VISA_LARGE_SVGID_4_); fill: #1A1F71; }
.visa_large2 { clip-path: url(#VISA_LARGE_SVGID_6_); }
.visa_large3 { clip-path: url(#VISA_LARGE_SVGID_8_); fill: #F7B600; }
.visa_large4 { clip-path: url(#VISA_LARGE_SVGID_10_); }
.visa_large5 { opacity: 0.7498; clip-path: url(#VISA_LARGE_SVGID_12_); fill: #2583FD; }
.visa_large6 { clip-path: url(#VISA_LARGE_SVGID_14_); }
.visa_large7 { opacity: 0.5047; clip-path: url(#VISA_LARGE_SVGID_16_); fill: #565656; }
.visa_large8 { opacity: 0.8022; }
.visa_large9 { fill: #FFF; }
.visa_large10 { font-family: 'Avenir-Heavy'; }
.visa_large11 { font-size: 10px; }
.visa_large12 { font-size: 20px; }
.visa_large13 { clip-path: url(#VISA_LARGE_SVGID_18_); }
.visa_large14 { opacity: 0.1968; clip-path: url(#VISA_LARGE_SVGID_20_); fill: #FFF; }
.visa_large15 { opacity: 0.4; enable-background: new; }
.visa_large16 { clip-path: url(#VISA_LARGE_SVGID_22_); }
.visa_large17 { clip-path: url(#VISA_LARGE_SVGID_24_); }
.visa_large18 { clip-path: url(#VISA_LARGE_SVGID_26_); fill: #FFF; }
.visa_large19 { clip-path: url(#VISA_LARGE_SVGID_28_); }
.visa_large20 { clip-path: url(#VISA_LARGE_SVGID_30_); fill: #FFF; }
.visa_large21 { clip-path: url(#VISA_LARGE_SVGID_32_); }
.visa_large22 { clip-path: url(#VISA_LARGE_SVGID_34_); fill: #FFF; }
.visa_large23 { clip-path: url(#VISA_LARGE_SVGID_36_); }
.visa_large24 { clip-path: url(#VISA_LARGE_SVGID_38_); fill: #FFF; }
.visa_large25 { clip-path: url(#VISA_LARGE_SVGID_40_); }
.visa_large26 { opacity: 0.5; clip-path: url(#VISA_LARGE_SVGID_42_); fill: #FFF; }


.card_unionpay {
    tspan {
        color: #262626;
        fill: #262626;
    }
    .expiry_date {
        fill: #525252;
        font-weight: normal;
    }
}
.card_mastercard {
    .default_tag_bg {
        fill: rgba(0, 0, 0, 0.25);
    }
    .default_tag, .small_default_text {
        fill: white;
    }
}

.card_visa {
    .default_tag_bg {
        fill: rgba(0, 0, 0, 0.25);
    }
    .default_tag, .small_default_text {
        fill: white;
    }
}