@import '~styles/_theme';

.detailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  border-radius: 4px;
  padding: $spacing-0;
  margin-top: $spacing-6;

  .sectionTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacing-4;

    .sectionTitle {
      color: $light-violet-1000;
      margin: 0;

      @include fontStyles($text-base);
    }
  }

  .details {
    flex: 1;
    flex-direction: column;
    display: flex;
    color: $nb-grey-900;

    @include fontStyles($text-sm);
  }
}
