@import '~styles/_theme';

.container {
  height: calc(1.5rem + 2px);
  border: $nb-border-light-grey-1px;
  border-radius: $rounded-sm;
  background-color: $nb-white;
  padding: $spacing-0 0.1875rem !important;
  max-width: $spacing-8;
  min-width: $spacing-8;

  .cardBrandLogo {
    height: 100%;
    width: 100%;
  }

  &.lg {
    max-width: $spacing-10;
    min-width: $spacing-10;
  }
}
