@import '~styles/_theme';

button.selected {
  background: $light-violet-100 !important;
  border-color: $light-violet-600 !important;

  svg {
    color: $light-violet-700 !important;
  }
}
