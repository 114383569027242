@import '~styles/_theme';

.container {
  display: inline-block;
  color: $nb-grey-800 !important;
  border: $border-1 solid $nb-warm-grey-300 !important;
  background: $nb-warm-grey-100 !important;
  width: fit-content;
  border-radius: $rounded-base;
  padding: 0 $spacing-1;
  margin-top: $spacing-1;

  > div {
    display: inline-block;
    position: relative;
  }
}

.button {
  padding: 0;
  min-height: 0;
  height: $spacing-5;
  border-radius: 0;

  :global(.bp3-button-text) {
    font-family: 'Menlo', monospace !important;

    @include setPrint($nb-text-sm, $nb-grey-900, $nb-font-regular);
  }

  > svg {
    color: $nb-svg-grey;
  }

  &:hover {
    background-color: $nb-warm-grey-200 !important; //override Button tertiary
  }

  &.focus,
  &.focus:hover {
    // background-color: $nb-button-primary-bg !important; //override Button tertiary
    :global(.bp3-button-text) {
      color: $nb-grey-900;
    }

    > svg {
      color: $nb-svg-grey;
    }
  }
}

.tooltip {
  cursor: pointer;
  background-color: $nb-grey-800;
  display: none;
  align-items: center;
  padding: $spacing-1 $spacing-3;
  height: $spacing-8;
  position: absolute;
  top: -$spacing-10;
  left: 50%;
  transform: translateX(-50%);
  border-radius: $rounded-sm;
  white-space: nowrap;

  @include setPrint($text-sm, $white);

  // stylelint-disable-next-line no-descending-specificity
  > svg {
    margin-right: $spacing-1;
  }
}

.tooltip.open {
  display: inline-flex;
}
