.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.qr {
  display: flex;
  align-self: center;
  margin-bottom: 10px;
}
