@import '~styles/_theme';

.container {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: $spacing-6;

    @include tablet {
      justify-content: space-between;
    }

    @include mobile {
      padding-left: $spacing-4;
    }

    .checkbox {
      margin: 0;
    }

    .exportButton {
      margin-left: $spacing-8;
      color: $nb-grey-800;

      svg {
        color: $nb-svg-grey;
      }
    }

    .exportButton:disabled {
      opacity: 0.5;
    }

    .exportButton :global(.bp3-button-text) {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: $spacing-2;
      }
    }
  }

  .emptyContainer {
    .item {
      filter: blur(6px);
    }
  }

  .content {
    margin-top: $spacing-1;

    .item + .item {
      margin-top: $spacing-2;
    }
  }

  .paginate {
    margin-top: $spacing-6;

    .pageDetails {
      @include mediumDevice {
        display: none;
      }

      @include mobile {
        display: block;
      }
    }
  }
}
