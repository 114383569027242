@import '~styles/_theme';

.highlightLink {
  text-align: center;
  color: $nb-grey-700;

  a {
    color: $nb-red-700;
    font-weight: $font-medium;

    &:not(:hover) {
      text-decoration: none;
    }
  }
}

.formContainer {
  max-width: $spacing-90;
  margin: auto;

  @include mobile {
    padding: 0 $spacing-2;
  }

  &:last-child {
    text-align: center;
  }
}

.formFields {
  margin-bottom: $spacing-6;

  :global(label.bp3-label) {
    align-self: flex-start;
  }

  :global(.small-container) a {
    color: $nb-grey-800;
  }
}

.formActions {
  .submitButton {
    display: block;
    text-align: center;
  }
}

.termAndPolicy {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: $spacing-6;
  color: $nb-grey-600;

  // stylelint-disable-next-line no-descending-specificity
  a {
    color: $nb-grey-600;
  }

  &:global(.bp3-form-group):last-child {
    margin-top: $spacing-6;
    margin-bottom: 0;
  }
}

.inactiveAlert {
  text-align: left;
}
