@import "~styles/_theme";

.peekIcon {
  margin: $spacing-0 !important;
  padding: $spacing-0 !important;

  &:hover {
    box-shadow: none !important;
    background: $white !important;
  }

  svg {
    color: $nb-svg-grey;
  }
}

// styling the right element on the input field
.inputGroup input + span:first-of-type {
  display: flex;
  height: 100%;
  align-items: center;
  padding-right: $spacing-4;
}

.inputGroup input {
  padding-right: $spacing-10;
}

.strengthContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: $spacing-3;

  .progressBarContainer {
    width: 100%;

    .danger,
    .warning,
    .success {
      background-color: $nb-grey-300;
    }

    .danger > div {
      background-color: $nb-red-600;
    }

    .warning > div {
      background-color: $nb-yellow-700;
    }

    .success > div {
      background-color: $nb-green-700;
    }
  }

  .strengthIndicator {
    min-width: fit-content;

    @include setPrint($nb-text-xs, $nb-grey-700);

    margin: $spacing-0 $spacing-3;
  }
}

.requirementCardContainer {
  background: $nb-warm-grey-200;
  border: none;
  box-sizing: border-box;
  box-shadow: $shadow-sm;
  border-radius: $rounded-base;
  padding: $spacing-3 $spacing-4;
  margin-top: $spacing-3;

  .requirementItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .requirementItemDesc {
      @include setPrint($nb-text-xs, $nb-grey-700);

      &.passedRequirement {
        text-decoration-line: line-through;
      }

      strong {
        font-weight: $font-semibold;
        color: $nb-grey-800;
      }
    }

    .requirementStatusIcon {
      color: $nb-svg-grey;
      margin-right: $spacing-2;

      &.passedRequirement {
        color: $nb-svg-grey;
      }
    }
  }
}
