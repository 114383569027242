@import '~styles/_theme';

.popoverContainer {
  width: $spacing-48;
}

.selectedOption {
  @include fontStyles($text-sm);

  color: $nb-grey-600;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & img {
    width: $spacing-5;
    height: $spacing-5;
  }
}

.languageOption {
  @include fontStyles($nb-text-sm);

  color: $nb-grey-800;
}

.chevronDown {
  display: flex;
  color: $nb-grey-600;
  margin-left: 4px;
}

@include tablet {
  .chevronDown {
    display: none;
  }
}

.wrapper {
  display: flex;
}
