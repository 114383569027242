@import '~styles/_theme';

.content {
  padding: $spacing-0 $spacing-10;
  margin-top: $spacing-2;
  text-align: center;

  @include mobile {
    padding: $spacing-0 $spacing-6;
  }

  .context {
    text-align: left;
  }

  .qrcode {
    margin: $spacing-8;
  }

  .reapCollectLinkBtnContainer {
    margin: $spacing-0 $spacing-4;
  }

  .shareLink {
    margin-top: $spacing-6;

    @include setPrint($nb-text-sm, $nb-dark-violet, $nb-font-semibold);
  }

  .button {
    margin: $spacing-2 $spacing-0;
  }
}
