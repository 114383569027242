@import '~styles/_theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .phoneInputContainer {
    max-width: $spacing-96;
    width: 100%;

    > label {
      font-size: $nb-text-sm !important;
      color: $nb-grey-800 !important;
      font-weight: $nb-font-medium !important;
    }

    > div > div {
      width: 100%;
    }

    .phoneInput {
      width: 100%;
    }
  }

  .codeInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    // stylelint-disable-next-line no-descending-specificity
    > div {
      margin: $spacing-0;
    }

    > div > div > div {
      margin: $spacing-0;
    }

    .resendGroup {
      display: flex;
      align-items: center;
      margin-top: $spacing-6;

      .resendBtn {
        padding: $spacing-0;
        margin-left: $spacing-1;

        span {
          color: $nb-rose-700;
        }
      }
    }
  }
}

.container.inModal {
  width: 100%;

  .phoneInputContainer {
    max-width: 100%;
  }
}

@include mobile {
  .container,
  .container.inModal {
    width: 100%;
  }
}
