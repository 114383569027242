@import '~styles/_theme';

.block {
  width: 100%;
  background: $white;
  border: $nb-border-light-grey-1px;
  border-radius: $rounded-lg;
  box-shadow: $shadow-base;
  margin: 0 0 $spacing-2;
  padding: $spacing-3 $spacing-5;

  svg {
    stroke: $nb-svg-grey;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: $nb-red-100;
    border: $border-1 solid $nb-red-400;
    box-shadow: $shadow-base;
  }

  > span {
    display: flex;
    width: 100%;

    @include setPrint($text-sm, $nb-grey-700);

    cursor: pointer;

    .top {
      @include setPrint($nb-text-xs, $nb-red-700, $nb-font-semibold);
    }

    .bottom {
      @include setPrint($nb-text-sm, $nb-grey-700);
    }

    .bottom strong {
      @include setPrint($nb-text-sm, $nb-grey-900, $nb-font-semibold);
    }

    .left {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .right {
      flex-shrink: 0;
      margin-left: 15px;
      text-align: end;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  &:disabled {
    background: rgba($nb-red-700, 0.05);
    opacity: 0.3;

    > span {
      cursor: not-allowed;
    }
  }

  @media (max-width: 575px) {
    font-size: $text-sm;
    line-height: 19px;
  }
}
