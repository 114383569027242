@import '~styles/_theme';

.allocGroup {
  display: flex;

  .allocButton {
    padding: 0;
    height: $spacing-11;
    flex: 1;
    border-radius: 0;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    &:first-child {
      border-top-left-radius: $rounded-base;
      border-bottom-left-radius: $rounded-base;
    }

    &:last-of-type {
      border-top-right-radius: $rounded-base;
      border-bottom-right-radius: $rounded-base;
    }
  }
}

.feeAlloc {
  margin-top: $spacing-6;
}
