@import '~styles/_theme';

.popover {
  opacity: 1;
}

.popoverContent {
  background: $nb-white;
  padding: $spacing-4;
  box-shadow: $shadow-lg;
  border-radius: $rounded-base;
  width: 224px;
  margin-top: $spacing-1;

  .headerSection {
    display: flex;
    justify-content: space-between;

    .title {
      @include setPrint($nb-text-sm, $nb-grey-900);
    }

    button {
      padding: $spacing-0;
      min-height: auto;
      height: auto;

      span {
        @include setPrint($nb-text-sm, $nb-grey-600);
      }
    }
  }

  .filterSection {
    margin-top: $spacing-4;

    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .searchInput {
      margin-bottom: $spacing-3;
      padding: $spacing-px-2;
      margin-left: -$spacing-px-2;
      margin-right: -$spacing-px-2;
      margin-top: -$spacing-1;

      input {
        height: 38px;
        border: none;
      }

      :global(.bp3-input-left-container) svg {
        margin-top: $spacing-px-10;
        margin-left: $spacing-3;
        margin-right: $spacing-2;
        color: $nb-svg-grey;
      }
    }
  }

  .checkboxList {
    position: relative;
    max-height: 180px;
    overflow: auto;

    .loading {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.7);

      :global(.bp3-spinner-head) {
        stroke: $nb-rose-600;
      }
    }
  }
}
