@import '~styles/_theme';

.icon {
  margin-left: $spacing-5;

  @include tablet {
    margin-left: $spacing-4;
  }
}

.userSettingContainer {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.userInfoContainer {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatarContainer {
    margin-left: $spacing-0;
    margin-top: $spacing-1;
  }

  .userDetailsContainer {
    display: flex;
    flex-direction: column;
    margin-left: $spacing-4;

    .userName {
      color: $nb-grey-900;
    }

    .userEmail {
      @include fontStyles($text-xs);

      color: $nb-grey-600;
    }
  }
}

.divider {
  padding-bottom: $spacing-0 !important;
  padding-top: $spacing-0 !important;
  margin: $spacing-1 $spacing-0;
  border-color: $nb-grey-300;
}

.subTitle {
  @include setPrint($nb-text-xs, $nb-grey-600, $nb-font-medium);
}
