// new brand mixins

@mixin nbButtonStyle($background, $textColor) {
  background: $background;
  padding: 15px 0;

  &:active,
  &:hover {
    background-color: rgba($background, 0.9);
  }

  &:disabled {
    background-color: rgba($background, 0.5);
  }

  span {
    font-size: 17.5px;
    line-height: 24px;
    font-weight: bold;
    color: $textColor;
  }

  @media (max-width: 575px) {
    span {
      font-size: 20px;
      line-height: 27px;
    }
  }
}

@mixin nbWireButtonStyle($background, $borderColor, $textColor) {
  background: $background;
  border: 1.5px solid $borderColor;
  padding: 15px 0;

  &:active,
  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
  }

  span {
    font-size: 17.5px;
    line-height: 24px;
    font-weight: bold;
    color: $textColor;
  }

  span > svg {
    color: $textColor;
  }

  @media (max-width: 575px) {
    span {
      font-size: 20px;
      line-height: 27px;
    }
  }
}

@mixin nbSelectedBorderStyle($bg-color: $nb-white, $border-width: $border-2) {
  // used when it's gradient
  // border: $border-width solid transparent;
  // background: linear-gradient($bg-color, $bg-color) padding-box, $nb-accent-primary border-box; /* white background */
  border: $border-width solid $nb-rose-600;
  background: $bg-color;
}

@mixin gradientBorder($gradient: $nb-accent-primary, $border-width: $border-1) {
  border: $border-width solid transparent;
  background: linear-gradient($nb-white, $nb-white) padding-box, $gradient border-box;
}

// Typography hierarchy
@mixin nb-h1 {
  font-family: $nb-orbikular-font;
  font-weight: $nb-font-bold;
  font-size: $nb-text-4xl;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: $nb-dark-violet;

  @include tablet {
    font-size: $nb-text-3xl;
  }
}

@mixin nb-h2 {
  font-family: $nb-orbikular-font;
  font-weight: $nb-font-bold;
  font-size: $nb-text-3xl;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: $nb-dark-violet;

  @include tablet {
    font-size: $nb-text-2xl;
    line-height: 32px;
  }
}

@mixin nb-h3($text-color: $nb-grey-900) {
  font-weight: $nb-font-medium;
  font-size: $nb-text-lg;
  line-height: 28px;
  color: $nb-grey-900;
}

@mixin nb-h4($text-color: $nb-grey-900) {
  font-weight: $nb-font-bold;
  font-size: $nb-text-base;
  line-height: 24px;
  color: $text-color;
  margin: $spacing-0;
}

@mixin nb-h5($text-color: $nb-grey-700) {
  font-weight: $nb-font-medium;
  font-size: $nb-text-sm;
  line-height: 20px;
  color: $text-color;
}

@mixin nb-h6 {
  font-weight: $nb-font-medium;
  font-size: $nb-text-xs;
  line-height: 20px;
  color: $nb-grey-600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin nb-feature-paragraph($text-color: $nb-grey-900) {
  font-size: $nb-text-base;
  font-weight: $nb-font-regular;
  line-height: 24px;
  color: $text-color;
}

@mixin nb-paragraph($text-color: $nb-grey-700) {
  font-size: $nb-text-sm;
  font-weight: $nb-font-regular;
  line-height: 20px;
  color: $text-color;
}

@mixin nb-dialog-title($text-color: $nb-grey-900) {
  @include setPrint($nb-text-xl, $text-color);
}
