// stylelint-disable no-descending-specificity
@import "~react-intl-tel-input/dist/main.css";
@import "~styles/_theme";

.iti-mobile .intl-tel-input.iti-container {
  height: 100vh;
  width: 100vw;
  background-color: rgba(black, 0.3);
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;

  @include mobile {
    position: fixed !important;
  }

  ul.country-list {
    margin-top: 50vh;
    height: 50vh;
    width: 100%;
    max-height: 50vh;
  }
}

.iti-mobile .intl-tel-input .country-list {
  max-height: 200px;
  overflow-x: auto;
}

.intl-tel-input {
  width: 100%;
  padding-left: 0;
  border: none;

  .country-list {
    .country {
      .country-name {
        color: $nb-grey-900;
      }

      .dial-code {
        color: $nb-grey-600;
      }
    }

    .divider {
      border-bottom: $nb-border-grey-1px;
    }
  }

  :global .bp3-input:disabled,
  .bp3-input.bp3-disabled {
    background-color: $nb-grey-100;
  }

  .selected-flag {
    background-color: $nb-grey-100;
    border-top-left-radius: $rounded-sm;
    border-bottom-left-radius: $rounded-sm;
    border-right: $nb-border-light-grey-1px;

    &:focus {
      outline: none;
      box-shadow: $nb-shadow-outline;
    }
  }
}

body {
  .intl-tel-input.allow-dropdown input,
  .intl-tel-input.allow-dropdown input[type=tel],
  .intl-tel-input.allow-dropdown input[type=text] {
    padding-left: $spacing-15;
  }
}
