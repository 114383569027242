@import '~styles/_theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .instruction {
    @include setPrint($nb-text-base, $nb-grey-700, $nb-font-regular);

    max-width: $spacing-96;
    text-align: center;
  }

  .resendGroup {
    display: flex;
    align-items: center;
  }

  .lockContainer {
    background: $nb-light-blue-200;
    border-radius: $rounded-full;
    height: $spacing-16;
    width: $spacing-16;
    margin-top: $spacing-6;
    display: flex;
    justify-content: center;
    align-items: center;

    &.lockOpen {
      background: $nb-green-200;
    }

    &.lockError {
      @include shakeX;

      background: $nb-red-200;
    }

    svg {
      height: $spacing-6;
      width: $spacing-6;

      &.lockError path {
        stroke: $nb-red-700;
      }
    }
  }

}

@include mobile {
  .container {
    .instruction {
      @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);
    }
  }
}
