// stylelint-disable no-descending-specificity
@import '~@blueprintjs/core/src/common/_colors.scss';
@import '~styles/_theme';

$pt-intent-primary: $nb-button-primary;
// "intent": (default, hover, active colors)
$button-intents: (
  'primary': (
    $pt-intent-primary,
    $nb-button-primary-hovered,
    $pt-intent-primary,
  ),
  'success': (
    $pt-intent-success,
    $green2,
    $green1,
  ),
  'warning': (
    $pt-intent-warning,
    $orange2,
    $orange1,
  ),
  'danger': (
    $pt-intent-danger,
    $red2,
    $red1,
  ),
);

@import '~@blueprintjs/core/src/blueprint.scss';

.bp3-control .bp3-control-indicator {
  &,
  &::before {
    width: $nb-spacing-4;
    height: $nb-spacing-4;
  }

  background-color: $nb-white;
  border: $nb-border-grey-1px;
  box-sizing: border-box;
  box-shadow: $shadow-sm;
  border-radius: $rounded-sm;
}

body .bp3-control:hover .bp3-control-indicator {
  background-color: $nb-white;
}

.bp3-control.bp3-checkbox input:checked {
  ~ .bp3-control-indicator {
    border: none;
  }

  ~ .bp3-control-indicator::before {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 3L4.5 8.5L2 6' stroke='white' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.bp3-control.bp3-checkbox {
  input:indeterminate ~ .bp3-control-indicator {
    background-color: $nb-bp-primary;
    box-shadow: none;
    border: none;

    &::before {
      position: absolute;
      top: 7px;
      height: 2px;
      width: calc(100% - 6px);
      background: $nb-white;
      left: 3px;
      right: 3px;
    }
  }

  &:hover input:indeterminate ~ .bp3-control-indicator {
    background-color: $nb-bp-primary;
    box-shadow: none;
  }
}

.bp3-control input:focus ~ .bp3-control-indicator {
  outline: 0;
}

.bp3-control input:disabled ~ .bp3-control-indicator {
  background: $nb-grey-300;
}

.bp3-control input:checked,
.bp3-control:hover input:checked,
.bp3-control input:not(:disabled):active:checked,
.bp3-control.bp3-checkbox input:not(:disabled):active:indeterminate {
  ~ .bp3-control-indicator {
    background-color: $nb-bp-primary !important;
    box-shadow: $shadow-sm;
    border-color: $nb-bp-primary;
  }
}

.bp3-control input:disabled:indeterminate {
  ~ .bp3-control-indicator {
    background-color: rgba($nb-bp-primary, 0.5) !important;
    box-shadow: $shadow-sm;
    border-color: rgba($nb-bp-primary, 0.5);
  }
}

.bp3-label {
  color: $nb-grey-600;

  .bp3-text-muted {
    color: $nb-grey-400;
  }
}

.bp3-button.bp3-button {
  background-image: none;

  &:focus {
    outline: none;
  }
}

.bp3-button .bp3-button-text span {
  vertical-align: middle;
}

.bp3-button:not([class*='bp3-intent-']):disabled,
.bp3-button:not([class*='bp3-intent-']).bp3-disabled {
  background-color: $nb-grey-200;
}

.bp3-button:not([class*='bp3-intent-']):active,
.bp3-button:not([class*='bp3-intent-']).bp3-active {
  background: $nb-button-primary-hovered;
}

@media (max-width: 575px) {
  .floating {
    .title.title {
      padding: 14px 20px 13px 0;
      position: fixed;
      z-index: 3;
      top: 0;
      right: 0;
      left: 56px;
      height: 55px;
      transition: bottom 0.5s;

      .bp3-button.bp3-large,
      .bp3-large .bp3-button {
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 28px;
      }

      h1.bp3-heading {
        opacity: 0.4;
        color: $white;
        font-size: $text-base;
        line-height: 19px;
        letter-spacing: 0.0666667px;
      }
    }

    .hideFromTitle {
      display: none;
    }

    .content {
      .noShrink {
        margin-top: 80px;
      }
    }

    &.withTopBanner .title.title {
      margin-top: 40px;
    }
  }
}

.bp3-input:disabled,
.bp3-input.bp3-disabled {
  background: $nb-grey-200;
  color: $nb-grey-700;
  opacity: 1;
}

pre {
  font-family: Monaco, Consolas, SF Mono, monospace;
}

// overriding input field
.bp3-input {
  @include fontStyles($text-base);

  border: $nb-border-grey-1px;
  box-sizing: border-box;
  border-radius: $rounded-base;
  height: 44px;
  padding-left: $spacing-4;
  color: $nb-grey-900;
  caret-color: $nb-grey-900;
  font-weight: $nb-font-regular;

  &[readonly] {
    box-shadow: $shadow-none;
  }

  &::placeholder {
    color: $nb-grey-500;
    font-weight: $nb-font-regular;
  }
}

label.bp3-control {
  color: $nb-grey-900;
}

.bp3-form-group {
  margin-bottom: $spacing-3;

  @media (max-width: 575px) {
    margin-bottom: $spacing-2;
  }

  &:last-child {
    margin-bottom: 0;
  }

  label.bp3-label.bp3-label {
    @include fontStyles($text-sm);

    color: $nb-grey-800;
    font-weight: $font-medium;
    margin-bottom: 0.5rem;

    .bp3-popover-wrapper {
      display: inline-block;
    }
  }

  .bp3-input {
    box-shadow: none !important;
    border-radius: 6px;
  }

  .input-warning,
  .bp3-form-helper-text {
    margin-top: 0.5rem;
    color: $nb-red-700;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }
  }

  .bp3-label {
    .bp3-popover-wrapper {
      margin-left: 4px;
      margin-top: 0;

      .bp3-button {
        min-width: 20px;
        min-height: 20px;
      }
    }
  }
}

.bp3-input:focus,
.bp3-input.bp3-active {
  border-color: transparent !important;
  box-shadow: $nb-shadow-outline !important;
  outline: 0;
}

.bp3-input-group.bp3-intent-danger {
  .bp3-input {
    border-color: $nb-red-600;
    outline: 0;
    box-shadow: $shadow-none;
  }

  span:last-child {
    border-left-color: $nb-red-600;
  }
}

.bp3-progress-bar {
  height: 4px;
  background-color: $white;
}

@media (max-width: 575px) {
  .bp3-input {
    height: 44px;
  }

}

* {
  touch-action: manipulation;
  -webkit-text-size-adjust: none;
}

body {
  /* stylelint-disable-next-line property-no-unknown */
  –webkit-overflow-scrolling: auto;

  .bp3-skeleton {
    background: $nb-warm-grey-300;
    animation: loading-gradient-animation 1.2s ease alternate infinite;
    border-radius: $rounded-sm;
    border-color: transparent !important;
  }
}

.bp3-dialog-container {
  transform: none !important;
}

.intl-tel-input {
  height: 40px;
  border: $nb-border-light-grey-1px;
  color: $nb-grey-600;
  box-shadow: none !important;
  border-radius: 4px;
  padding-left: 0;

  input.bp3-input.bp3-large {
    height: 38px;
    border: 0;
    top: 0;
    width: 100%;
    border-radius: 4px;

    &:focus {
      outline: none;
    }
  }
}

.questionMarkCircle {
  border: $border-1 solid $nb-red-600;

  &.sizeXS {
    border-radius: 6px;
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    padding: 0;
    margin: 0;
    font-size: $text-xs !important;
  }
}

.feePopover {
  display: inline-block !important;
  margin-left: 5px;
}

.insidePopover {
  background: $white !important;
  max-width: 278px;
}

.bp3-button {
  &.bp3-intent-clean {
    background: $nb-grey-200;
    color: $nb-grey-600;
  }

  &.bp3-intent-button-link {
    color: inherit;
    text-decoration: underline;
    margin: 0;
    padding: 5px;
    background: none;
  }

  &.bp3-intent-button-link-highlight {
    background: none;
  }

  &.bp3-intent-simple {
    cursor: pointer;
    border: 1px solid $nb-light-blue-700;
    color: $nb-light-blue-700;
    height: 42px;
    padding: 12px 26px;
    border-radius: 4px;
    background: white;

    span {
      font-size: 13px;
      font-weight: 600;
    }

    @media (max-width: 575px) {
      padding: 12px 22px;
    }

    &.invert {
      background-color: $nb-light-blue-700;
      color: white;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  &.bp3-intent-primary {
    box-shadow: none;

    &:not(.bp3-outlined):not(.bp3-minimal) {
      background: $nb-button-primary;

      &:disabled {
        background: $nb-button-primary-disabled;
      }

      &:not(:disabled):hover {
        background: $nb-button-primary-hovered;
      }
    }

    &:hover {
      box-shadow: none;
    }

    &.bp3-outlined {
      border-color: $nb-grey-400 !important;
      color: $nb-grey-800 !important;

      svg {
        color: $nb-grey-600;
      }

      &:disabled {
        opacity: 0.5;
      }

      &:hover {
        background: $nb-grey-100;
      }
    }
  }

  &.bp3-intent-cta-purple {
    background: $nb-purple-500;
    padding: 15px 80px;
    color: white;

    span {
      font-weight: 600;
      font-size: 17.5px;
      line-height: 24px;
    }

    &:disabled {
      opacity: 0.6;
    }
  }

  &.bp3-intent-clean-purple {
    color: $nb-purple-500;
    font-weight: bold;
    background: none;

    &.bp3-disabled {
      opacity: 0.7;
    }
  }

  &.bp3-intent-clean-blue {
    color: $nb-light-blue-500;
    font-weight: bold;
    background: none;

    &.bp3-disabled {
      opacity: 0.7;
    }
  }

  &.bp3-intent-clean-blue-border {
    color: $nb-light-blue-500;
    font-weight: bold;
    background: none;
    border: $border-1 solid $nb-light-blue-500;

    &.bp3-disabled {
      opacity: 0.7;
    }
  }

  &.bp3-intent-clean-gray {
    color: $nb-grey-300;
    background: none;

    &.bp3-disabled {
      opacity: 0.7;
    }
  }

  &.bp3-minimal {
    &:hover {
      background: $nb-grey-100;
    }
  }

  &.bp3-intent-input-action {
    background-color: transparent;
    min-width: 31px;
    min-height: 36px;
  }

  &:not([class*='bp3-intent-']):hover {
    background-color: $nb-grey-100;
  }
}

.dateFullWidth .bp3-popover-target {
  width: 100%;
}

.bp3-button.bp3-loading .bp3-button-spinner {
  left: 0;
  right: 0;

  svg {
    margin-right: $spacing-0 !important;
  }
}

.bp3-button .bp3-icon {
  color: $nb-grey-600;
}

.bp3-button.bp3-minimal.bp3-intent-primary:active,
.bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
  background: $nb-grey-200;
  color: $nb-grey-600;
}

.bp3-slider-handle {
  background: $nb-red-600;

  &:focus {
    outline: none;
  }
}

.bp3-slider-progress {
  background-color: $nb-red-300;
}

.bp3-slider-progress.bp3-intent-primary {
  background: $nb-accent-primary;
}

.containerLarge {
  max-width: 1200px;
  display: flex;
  flex: 1;
}

.bp3-tab {
  color: $nb-grey-700;
}

.alignCenter {
  text-align: center;
  clear: both;
  justify-content: center;
}

.stepperField .bp3-input {
  text-align: center;
  border-radius: 0 !important;
}

.bp3-tab-indicator-wrapper .bp3-tab-indicator {
  background-color: $nb-red-600;
}

*:focus {
  outline: none;
}

.bp3-tab[aria-selected='true'],
.bp3-tab:not([aria-disabled='true']):hover {
  color: $nb-red-700;
}

.bp3-dialog {
  background: $nb-warm-grey-200;
}

.bp3-heading {
  @include nb-dialog-title;
}

.html-select.html-select {
  width: 100%;
  outline: none;
  background-color: $white;

  select {
    border: $nb-border-light-grey-1px;
    height: 44px;
    padding: 0 16px;
    border-radius: 0.375rem !important;
    box-shadow: $shadow-base;
    background-color: transparent;

    /* Firefox hack on select element */
    &:-moz-focusring {
      color: transparent;
      text-shadow: $shadow-black;
    }

    &:focus {
      background-color: $white;
      outline: none;
      box-shadow: $nb-shadow-outline !important;
      border: 1px solid transparent;
    }

    &:hover {
      &:not([disabled]) {
        background-color: $white;
        outline: none;
        box-shadow: $nb-shadow-outline !important;
        border: 1px solid transparent;
      }
    }

    &[disabled] {
      box-shadow: $shadow-base !important;
      background-color: $nb-grey-100 !important;
      opacity: 1;
    }
  }

  &::after {
    background: url('../assets/images/chevron-down.svg') no-repeat center;
    content: ' ';
    width: $spacing-6;
    height: $spacing-6;
    top: $spacing-px-10;
  }
}
// FormGroup tooltip
@media (max-width: 575px) {
  .formGroupWithTooltip {
    .bp3-transition-container.bp3-popover-enter-done {
      left: calc(50% - 172.5px) !important;
    }
  }
}

// Calendar
.arrivalDate {
  .bp3-input-group {
    .bp3-disabled {
      background: $nb-grey-100;
    }

    input.bp3-input {
      box-shadow: $shadow-base !important;
      color: $nb-grey-900;
      padding-right: 52px;
    }

    .bp3-input-action {
      svg {
        stroke: $nb-svg-grey;
      }
    }
  }
}

.bp3-datepicker.bp3-datepicker {
  @include setPrint($text-sm, $nb-grey-800, $font-normal);

  .DayPicker-Weekdays,
  .DayPicker-Weekday {
    font-weight: $font-normal;
  }

  .bp3-datepicker-caption select {
    color: $nb-grey-800;
    font-family: $font-semibold;
  }

  .DayPicker-Caption .bp3-divider {
    display: none;
  }

  .bp3-datepicker-navbar,
  .DayPicker-Caption {
    .bp3-icon > svg {
      opacity: 0;
    }
  }

  .bp3-datepicker-navbar {
    .bp3-button.bp3-minimal {
      background-color: transparent !important;
    }

    .bp3-icon-chevron-left {
      background: url('../assets/images/nb-chevron-left.svg') no-repeat center;
    }

    .bp3-icon-chevron-right {
      background: url('../assets/images/nb-chevron-right.svg') no-repeat center;
    }
  }

  .bp3-icon-double-caret-vertical {
    margin-right: -5px;
    background: url('../assets/images/nb-chevron-down.svg') no-repeat center;
  }

  .DayPicker-Day {
    border-radius: 0 !important;
    width: 32px;
    height: 32px;
    background-color: transparent !important;
    color: $nb-grey-800;

    &:focus {
      outline: none;
    }

    .bp3-datepicker-day-wrapper {
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 14px !important;
      margin-left: auto;
      margin-right: auto;
    }

    &:hover .bp3-datepicker-day-wrapper {
      background-color: $nb-grey-200;
    }
  }

  .DayPicker-Day.DayPicker-Day--selected {
    font-weight: $font-semibold;

    .bp3-datepicker-day-wrapper {
      background: $nb-red-600;
      box-shadow: $shadow-base;
      color: $white;
    }
  }

  .DayPicker-Day--faster .bp3-datepicker-day-wrapper {
    background-color: $nb-grey-700 !important;
    font-weight: $font-semibold;
    color: $white;
  }

  .DayPicker-Day.DayPicker-Day--selected:hover {
    .bp3-datepicker-day-wrapper {
      background: $nb-red-800;
    }
  }

  .DayPicker-Day.DayPicker-Day--disabled {
    color: $nb-grey-600;
  }

  @media (max-width: 575px) {
    .DayPicker-Weekday {
      width: 42px;
      height: 40px;
    }

    .DayPicker-Day .bp3-datepicker-day-wrapper {
      width: 36px;
      height: 36px;
      border-radius: 18px !important;
    }
  }
}

// Support mobile shortcut
@media (max-width: 575px) {
  .useShortcut .bp3-drawer.bp3-overlay-content {
    border-radius: 14px 14px 0 0;
    box-shadow: $shadow-2xl;

    .bp3-drawer-header {
      padding: $spacing-3;
      padding-left: 44px;
      border-bottom: $nb-border-light-grey-1px;
      box-shadow: none;

      h4 {
        @include setPrint($text-base, $nb-grey-900, $font-semibold);

        text-align: center;
      }

      .bp3-dialog-close-button {
        width: 32px;
        height: 32px;
        background: $nb-grey-200;
        border-radius: 16px;
      }
    }

    .bp3-drawer-body {
      padding-top: $spacing-6;

      .bp3-datepicker {
        justify-content: center;
        padding-bottom: $spacing-4;

        select {
          font-size: 15px;
          background: transparent;
        }
      }
    }
  }

  .useShortcut .bp3-dialog-container {
    align-items: flex-end;

    .bp3-dialog {
      border-radius: 14px 14px 0 0;
      padding: 24px 48px;

      > button {
        width: $spacing-12;
        height: $spacing-12;
        border-radius: $spacing-6;
        background-color: $nb-grey-100;
      }

      .bp3-drawer-header {
        h4 {
          text-align: center;
        }
      }
    }
  }
}

body .bp3-radio {
  box-shadow: none;
}

// from old promotions page
// TODO: remove it when all tooltip is scoped (PaymentFlow/FormGroup)
.bp3-popover-content {
  background: white !important;
  color: $nb-grey-600;
  padding: 20px 30px;
}

.bp3-tooltip .bp3-popover-arrow-border {
  fill: white;
}

.bp3-tooltip .bp3-popover-arrow-fill {
  fill: white;
}

.lowercase {
  text-transform: lowercase;
}

.customAlert {
  background-color: $white;

  .bp3-alert-contents {
    flex: 1;
  }

  .bp3-alert-footer {
    display: none;
  }
}

button.clearStyle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.cf::before,
.cf::after {
  content: ' '; /* 1 */
  display: table; /* 2 */
}

.cf::after {
  clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
  *zoom: 1;
}

@keyframes loading-gradient-animation {
  0% {
    background: $nb-warm-grey-300;
  }

  100% {
    background: $nb-warm-grey-200;
  }
}

.bp3-transition-container {
  z-index: 9999;
}
