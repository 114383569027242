@import '~styles/_theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .heroContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $nb-warm-grey-200;
    height: $nb-spacing-60;
    width: 100%;
    max-width: $nb-spacing-160;
  }

  .header {
    @include nb-h1;
  }

  .cardContainer {
    padding: 0;
    width: 100%;
    max-width: $nb-spacing-96;
    box-shadow: none;

    a {
      text-decoration: underline;
    }
  }

  .loginBtn {
    text-align: center;
  }

  .activationForm {
    display: flex;
    justify-content: center;
  }
}

@include mobile {
  .container {
    .heroContainer {
      display: none;
    }

    .header {
      @include nb-h2;
    }
  }
}
