@import '~styles/_theme';

.dateInput {
  position: relative;

  span {
    display: block;
  }

  :global(.bp3-input-group) :global(.bp3-input-action) {
    height: 100%;
    right: $spacing-4;
  }
}

.offset {
  > input {
    padding-left: $spacing-36;
    border: $border-1;
  }
}

.maskButton {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
}

.rightIcons {
  left: 50%;
  display: flex;
  align-items: center !important;
  height: 100%;

  svg {
    stroke-width: 1.5;
    color: $nb-grey-600 !important;
  }

  > .successIcon {
    stroke: $nb-green-700 !important;
    margin-right: $spacing-4;
  }
}

.dateInputPopover:global(.bp3-popover) {
  :global(.bp3-popover-content) {
    padding: $spacing-4;
  }

  :global(.bp3-datepicker) {
    outline: none;
  }

  :global(.DayPicker-wrapper) {
    outline: none;
  }

  &.menu {
    :global(.bp3-popover-content) {
      padding: 0;
    }

    :global(.bp3-menu) {
      padding: $spacing-1 0;
    }
  }
}

.menuItem {
  padding: $spacing-2 $spacing-4;
  border-radius: 0;
  min-width: $spacing-64;

  @include setPrint($nb-text-sm, $nb-grey-800);

  @include mobile {
    font-size: $text-base;
  }

  &:global(.bp3-menu-item.bp3-intent-primary.bp3-active) {
    background-color: $nb-red-600;
  }
}

.dateDrawer:global(.bp3-portal) {
  @include mobile {
    :global(.bp3-menu) {
      padding: $spacing-1 0;
    }

    :global(.bp3-drawer.bp3-overlay-content .bp3-drawer-header) {
      border: none;
    }

    :global(.bp3-drawer.bp3-overlay-content .bp3-drawer-body) {
      padding-top: 0;
    }
  }
}

.prefixLabel {
  position: absolute;
  z-index: 3;
  padding: 0 11px;
  left: 0;
  width: $spacing-32;
  line-height: 42px;
  top: 0;
  bottom: 0;
  text-align: left;
  transition: all 0.3s;
  white-space: nowrap;
  margin: $spacing-px;
  background-color: $nb-grey-100;
  color: $nb-grey-700;
  border: none;
  box-shadow: $nb-inner-1px-right-gray-400;
  border-bottom-left-radius: $rounded-lg;
  border-top-left-radius: $rounded-lg;
}
