@import '~styles/_theme';

.container {
  .symbol {
    @include setPrint($text-lg, $nb-grey-900, $nb-font-medium);
  }

  .amount {
    @include setPrint($text-lg, $nb-grey-900, $nb-font-medium);

    margin-left: $spacing-1;
    word-break: break-all;
  }

  .code {
    @include setPrint($text-base, $nb-grey-900, $nb-font-regular);
  }

  &.strikeThrough {
    .symbol {
      text-decoration: line-through !important;
    }

    .amount {
      text-decoration: line-through !important;
    }

    .code {
      text-decoration: line-through !important;
    }
  }

  &.lg {
    .symbol {
      font-size: $text-lg;
    }

    .amount {
      font-size: $text-lg;
    }

    .code {
      font-size: $text-base;
    }
  }

  &.sm {
    .symbol {
      font-size: $text-sm;

      @include mobile {
        font-size: $text-base;
      }
    }

    .amount {
      font-size: $text-sm;

      @include mobile {
        font-size: $text-sm;
        margin-left: 3px;
      }
    }

    .code {
      font-size: $text-sm;

      @include mobile {
        margin-left: 3px;
      }
    }
  }

  &.base {
    .amount {
      font-size: $text-base;
    }

    .symbol {
      font-size: $text-xs;
    }

    .code {
      font-size: $text-xs;
    }
  }

  &.xl {
    .symbol {
      font-size: $text-2xl;

      @include mobile {
        font-size: $text-lg;
      }
    }

    .code {
      font-size: $text-2xl;

      @include mobile {
        font-size: $text-lg;
      }
    }

    .amount {
      font-size: $text-2xl;
      font-weight: bold;

      @include mobile {
        font-size: $text-lg;
      }
    }
  }

  &.xs {
    .symbol {
      @include setPrint($text-xs, $nb-grey-600);
    }

    .amount {
      @include setPrint($text-xs, $nb-grey-600);
    }

    .code {
      @include setPrint($text-xs, $nb-grey-600);
    }
  }

  &.discounted {
    .amount {
      text-decoration-line: line-through;
    }
  }

  &.sm.light {
    .symbol {
      @include setPrint($text-sm, $nb-grey-600);
    }

    .amount {
      margin-left: 0;

      @include setPrint($text-sm, $nb-grey-600);
    }

    .code {
      margin-left: 3px;

      @include setPrint($text-sm, $nb-grey-600);
    }
  }

  &.sm.medium {
    .symbol {
      @include setPrint($text-sm, $nb-grey-900);
    }

    .amount {
      margin-left: 3px;

      @include setPrint($text-sm, $nb-grey-900);
    }

    .code {
      margin-left: 3px;

      @include setPrint($text-sm, $nb-grey-900);
    }
  }

  &.base.medium {
    .symbol {
      @include setPrint($text-base, $nb-grey-900);
    }

    .amount {
      margin-left: 3px;

      @include setPrint($text-base, $nb-grey-900);
    }

    .code {
      margin-left: 3px;

      @include setPrint($text-base, $nb-grey-900);
    }
  }

  &.sm.bold {
    .symbol {
      @include setPrint($text-sm, $nb-grey-1000, $font-semibold);
    }

    .amount {
      margin-left: 3px;

      @include setPrint($text-sm, $nb-grey-1000, $font-semibold);
    }

    .code {
      margin-left: 3px;

      @include setPrint($text-sm, $nb-grey-1000, $font-semibold);
    }
  }

  &.base.bold {
    .symbol {
      @include setPrint($text-base, $nb-grey-900, $font-semibold);
    }

    .amount {
      margin-left: 3px;

      @include setPrint($text-base, $nb-grey-900, $font-semibold);
    }

    .code {
      margin-left: 3px;

      @include setPrint($text-base, $nb-grey-900, $font-semibold);
    }
  }

  &.xl.bold {
    .symbol {
      @include setPrint($text-xl, $nb-grey-1000, $font-semibold);
    }

    .amount {
      @include setPrint($text-xl, $nb-grey-1000, $font-semibold);
    }

    .code {
      @include setPrint($text-xl, $nb-grey-1000, $font-semibold);
    }
  }
}
