@import '~styles/_theme';

.panel {
  width: $spacing-80 !important;

  .count {
    color: $nb-red-700;
    margin-left: $spacing-1;
  }
}
