@import '~styles/_theme';

.popoverContainer {
  @include fontStyles($text-sm);

  box-shadow: $shadow-md;
  width: $spacing-64;

  > div,
  ul,
  & {
    border-radius: 6px !important;
  }

  ul {
    padding: $spacing-2 $spacing-px-6;
  }

  li {
    color: $nb-grey-800;
    @include setPrint($text-sm, $nb-grey-800, $nb-font-regular);

    &:not(.divider) {
      padding: $spacing-2 $spacing-px-10;
    }

    a {
      &:hover {
        background: none;
      }

      padding: $spacing-0;
      align-items: center;
    }

    &:hover,
    &:active {
      background-color: $nb-warm-grey-200;
      border-radius: $rounded-base;
    }

    svg {
      margin-right: $spacing-4;
    }
  }

  li:global(.bp3-menu-divider) {
    padding: 0;
  }

  li:last-of-type {
    margin-bottom: $spacing-0;
  }

  :global(.bp3-popover-content) {
    box-shadow: $nb-shadow-xs;
    padding: $spacing-0;
  }
}

.portalContainer {
  > div {
    top: $spacing-2 !important;
  }
}
