@import '~styles/_theme';

.container {
  margin: 15px 30px;
  margin-top: $spacing-6;
  position: relative;
  display: flex;
  flex-direction: column;
  
  .bp3-progress-bar.bp3-intent-primary .bp3-progress-meter {
    background: $nb-accent-primary !important;
  }

  @media (max-width: 575px) {
    margin-bottom: 12px;
  }
  
  .progressBarContainer {
    position: absolute;
    top: 6px;
    width: 100%;
    
    
    .progressBar {
      background: $nb-grey-300;
      height: 2px;
      
      > div {
        background-color: $nb-red-500;
      }
    }
  }

  .progressTextContainer {
    justify-content: space-between;
    display: flex;
    list-style-type: none;
    margin-bottom: 10px;

    .progressTextItemWrapper {
      position: relative;
      display: flex;
      align-items: center;

      &.clickable {
        &:hover {
          cursor: pointer;
        }
      }

      &.empty {
        .progressText {
          color: $nb-grey-700;
        }
      }
    }

    .statusDot {
      display: flex;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $nb-white;
      border: 4px solid $nb-red-600;
      align-items: center;
      justify-content: center;

      svg {
        color: $white;
      }

      &::after,
      &::before {
        content: '';
        border-right: $spacing-1 solid $nb-warm-grey-200;
        height: 14px;
      }

      &::after {
        margin-left: 7px;
      }

      &::before {
        margin-right: 7px;
      }

      &.empty {
        width: 10px;
        height: 10px;
        background-color: $nb-grey-400;
        border: $spacing-1 solid $nb-grey-400;

        &::after {
          margin-left: 5px;
        }

        &::before {
          margin-right: 5px;
        }

        @media (max-width: 575px) {
          background-color: $white;
        }
      }

      &.completed {
        border: $border-0;
        background-color: $nb-red-600;
        position: relative;

        svg {
          position: absolute;
          stroke-width: 2.5px;
        }
      }

      @media (max-width: 575px) {
        &::after,
        &::before {
          border-color: $white;
        }
      }
    }

    // stylelint-disable-next-line no-descending-specificity
    .progressText {
      position: absolute;
      top: $spacing-3;
      left: 50%;
      transform: translate(-50%, 50%);

      @include fontStyles($text-sm);

      color: $nb-red-800;
      font-weight: 400;
      font-size: $text-sm;

      @media (max-width: 575px) {
        @include fontStyles($text-xs);

        top: $spacing-2;
      }

      width: max-content;
    }
  }
}
