@import '~styles/_theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .optionsGroup {
    display: flex;
    flex-direction: row;

    .optionBtn {
      background-color: $white;
      border: $nb-border-grey-1px;
      height: $spacing-29;
      width: $spacing-46;
      padding: $spacing-3 $spacing-0;
      box-shadow: $shadow-none;

      &:not(:last-child) {
        margin-right: $spacing-3;
      }

      &.isActive {
        background-color: $nb-button-primary-bg;
        border: $nb-border-primary-1px;
        border-radius: $rounded-base;
      }

      .option {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        @include mobile {
          text-align: left;
        }

        img {
          height: $spacing-10;
          width: $spacing-10;
          margin-bottom: $spacing-2;
        }

        .smsIconContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          height: $spacing-10;
          width: $spacing-10;
          border-radius: $rounded-full;
          background-color: $nb-red-200;
          margin-bottom: $spacing-2;

          img {
            height: $spacing-6;
            width: $spacing-6;
            margin: $spacing-0;
          }
        }

        .whatsAppIconContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          height: $spacing-10;
          width: $spacing-10;
          border-radius: $rounded-full;
          background-color: #25d366;
          margin-bottom: $spacing-2;

          img {
            height: $spacing-6;
            width: $spacing-6;
            margin: $spacing-0;
          }
        }

        .optionDescContainer {
          display: flex;
          flex-direction: column;

          .optionTitle {
            @include setPrint($nb-text-sm, $nb-grey-900, $nb-font-medium);
          }

          .optionSubTitle {
            @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);
          }
        }
      }
    }
  }

  .logout {
    padding: $spacing-4 $spacing-0;
    text-align: center;
  }
}

.container.inModal {
  width: 100%;

  .optionsGroup {
    display: flex;
    width: 100%;

    .optionBtn {
      width: 33%;
    }
  }
}

@include mobile {
  .container,
  .container.inModal {
    width: 100%;

    .optionsGroup {
      display: flex;
      width: 100%;
      flex-direction: column;

      .optionBtn {
        width: 100%;
        height: $spacing-16;
        padding: $spacing-3 $spacing-4;

        > span {
          width: 100%;
        }

        &:not(:last-child) {
          margin-bottom: $spacing-2;
        }

        .option {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          img {
            margin-right: $spacing-4;
            margin-bottom: $spacing-0;
          }

          .smsIconContainer {
            margin-right: $spacing-4;
            margin-bottom: $spacing-0;

            img {
              margin-right: $spacing-0;
            }
          }

          .whatsAppIconContainer {
            margin-right: $spacing-4;
            margin-bottom: $spacing-0;

            img {
              margin-right: $spacing-0;
            }
          }

          .optionDescContainer {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}
