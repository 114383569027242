@import '~styles/_theme';

.container {
  display: flex;
  flex-direction: row;
  padding: $spacing-6;
  box-shadow: $shadow-md;
  border-radius: $rounded-lg;
  width: 100%;
  height: max-content;

  &.withoutCard {
    box-shadow: $shadow-none;
    padding: $spacing-0;
  }

  .businessInfo {
    margin-left: $spacing-6;
    overflow: hidden;

    .businessName {
      @include setPrint($text-base, $nb-grey-900, $font-semibold);
      @include textOverflow();
    }

    .detailsContainer {
      @include setPrint($text-sm, $nb-grey-600);

      display: flex;
      flex-direction: row;
      margin-top: $spacing-2;
      align-items: center;

      .icon {
        color: $nb-svg-grey;
        margin-right: $spacing-2;
        min-width: $spacing-4;
        min-height: $spacing-4;
      }

      .detail {
        overflow-wrap: break-word;
        overflow: hidden;
      }

      &.highLight {
        .icon {
          color: $nb-red-600;
        }

        .detail {
          color: $nb-red-600;
        }
      }
    }

    .italics {
      font-style: italic;
    }
  }
}
