/* stylelint-disable no-descending-specificity */
@import '~styles/_theme';

.backdrop {
  background-color: $nb-backdrop-color;
}

.panelContainer {
  width: $spacing-120;
  box-shadow: $shadow-xl;
  overflow: visible;

  .panelContentContainer {
    overflow: auto;
  }

  button.disabled {
    opacity: 0.75 !important;
    cursor: not-allowed;
  }

  &:global(.version-2) {
    .contentContainer {
      .titleContainer {
        padding: $spacing-5 $spacing-8;

        .innerTitle {
          flex: 1;
          width: 100%;
          justify-content: center;
          align-items: center;

          .title {
            flex: 1;
          }

          .rightActions {
            width: $spacing-8;
          }
        }
      }

      .sectionsContainer {
        .contentSection {
          padding: $spacing-5 $spacing-8;
        }
      }
    }

    .buttonsContainer {
      flex-direction: row;
      align-items: center;
      padding: $spacing-4 $spacing-6;

      button {
        margin: 0 $spacing-3 0 0 !important;
        width: auto !important;
      }
    }
  }

  &:global(.bp3-drawer) {
    width: $spacing-120;
  }

  .contentContainer {
    .titleContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: $spacing-4 $spacing-6;
      border-bottom: $border-1 solid $nb-grey-200;
      width: 100%;

      .innerTitle {
        display: flex;
        width: 100%;
      }

      .title {
        @include setPrint($nb-text-xl, $nb-grey-900, $nb-font-medium);

        line-height: $spacing-8;
      }

      .rightActions {
        display: flex;
        align-items: center;
        margin-left: auto;

        :global(.bp3-popover-wrapper) {
          height: $nb-spacing-6;
        }
      }
    }

    .sectionsContainer {
      .contentSection {
        padding: $spacing-3 $spacing-6;
        border-bottom: $nb-border-light-grey-1px;
        
        &:first-of-type {
          padding-top: $spacing-4;
        }
        
        &:last-of-type {
          border-bottom: $border-0;
        }
      }
      .noSectionDivider {
        border-bottom: none !important;
      }
    }
  }

  .buttonsContainer {
    padding: $spacing-4 $spacing-6;
    display: flex;
    flex-direction: column;
    border-top: $nb-border-light-grey-1px;
    margin-top: auto;

    button:not(:last-child) {
      margin-bottom: $spacing-4;
    }

    @include mobile {
      display: grid;
    }
  }

  .buttonsContainer.horizontal {
    flex-direction: row;

    > :last-child {
      margin-top: 0;
      margin-left: $spacing-3;
    }

    @include mobile {
      > :last-child {
        margin-top: $spacing-3;
        margin-left: unset;
      }
    }
  }

  .buttonsContainer.horizontalFullWidth {
    display: flex;
    flex-direction: row;

    button {
      flex: 1;
    }

    > :last-child {
      margin-top: 0;
      margin-left: $spacing-3;
    }
  }

  .buttonsContainer.stickToBottom {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    align-self: flex-end !important;
    background-color: white;
  }
}

@include mobile {
  .panelContainer {
    width: 85%;
    overflow: scroll;

    &:global(.bp3-drawer) {
      width: 80%;
    }

    .contentContainer {
      .titleContainer {
        padding: $spacing-4;
      }

      .sectionsContainer {
        .contentSection {
          border-bottom: $nb-border-light-grey-1px;
          padding: $spacing-8 $spacing-6;
        }
      }
    }
  }
}

.tooltip {
  max-width: 312px;
  border-radius: $rounded-lg !important;
  box-shadow: $nb-shadow-tooltip !important;

  :global(.bp3-popover-content) {
    cursor: pointer;
    padding: $spacing-4;
    align-items: center;
    border-radius: $rounded-lg !important;

    @include setPrint($text-sm, $nb-grey-700, $nb-font-regular);

    p:first-child {
      @include setPrint($text-sm, $nb-grey-600, $nb-font-regular);

      text-transform: uppercase;
    }
  }
}

.lhsElement {
  display: block;
  position: fixed;
  background: $nb-warm-grey-1000;
  top: 0;
  left: 0;
  height: 100vh;
}
