@import '~styles/_theme';

.container {
  padding: $spacing-5 $spacing-6;
  display: flex;
  align-items: center;
  box-shadow: $shadow-base;
  cursor: pointer;
  border-radius: $rounded-lg;
  border: $border-2 solid transparent;

  &.dim {
    background-color: $nb-grey-100;
  }

  &.dashed {
    box-shadow: none;
    border: $border-1 dashed $nb-warm-grey-300;
  }

  &.selected,
  &.dashed.selected,
  &.highlighted {
    box-shadow: $shadow-base;

    @include nbSelectedBorderStyle();
  }

  &.highlighted {
    border: $border-1 solid $nb-red-500;
  }

  @include mobile {
    padding: $spacing-3 $spacing-4;
    align-items: flex-start;
  }

  .content {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: $spacing-2;
    min-width: 0;

    @include mobile {
      flex-direction: column;
    }
  }

  .iconContainer {
    width: $spacing-10;
    height: $spacing-10;
    border-radius: $rounded-full;
    background-color: $nb-warm-grey-200;
    color: $nb-grey-600;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $shadow-sm;
    flex-shrink: 0;
  }

  .checkbox {
    margin: 0;
  }

  .main {
    display: flex;
    align-items: center;
    flex: 1.5;
    max-width: 50%;
    position: relative;
    min-width: 0;

    @include mediumDevice {
      max-width: 70%;
    }

    @include mobile {
      max-width: 100%;
      min-width: 100%;
    }

    .info {
      display: flex;
      align-items: center;
      flex: 1;
      max-width: 100%;

      @include mobile {
        flex-direction: row-reverse;
      }

      .infoContent {
        flex: 1;
        min-width: 0;

        > div {
          width: 100%;
          max-width: $spacing-96;
          padding-right: $spacing-4;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
        }

        @include mediumDevice {
          > div {
            padding-right: $spacing-4;
          }
        }

        @include tablet {
          > div {
            flex-direction: column;
            align-items: flex-start;
            padding-right: $spacing-4;
          }
        }

        .userDetails {
          margin-left: $spacing-4;
          max-width: 60%;

          @include mobile {
            margin: 0;
            max-width: 100%;
            width: 100%;
          }

          .name {
            @include setPrint($text-sm, $nb-grey-900, $nb-font-regular);
            @include textOverflow;

            max-width: 100%;
            display: inline-block;
          }

          .email {
            color: $nb-grey-600;
            font-weight: $nb-font-regular;
            line-height: 1.2;

            @include textOverflow;

            @include tablet {
              display: none;
            }
          }
        }

        .amount {
          text-align: right;
          word-break: break-word;

          @include tablet {
            margin-left: $spacing-4;
            max-width: 100%;
            width: 100%;
            text-align: left;
          }

          @include mobile {
            margin-left: 0;
          }
        }
      }
    }
  }

  .payDate {
    @include setPrint($text-sm, $nb-grey-900, $nb-font-regular);
  }

  .paymentDetail {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mediumDevice {
      flex: 0;
      display: none;
    }

    .invoiceNumber {
      @include setPrint($text-sm, $nb-grey-600, $nb-font-regular);
    }
  }

  .secondary {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet {
      width: 100%;
      margin-top: $spacing-1;
    }

    @include smallDesktop {
      white-space: pre;
    }

    .amount {
      display: flex;

      @include tablet {
        max-width: 100%;
        width: 100%;
        text-align: left;
      }
    }

    .actionButton {
      margin: 0 $spacing-1 * 1.5;
      padding: 0 $spacing-3;

      &:global(.bp3-active) {
        background: $nb-warm-grey-200 !important;
      }
    }
  }
}

.status {
  min-width: $spacing-24;
  height: $spacing-6;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mediumDevice {
    min-width: $spacing-20;
    height: $spacing-5;
  }
}

.itemMenu {
  margin-left: $spacing-6;
}

.billDetail {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;

  @include mobile {
    align-self: flex-start;
  }
}

.sublabel {
  color: $nb-grey-600;
}
