@import '~styles/_theme';

.paymentDialogTitle {
  margin-top: 13px;
  font-size: 26px;
}

.threeDSecureValidatingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-60px);

  .loadingDesc {
    padding-top: 20px;
  }
}

.xBorderCallout {
  background: rgba($nb-purple-500, 0.08);
  color: $nb-purple-500;
  font-size: 12px;
  line-height: 14px;
  padding-left: 32px !important;

  span > svg {
    width: 12px;
    height: 12px;
    color: $nb-purple-500;
  }
}

.containerHeader {
  padding: 16px 20px;
  box-shadow: $nb-inner-1px-bottom-gray-300;
  justify-content: space-between;
  display: flex;

  h2 {
    color: $nb-grey-1000;
    font-weight: $nb-font-semibold;
    margin: 0 0 11px;
    padding: 0;
    line-height: 32px;
    font-size: 28px;
  }

  @media (max-width: 575px) {
    .containerHeader {
      h2 {
        margin: 0 0 10px;
      }
    }
  }
}

.container {
  .empty {
    height: calc(100vh - 200px);
    padding: 80px 40px;
    background: $white;
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      max-width: 240px;
      display: block;
      margin: 0 auto;
    }

    figure {
      margin-bottom: 30px;
    }

    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $nb-grey-600;
      margin-bottom: 15px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: $nb-grey-400;
      margin-bottom: 40px;
    }

    .btn {
      text-align: center;
      padding: 12px 34px;
      background: $nb-button-primary;
      box-shadow: none;
      font-weight: 600;
      font-size: 14.5px;
      line-height: 20px;
      color: $white;
      border-radius: 4px;

      &:hover {
        background: $nb-button-primary;
        box-shadow: none;
      }
    }
  }
}

:global {
  .amount_with_tag {
    color: $nb-grey-600;
    font-weight: $nb-font-semibold;

    .value {
      color: $nb-grey-900;
    }
  }
}

.paymentPage {
  .filterTagBar {
    margin-bottom: $spacing-2;
  }

  .titleContainer {
    .titleFloating {
      background-color: white;
    }

    .titleRight {
      display: flex;
    }
  }
}
