@import '~styles/_theme';

$btn-height: 48px;

.container {
  position: relative;

  span {
    display: block;
  }

  :global(.bp3-input-group) :global(.bp3-input-action) {
    height: 100%;
    right: $spacing-4;
  }
}

.drawer > :global(.bp3-overlay > .bp3-drawer) {
  box-shadow: $shadow-xl;
  border-top-left-radius: $rounded-2xl;
  border-top-right-radius: $rounded-2xl;
  padding: $spacing-6 $spacing-5;
  height: 60%;
}

.dialog :global(.bp3-overlay > .bp3-dialog-container > .bp3-dialog) {
  box-shadow: $shadow-xl;
  background: $white;
  padding: $spacing-4;
}

.backdrop {
  background: $nb-backdrop-color;
}

.atom {
  display: flex;
  justify-content: center;
  margin-bottom: $spacing-2;
  margin-right: $spacing-4;

  svg {
    color: $nb-red-700;
    background: $nb-red-100;
    border-radius: $rounded-full;
    padding: $spacing-2;
    overflow: visible;
    stroke-width: 1.5;
  }
}

.header {
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-bottom: $spacing-2;

  @include mobile {
    text-align: center;
    margin-left: 0;
    margin-bottom: $spacing-4;
  }

  @include setPrint($text-xl, $nb-grey-1000, $font-semibold);

  p {
    @include setPrint($nb-text-xl, $nb-grey-1000, $nb-font-regular);

    text-align: left;
  }
}

.headerBlock {
  display: flex;
  justify-content: space-evenly;

  @include mobile {
    display: unset;
  }
}

.passwordField > div > div:first-child {
  margin-bottom: $spacing-2;
}

.loggedInTxt {
  margin-bottom: $spacing-6;

  @include setPrint($text-sm, $nb-grey-700);

  em {
    font-style: normal;
    margin-left: $spacing-1;

    @include setPrint($nb-text-sm, $nb-red-700, $nb-font-regular);
  }
}

.passwordContent {
  padding-left: 56px;

  .descriptions {
    margin-bottom: $spacing-6;

    p {
      font-weight: $nb-font-medium;
    }

    span {
      font-weight: $nb-font-regular;
    }
  }

  .passwordField {
    margin-bottom: $spacing-4;
  }
}

.closeBtn {
  margin-bottom: $spacing-1;

  @include mobile {
    top: 0;
    left: 90% !important;
    position: relative !important;
    margin-top: -$spacing-8;
  }

  button {
    box-shadow: none !important;
    padding: 0;
    background-color: $white !important;

    @include mobile {
      background-color: $nb-grey-100 !important;
    }

    color: $nb-grey-600 !important;
    border-radius: $rounded-full;
    width: $spacing-8;
    height: $spacing-8;
  }
}

.btns {
  display: flex;
  justify-content: right;
  flex-direction: row-reverse;

  @include mobile {
    display: unset;
  }

  button {
    @include mobile {
      width: 100%;
      padding: $spacing-4 0;
    }

    & + button {
      margin-right: $spacing-3;

      @include mobile {
        margin-top: $spacing-3;
        margin-right: 0;
      }
    }
  }
}

.confirmModal,
.cardPasswordConfirm {
  .headerBlock {
    justify-content: flex-start;
    position: relative;

    .header {
      @include setPrint($text-xl, $nb-grey-900, $nb-font-medium);

      padding-right: $spacing-8;

      @include mobile {
        padding-right: 0;
      }
    }

    .closeBtn {
      position: absolute;
      top: 0;
      right: 0;

      @include mobile {
        position: unset;
      }
    }
  }

  .content {
    // padding-left: $spacing-14;
    margin-bottom: $spacing-8;
  }

  .btns {
    button {
      height: $spacing-11;

    }
  }
}

@include mobile {
  .header,
  .atom {
    padding-right: 0;
    margin-right: 0;
    text-align: center;
  }

  .content {
    padding-left: 0;
    text-align: center;
  }

  .passwordContent {
    text-align: center;
    padding: 0 $spacing-px-2;
  }

  .headerBlock {
    position: static !important;
  }

  .confirmDialog {
    max-width: $spacing-86;
    width: 100%;
    padding: $spacing-4;

    .atom,
    .header {
      margin-bottom: $spacing-1;
    }

    .content {
      padding: 0;
      margin-bottom: $spacing-6;
    }

    .btns {
      button {
        height: $spacing-12;
      }
    }
  }

  .drawer {
    :global {
      .bp3-drawer.bp3-position-bottom {
        border-radius: 14px 14px 0 0;
      }
    }

    .header p {
      text-align: center;
    }

    .closeBtn.closeBtn {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      border: 1px solid $nb-grey-400;
      border-radius: $rounded-full;
      top: $spacing-3;
      right: $spacing-3;

      button {
        display: flex;
        justify-content: center;
        align-items: center;

        > span {
          height: $spacing-5;
          display: block;
        }
      }
    }

    .content {
      flex-direction: column;
      padding: $spacing-6 $spacing-5;
      text-align: center;

      .title {
        position: static;
        padding-right: 0;
      }
    }

    .passwordField {
      text-align: left;
    }

    .buttonContainer.buttonContainer {
      display: flex;
      flex-direction: column-reverse;
      padding: $spacing-6 $spacing-5;
      position: fixed;
      bottom: 0;
      width: 100%;

      button {
        flex: 1;
        margin: 0 !important;
        margin-bottom: $spacing-2 !important;
      }
    }
  }
}

.illustration {
  display: flex;
  justify-content: center;
  padding-bottom: $spacing-4;

  svg {
    width: 320px;
    height: 160px;
  }
}
