@import '~styles/_theme';

.uploadButton {
  background: $white;
  margin-bottom: $spacing-px-10;
}

.uploadContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 128px;
  align-items: center;
  background: $nb-white;
  border: $border-2 dashed $nb-grey-400;
  border-radius: $rounded-base;
  position: relative;
  padding: $spacing-8;
  cursor: pointer;
  transition: all 0.3;

  &:focus {
    outline: none;
  }

  &:hover {
    border: $border-2 solid $nb-red-400;
    opacity: 0.8;
  }

  p {
    @include setPrint($text-sm, $nb-grey-800, $font-normal);

    text-align: center;

    strong {
      color: $nb-red-700;
      font-weight: $font-medium;
    }

    &.sub {
      @include setPrint($text-xs, $nb-grey-700, $font-normal);
    }
  }
}

.fileInfo {
  display: flex;
  align-items: center;
  color: $nb-grey-900;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
}

.selected {
  background-color: $nb-red-100;
  border-color: $nb-red-400;
}

.closeButton:global(.bp3-button) {
  background: transparent !important;
  box-shadow: none !important;
  color: $nb-svg-grey;
  padding: $spacing-0;
  padding-left: $spacing-2;

  &:hover {
    color: $nb-svg-red;
  }
}

@include mobile {
  .mediumAndUp {
    p span {
      display: none;
    }
  }
}

.uploadLogoContainer {
  padding: $spacing-6 22px;

  .sub {
    margin-top: $spacing-1;
  }

  .uploadText {
    width: $spacing-60;
    height: $spacing-16;
  }

  .uploadedPreview {
    width: $spacing-32;
    height: auto;

    &.uploadedIcon {
      width: $spacing-16;
      height: $spacing-16;
    }
  }

  .selectedContainer {
    position: relative;

    .removeBtn {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.hasFile {
    border: none;
    background-color: transparent !important;
    padding: 0;

    > button {
      display: none;
    }

    &:hover {
      img {
        opacity: 0.5;
      }

      button {
        display: block;
      }
    }

    .uploadText {
      display: none;
    }
  }
}

.uploadContainerV2 {
  background-color: $white;
  flex-direction: column;
  padding: $spacing-6;

  &.selected {
    background-color: $nb-red-100;

    .uploadIconContainer {
      background-color: $white;
    }
  }

  .fileSelected {
    color: $nb-green-700;
    display: flex;
    align-items: center;
    margin-bottom: $spacing-2;

    svg {
      margin-right: $spacing-2;
    }

    span {
      @include setPrint($text-sm, $nb-grey-900, $font-medium);
    }
  }

  .strong {
    @include setPrint($text-sm, $nb-red-600, $font-medium);
  }

  // stylelint-disable-next-line no-descending-specificity
  .uploadIconContainer {
    align-items: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border-radius: 9999px;
    background-color: $nb-warm-grey-200;
    width: $spacing-12;
    height: $spacing-12;
    margin-bottom: $spacing-2;
    color: $nb-grey-600;
  }
}

.fileList {
  margin-top: $spacing-4;
}

.fileListItem {
  display: flex;
  align-items: center;
  margin: $spacing-2 $spacing-0;
  color: $nb-svg-grey;

  // stylelint-disable-next-line no-descending-specificity
  svg {
    color: $nb-svg-grey;
  }

  .filename {
    flex: 1;
    color: $nb-grey-900;
    margin: $spacing-0 $spacing-3;
    word-break: break-all;

    &.clickableName:hover {
      cursor: pointer;
    }
  }

  .trashIcon {
    margin-left: $spacing-3;
  }

  &.uploadedFile {
    .filename {
      padding: 0;
      height: auto;
      justify-content: flex-start;
    }
  }
}

.mobileLogoUpload {
  display: none;
}

@include mobile {
  .mobileLogoUpload {
    &:not(.mobileEnabled) {
      background-color: $nb-warm-grey-100;
      border-radius: $rounded-lg;
      border: 1px solid #E5E5E5;
      display: flex;
      padding: 10px $spacing-4;
      width: 100%;

      .uploadInstruction {
        flex: 1;
        text-align: left;

        p {
          @include setPrint($nb-text-sm, $nb-grey-800, $nb-font-regular);

          text-align: left;
        }

        span {
          @include setPrint($nb-text-xs, $nb-grey-700, $nb-font-regular);
        }
      }

      .uploadActionContainer {
        display: flex;
        align-items: center;
      }
    }

    .selectedContainer {
      display: none;
    }

    .uploadLogoContainer {
      padding: 0;
      border: none;
      height: auto;
      min-height: auto;

      &:hover {
        border: none;
      }

      .mobileLogoUpload {
        display: flex;
      }

      > div {
        display: none;
      }
    }
  }
}
