@import '~styles/_theme';

.dateRangeFilter {
  .formGroup {
    margin: 0;
  }

  .formGroup + .formGroup {
    margin-top: $spacing-4;
  }

  .dateInput {
    & :global(.bp3-input-action) {
      height: 100%;
      margin-right: $spacing-4;
    }
  }

  .inputsWrapper {
    display: inline-flex;
    border: $nb-border-grey-1px;
    border-radius: $rounded-lg;
    background: $nb-white;

    > *:first-child {
      border-right: $nb-border-grey-1px;
    }

    input {
      border: none;
      height: 38px;

      &::placeholder {
        font-size: $nb-text-sm;
      }
    }
  }
}
