@import "./styles/theme";

* {
  font-family: $nb-aeonik-font;
}

body {
  margin: 0;
  padding: 0;
}

@charset "UTF-8";

blockquote,
body,
button,
dd,
div,
dl,
dt,
fieldset,
form,
input,
legend,
li,
ol,
p,
td,
textarea,
th,
ul {
  margin: 0;
  outline: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 1px;
}

.visa,
.mastercard,
.americanexpress,
.amex,
.unionpay {
  background-image: url('./assets/images/card-brands.svg');
  height: 26px;
}

.visa {
  width: 44px;
  min-width: 44px;
}

.mastercard {
  background-position: -48px 0;
  width: 84px;
  min-width: 84px;
}

.americanexpress,
.amex {
  background-position: -444px 0;
  width: 52px;
  min-width: 52px;
}

.unionpay {
  background-position: -908px 0;
  width: 32px;
  min-width: 52px;
}

// Font family (Aeonik Pro)
@font-face {
  font-family: 'Aeonik Pro';
  src: local('sans-serif');
  src:
    url('./styles/fonts/aeonik-pro/AeonikPro-Regular.woff2') format('woff2'),
    url('./styles/fonts/aeonik-pro/AeonikPro-Regular.woff') format('woff');
  font-weight: $nb-font-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro';
  src: local('sans-serif');
  src:
    url('./styles/fonts/aeonik-pro/AeonikPro-Medium.woff2') format('woff2'),
    url('./styles/fonts/aeonik-pro/AeonikPro-Medium.woff') format('woff');
  font-weight: $nb-font-medium;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro';
  src: local('sans-serif');
  src:
    url('./styles/fonts/aeonik-pro/AeonikPro-Bold.woff2') format('woff2'),
    url('./styles/fonts/aeonik-pro/AeonikPro-Bold.woff') format('woff');
  font-weight: $nb-font-bold;
  font-style: normal;
  font-display: swap;
}

// Font family (Orbikular)
@font-face {
  font-family: 'Orbikular';
  src: local('sans-serif');
  src:
    url('./styles/fonts/orbikular/Orbikular-Regular.woff2') format('woff2'),
    url('./styles/fonts/orbikular/Orbikular-Regular.woff') format('woff');
  font-weight: $nb-font-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orbikular';
  src: local('sans-serif');
  src:
    url('./styles/fonts/orbikular/Orbikular-SemiBold.woff2') format('woff2'),
    url('./styles/fonts/orbikular/Orbikular-SemiBold.woff') format('woff');
  font-weight: $nb-font-semibold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orbikular';
  src: local('sans-serif');
  src:
    url('./styles/fonts/orbikular/Orbikular-Bold.woff2') format('woff2'),
    url('./styles/fonts/orbikular/Orbikular-Bold.woff') format('woff');
  font-weight: $nb-font-bold;
  font-style: normal;
  font-display: swap;
}