@import '~styles/_theme';

.feeAllocationContainer {
  .feeSlider {
    > div {
      height: 4px;

      > div:last-of-type {
        background-color: $nb-grey-300;
      }
    }

    > span {
      top: -9px;
      border-radius: $rounded-full;
      box-shadow: $shadow-base;
      background: $nb-white;
      width: 32px;
      height: 32px;
      border: $nb-border-grey-1px;

      :global(.bp3-slider-label) {
        background: $nb-grey-800;
        border-radius: $rounded-sm;
        box-shadow: none;
        padding: $spacing-2 $spacing-3;
        top: -54px;
        left: 6.5px;
        opacity: 0;
        transition: opacity 0.2s ease-in;
      }

      &:hover,
      &:active {
        box-shadow: $shadow-base;
        background: $nb-grey-200;

        :global(.bp3-slider-label) {
          opacity: 1;
        }
      }

      &::before,
      &::after {
        font-size: 11px;
        top: 5px;
        color: $nb-svg-grey;
      }

      &::before {
        content: '❮';
        position: absolute;
        left: 7px;
      }

      &::after {
        content: '❯';
        position: absolute;
        right: 6px;
      }
    }

    // stylelint-disable-next-line no-descending-specificity
    :global(.bp3-slider-axis) {
      display: none;
    }
  }

  .feeContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-6;

    .feeAllocation {
      @include setPrint($nb-text-sm, $nb-grey-800, $nb-font-semibold);

      display: flex;
      flex-direction: column;

      span {
        margin-bottom: $spacing-2;
      }

      &:last-of-type {
        align-items: flex-end;
      }

      &:first-of-type {
        align-items: flex-start;
      }

      input {
        @include setPrint($nb-text-base, $nb-grey-900, $nb-font-medium);

        padding: 0;
        text-align: center;
        height: 44px;
        width: 60px;

        &:disabled {
          color: $nb-grey-700;
          background: none;
        }
      }
    }
  }
}
