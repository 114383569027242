@import '~styles/_theme';

.stepperField.stepperField {
  display: flex;
  flex-direction: row;
  height: 44px;
  border: $nb-border-light-grey-1px;
  overflow: hidden;
  border-radius: $rounded-base;

  > span {
    background-color: $nb-grey-100;
    display: flex;
    width: 50px;
    height: $spacing-11;
    padding: 0;
    justify-content: center;
    align-items: center;

    svg {
      stroke: $nb-svg-grey;
    }

    svg:hover {
      stroke: $nb-grey-800;
    }

    button:disabled,
    button[disabled] {
      svg {
        stroke: $nb-grey-400;
      }
    }

    // stylelint-disable-next-line no-descending-specificity
    button:global(.bp3-button) {
      font-size: $text-base;
      height: $spacing-11;
      background-color: transparent;
      flex: 1;
      padding: 0;
      margin: 0;
      box-shadow: none;
    }
  }

  > span:first-child {
    border-right: $nb-border-light-grey-1px;
  }

  > span:last-child {
    border-left: $nb-border-light-grey-1px;
  }

  input {
    text-align: center;
    border-top: none;
    border-left-color: $nb-grey-300;
    border-right-color: $nb-grey-300;
    height: $spacing-11;
    flex: 1;
    color: $nb-grey-900;
    background: $white !important;
    width: auto;
    min-width: 20px;
    border-radius: 0 !important;

    &:disabled {
      opacity: 1;
    }
  }
}

.stepperContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  h2 {
    margin: 0;

    @include setPrint($nb-text-base, $nb-grey-900, $nb-font-regular);
  }

  .centerText {
    flex: 1;
    color: $nb-grey-900;
    text-align: right;
    padding-right: $spacing-3;
  }

  .stepperActions {
    flex: 1;
  }

  .stepperButton {
    flex: 1;
    background: $nb-grey-100;
    box-shadow: none;
    border: $nb-border-light-grey-1px;
    text-align: left;

    &:first-child {
      border-radius: 0.375rem 0 0 0.375rem;
    }

    &:last-child {
      border-radius: 0 0.375rem 0.375rem 0;
      margin-left: -1px;
    }

    // stylelint-disable-next-line no-descending-specificity
    span {
      // stylelint-disable-next-line no-descending-specificity
      svg {
        transform: scale(2);
      }
    }
  }
}
