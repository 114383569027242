@import '~styles/_theme';

$range-border-radius: 14px;
$range-border-mobile-radius: 18px;

$day-size: 32px;
$day-size-mobile: 40px;

.dateRangeWrapper {
  position: relative;
  display: inline-flex;
  width: 100%;

  input {
    font-size: $nb-text-sm;
    width: 100%;
    height: 38px;

    &::placeholder {
      font-size: $nb-text-sm;
    }
  }

  .maskButton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  :global(.bp3-control-group) {
    > *:first-child {
      input {
        border-top-right-radius: $rounded-none;
        border-bottom-right-radius: $rounded-none;
      }
    }

    > *:last-child {
      input {
        border-top-left-radius: $rounded-none;
        border-bottom-left-radius: $rounded-none;
      }
    }
  }

  .leftIcon,
  .rightIcon {
    position: absolute;
    top: $spacing-2;
    color: $nb-svg-grey;

    @include mobile {
      display: none;
    }
  }

  .leftIcon {
    left: calc(50% - #{$spacing-8});
  }

  .rightIcon {
    right: $spacing-3;
  }
}

.dateRangeInput {
  width: 100%;

  :global(.bp3-popover-target) {
    width: 100%;

    :global(.bp3-input-group) {
      flex: 1;
    }
  }

  // to allow spacing between weeks
  // stylelint-disable-next-line no-descending-specificity
  :global(.DayPicker-WeekdaysRow) {
    display: flex;

    :global(.DayPicker-Weekday) {
      flex: 1;
    }
  }

  // stylelint-disable-next-line no-descending-specificity
  :global(.DayPicker-Week) {
    display: block;
    margin-bottom: $spacing-1;
  }

  :global(.DayPicker-Day--outside) + :global(.DayPicker-Day--selected-range) {
    border-top-left-radius: $range-border-radius !important;
    border-bottom-left-radius: $range-border-radius !important;

    @include mobile {
      border-top-left-radius: $range-border-mobile-radius !important;
      border-bottom-left-radius: $range-border-mobile-radius !important;
    }
  }

  :global(.DayPicker-Day--selected-range):not(:global(.DayPicker-Day--outside)) {
    position: relative;
    z-index: 1;

    + :global(.DayPicker-Day--outside) {
      position: relative;
      visibility: visible;
      color: $nb-white;
      background: none !important;

      &::after {
        content: ' ';
        position: absolute;
        background: $nb-rose-200;
        top: 0;
        bottom: 0;
        left: -65%;
        right: 65%;
        border-top-right-radius: $range-border-radius;
        border-bottom-right-radius: $range-border-radius;
        z-index: 0;

        @include mobile {
          border-top-right-radius: $range-border-mobile-radius;
          border-bottom-right-radius: $range-border-mobile-radius;
        }
      }
    }
  }

  // stylelint-disable-next-line no-descending-specificity
  :global(.DayPicker-Day) {
    margin: $spacing-px-2 $spacing-0;

    // stylelint-disable-next-line no-descending-specificity
    &:first-child {
      border-top-left-radius: $range-border-radius !important;
      border-bottom-left-radius: $range-border-radius !important;

      @include mobile {
        border-top-left-radius: $range-border-mobile-radius !important;
        border-bottom-left-radius: $range-border-mobile-radius !important;
      }
    }

    // stylelint-disable-next-line no-descending-specificity
    &:last-child {
      border-top-right-radius: $range-border-radius !important;
      border-bottom-right-radius: $range-border-radius !important;

      @include mobile {
        border-top-right-radius: $range-border-mobile-radius !important;
        border-bottom-right-radius: $range-border-mobile-radius !important;
      }
    }
  }

  // stylelint-disable-next-line no-descending-specificity
  :global(.DayPicker-Day.DayPicker-Day--selected) {
    background-color: $nb-rose-700 !important;
    border-radius: $rounded-full !important;
    transform-style: preserve-3d;
    font-weight: $nb-font-medium !important;
    color: $nb-white;
  }

  &.rangeSelected {
    :global(.DayPicker-Day.DayPicker-Day--selected-range-start)::after,
    :global(.DayPicker-Day.DayPicker-Day--selected-range-end)::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: $day-size;
      height: $day-size;
      transform: translateZ(-1px);
      background-color: $nb-rose-200 !important;

      @include mobile {
        width: $day-size-mobile;
        height: $day-size-mobile;
      }
    }

    :global(.DayPicker-Day.DayPicker-Day--selected-range-start)::after {
      border-top-left-radius: $rounded-full !important;
      border-bottom-left-radius: $rounded-full !important;
    }

    :global(.DayPicker-Day.DayPicker-Day--selected-range-end)::after {
      border-top-right-radius: $rounded-full !important;
      border-bottom-right-radius: $rounded-full !important;
    }

    :global(.DayPicker-Day--selected):global(.DayPicker-Day--outside)::after {
      left: -65%;
    }
  }

  // stylelint-disable-next-line no-descending-specificity
  :global(.DayPicker-Day.DayPicker-Day--selected-range.DayPicker-Day--outside) + :global(.DayPicker-Day--selected)::after {
    display: none;
  }

  // stylelint-disable-next-line no-descending-specificity
  :global(.DayPicker-Day.DayPicker-Day--selected-range) {
    background-color: $nb-rose-200 !important;
  }

  @include mobile {
    > div {
      display: flex;
      flex-direction: column;

      > div:last-child {
        margin-top: $spacing-3;
      }
    }

    :global(.DayPicker-Weekday),
    :global(.DayPicker-Day) {
      width: $day-size-mobile !important;
      height: $day-size-mobile !important;
    }
  }
}

.drawer {
  .drawerTitle {
    p:first-child {
      @include nb-dialog-title;
    }

    p:last-child {
      margin-top: $spacing-1;

      @include nb-paragraph;
    }
  }

  // stylelint-disable-next-line no-descending-specificity
  :global(.bp3-drawer-header) {
    border-bottom: none !important;
  }

  // stylelint-disable-next-line no-descending-specificity
  :global(.bp3-drawer-body) {
    padding-top: $spacing-0 !important;
  }

  // stylelint-disable-next-line no-descending-specificity
  :global(.bp3-dialog-close-button) {
    align-self: flex-start;
  }
}
