@import '~styles/_theme';

.progressBar {
  width: 100%;
  max-width: $spacing-192;

  @include mobile {
    width: 100%;
  }
}

.content {
  width: 100%;
  max-width: $spacing-100;
  color: $nb-grey-600;
  align-self: center;

  .headerContainer {
    text-align: center;
    margin-bottom: 18px;
  }

  .header {
    color: $nb-grey-600;
    display: block;

    @include setPrint($text-lg, $nb-grey-800, $font-semibold);

    @include mobile {
      @include setPrint($text-lg, $nb-grey-800);

      margin-top: $spacing-6;
    }
  }

  .buttonContainer {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      width: 100%;

      &:first-of-type {
        margin-right: $spacing-4;
      }
    }

    .nextButton {
      @include buttonStyle($nb-button-primary, $white);

      &:hover {
        background: $nb-button-primary-hovered;
      }
    }

  }

  .footer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: $spacing-8;
    padding-top: $spacing-8;
    width: 100%;
    max-width: $spacing-116;
    position: relative;

    &::before {
      display: block;
      content: ' ';
      position: absolute;
      top: 0;
      left: -55px;
      right: -55px;
      height: 2px;
      background: $nb-grey-100;
    }

    @include mobile {
      background: $nb-white;
      left: 0;
      right: 0;
      border-top: $nb-border-light-grey-1px;
      padding: $spacing-4 calc(10% / 2);
      width: 100%;
      bottom: 0;
      position: fixed;
      z-index: 999;

      &::before {
        display: none;
      }
    }
  }
}
