@import '~styles/_theme';

.amountFilter {
  .textInputContainer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: $spacing-6;

    .dash {
      margin: $spacing-2;
      height: $spacing-6;
    }

    .dash::before {
      content: '-';
    }
  }

  .sliderContainer {
    padding: 0 $spacing-1;
  }

  .slider {
    &:global(.bp3-slider.bp3-slider-unlabeled) {
      height: $spacing-5;
    }

    & :global(.bp3-slider-track) {
      top: 7px; // align center
    }

    & :global(.bp3-slider-progress) {
      background-color: $nb-red-300;
    }

    & :global(.bp3-slider-handle) {
      width: $spacing-5;
      height: $spacing-5;
      border-radius: $rounded-full !important;
      background: $nb-red-600;
      box-shadow: $shadow-base;
      margin: 0;

      &:focus {
        outline: none;
      }
    }

    & :global(.bp3-slider-progress):global(.bp3-intent-primary) {
      background: $nb-accent-primary;
    }
  }

  .textInputContainer .formGroup {
    margin: 0;
    max-width: 50%;

    & :global(.bp3-label) {
      white-space: nowrap;
      overflow: visible;
      color: $nb-grey-800;
    }
  }
}
