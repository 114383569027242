@import '~styles/_theme';

.testimonial {
  .imageContainer {
    text-align: center;

    img {
      width: $spacing-48;
    }
  }

  .quote {
    margin-top: $spacing-6;
    margin-bottom: -$spacing-5;
    background: $nb-accent-primary;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @include setPrint($nb-text-6xl, $nb-red-300, $nb-font-semibold);
  }

  .message {
    @include setPrint($nb-text-lg, $nb-white, $nb-font-regular);
  }

  .name {
    margin-top: $spacing-6;

    @include setPrint($nb-text-base, $nb-white, $nb-font-bold);
  }

  .role {
    margin-bottom: $spacing-12;

    @include setPrint($nb-text-sm, $nb-purple-200, $nb-font-medium);
  }
}

.carousel :global(.BrainhubCarouselItem) {
  align-items: flex-start;
}
