@import '~styles/_theme';

@mixin circleLogo($size) {
  width: $size;
  height: $size;
  border-radius: $rounded-full;
}

.avatarContainer {
  @include circleLogo($spacing-8);

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $nb-warm-grey-300;
  min-width: $spacing-8;
  min-height: $spacing-8;

  @include tablet {
    background-color: $nb-warm-grey-200;
  }

  svg {
    color: $nb-svg-grey;
  }

  &.withLogo {
    background-color: $nb-white;
    border: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @include mobile {
      margin-top: $spacing-1;
    }
  }

  &.large {
    @include circleLogo(80px);

    min-width: 80px;
    min-height: 80px;
  }

  &.showCompanyInitial {
    background-color: $nb-yellow-100;
    border: $border-1 solid $nb-yellow-200;
  }

  .initialNameContainer {
    @include fontStyles($nb-text-4xl);

    color: $nb-yellow-900;
  }
}

@media (max-width: 575px) {
  .avatarContainer {
    &.large {
      @include circleLogo($spacing-16);

      min-width: $spacing-16;
      min-height: $spacing-16;
    }
  }
}
