@import '~styles/_theme';

.container {
  background: transparent;
  border-radius: 3px;
  text-align: left;
  width: 100%;
  margin-top: $spacing-1;

  .errorBox,
  .successBox,
  .warningBox {
    @include fontStyles($nb-text-sm);

    color: $nb-orange-800;
    background: transparent;
    padding: 0;
    display: flex;
    font-weight: $nb-font-regular;
    align-items: flex-start;

    svg {
      flex: 0 0 $nb-spacing-5;
      margin-right: $nb-spacing-2;
    }
  }

  .successBox {
    color: $nb-green-800;
  }

  .warningBox {
    color: $nb-yellow-800;
  }
}
