@import '~styles/_theme';

.menuItem {
  display: flex;
  flex-direction: row;

  svg {
    margin-right: $nb-spacing-3;
  }
}

.moreBtn {
  max-height: $nb-spacing-6;

  &:hover {
    cursor: pointer;
  }
}
