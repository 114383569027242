@import '~styles/_theme';

.cardContainer {
  margin: $nb-spacing-4 0;
  padding: $spacing-4;
  display: flex;
}

.cardContent {
  flex: 1;
}

.checked {
  background-color: $nb-warm-grey-100;
}
