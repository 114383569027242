@import '~styles/_theme';

.MobileFullPageDialog {
  background: $nb-white;

  :global(.bp3-dialog-header) {
    box-shadow: initial;
    padding: $spacing-10 $spacing-10 $spacing-0 $spacing-10;

    :global(.bp3-dialog-close-button) {
      z-index: 1;
      padding: 0;
      min-width: 0;
      min-height: 0;
      width: $spacing-6;
      height: $spacing-6;

      svg {
        color: $nb-svg-grey;
        height: 100%;
        width: 100%;
      }

      &:hover {
        background: none;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

@include mobile {
  :global(.bp3-overlay-backdrop) {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .MobileFullPageDialog {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    border-radius: initial;

    :global(.bp3-dialog-header) {
      padding: $nb-spacing-5 $nb-spacing-4;
      box-shadow: $nb-inner-1px-bottom-warm-gray-300;

      :global(.bp3-heading) {
        order: 1;
      }

      :global(.bp3-dialog-close-button) {
        order: 0;
      }
    }
  }
}

.HeaderTitleCenter {
  :global(.bp3-dialog-header) {
    text-align: center;
    box-shadow: none !important;
  }
}