
@import '~styles/_theme';

.dismissableTip {
  background-color: $nb-grey-100;
  border: $nb-border-grey-1px;
  border-radius: $rounded-base;
  padding: $spacing-4;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: $spacing-6;

  .tipsInfo {
    padding: 0 $spacing-3;
  }

  p.title {
    @include nb-paragraph;

    font-weight: 500;
    color: $nb-grey-900;
  }

  .close {
    cursor: pointer;
  }
}
