@import '~styles/_theme';

.sectionTitle {
  @include setPrint($nb-text-base, $nb-red-700);
}

.contactInfoContainer {
  .containerWithSideIcon {
    @include setPrint($text-sm, $nb-grey-900);

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $spacing-4;

    &:first-of-type {
      margin-top: $spacing-2;
    }

    > svg {
      color: $nb-svg-grey;
      margin-right: $spacing-3;
      min-width: $spacing-5;
      min-height: $spacing-5;
      align-self: flex-start;
    }
  }
}

.personalInfoContainer {
  .recipientNameContainer {
    display: flex;
    align-items: center;
    height: $spacing-6;

    .recipientName {
      @include setPrint($text-sm, $nb-grey-900, $font-semibold);

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .starring {
      margin-left: $spacing-2;
      padding: $spacing-0;

      &:hover {
        background: $white;
      }

      svg {
        color: $nb-svg-grey;

        &.starred {
          fill: $nb-yellow-600;
          stroke: $nb-yellow-600;
        }
      }
    }
  }

  .recipientType {
    @include setPrint($text-sm, $nb-grey-600);
  }

  .bankNumberContainer {
    @include setPrint($text-sm, $nb-grey-600);

    display: flex;

    .bankCurrency {
      font-weight: $font-semibold;
    }
  }
}

.accountDetailsContainer {
  .detailsContainer {
    margin-top: $spacing-4;

    &:first-of-type {
      margin-top: $spacing-2;
    }

    .detailsLabel {
      @include setPrint($text-sm, $nb-grey-800);

      margin-bottom: $spacing-1;
    }

    .detailsContent {
      @include setPrint($text-base, $nb-grey-900);
    }
  }
}

.actionBtnsContainer {
  display: flex;
  justify-content: space-between;

  .deleteRecipientBtn,
  .editRecipientBtn {
    @include setPrint($text-sm, $nb-grey-800);

    height: $spacing-8;
  }

  .deleteRecipientBtn {
    border-color: $nb-grey-400;
  }

  .editRecipientBtn {
    color: $nb-red-700;
    border-image-source: $nb-accent-primary;
  }
}

@include tablet {
  .deletePopover {
    width: 80%;
  }
}

.senderDetailsContainer {
  .detailsContainer {
    margin-top: $spacing-4;

    &:first-of-type {
      margin-top: $spacing-2;
    }

    .detailsLabel {
      @include setPrint($text-sm, $nb-grey-800);

      margin-bottom: $spacing-1;
    }

    .detailsContent {
      @include setPrint($text-base, $nb-grey-900);
    }
  }

  .description {
    @include setPrint($text-sm, $nb-grey-600);
  }

  .containerWithSideIcon {
    @include setPrint($text-sm, $nb-grey-900);

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $spacing-4;

    &:first-of-type {
      margin-top: $spacing-2;
    }

    > svg {
      color: $nb-svg-grey;
      margin-right: $spacing-3;
      min-width: $spacing-5;
      min-height: $spacing-5;
      align-self: flex-start;
    }
  }
}

.panelContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  .contentContainer {
    flex: 1;
    overflow-y: auto;
  }

  .bottomButtonsContainer {
    flex-shrink: 0;
    position: sticky;
    bottom: 0;
  }
}
