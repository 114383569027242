@import '~styles/_theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoContainer {
  margin-bottom: $spacing-8;

  svg {
    color: $nb-red-600;
  }
}

.descriptionContainer {
  text-align: center;
  max-width: $spacing-160;

  @include setPrint($nb-text-2xl, $nb-dark-purple, $nb-font-regular);

  @include mobile {
    max-width: $spacing-60;
    font-size: $nb-text-xl;
  }
}

@include mobile {
  .logoContainer {
    margin-bottom: $spacing-6;
  }
}
