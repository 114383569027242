@import '~styles/_theme';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  :global(.bp3-spinner-head) {
    stroke: $nb-rose-600;
  }
}
