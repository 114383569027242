$nb-spacing-0: 0;
$nb-spacing-1: 0.25rem; // 4px
$nb-spacing-2: 0.5rem; // 8px
$nb-spacing-3: 0.75rem; // 12px
$nb-spacing-4: 1rem; // 16px
$nb-spacing-5: 1.25rem; // 20px
$nb-spacing-6: 1.5rem; // 24px
$nb-spacing-7: 1.75rem; // 28px
$nb-spacing-8: 2rem; // 32px
$nb-spacing-9: 2.25rem; // 36px
$nb-spacing-10: 2.5rem; // 40px
$nb-spacing-11: 2.75rem; // 44px
$nb-spacing-12: 3rem; // 48px
$nb-spacing-14: 3.5rem; // 56px
$nb-spacing-15: 3.75rem; // 60px
$nb-spacing-16: 4rem; // 64px
$nb-spacing-19: 4.75rem; // 76px
$nb-spacing-20: 5rem; // 80px
$nb-spacing-21: 5.25rem; // 84px
$nb-spacing-23: 5.75rem; // 92px
$nb-spacing-24: 6rem; // 96px
$nb-spacing-25: 6.25rem; // 100px
$nb-spacing-27: 6.75rem; // 108px
$nb-spacing-28: 7rem; // 112px
$nb-spacing-30: 7.5rem; //120px;
$nb-spacing-32: 8rem; // 128px
$nb-spacing-36: 9rem; // 144px
$nb-spacing-40: 10rem; // 160px
$nb-spacing-48: 12rem; // 192px
$nb-spacing-60: 15rem; // 240px
$nb-spacing-62: 15.5rem; // 248px
$nb-spacing-64: 16rem; // 256px
$nb-spacing-66: 16.25rem; // 264px
$nb-spacing-75: 18.75rem; // 300px
$nb-spacing-80: 20rem; // 320px
$nb-spacing-81: 20.25rem; // 324px
$nb-spacing-96: 24rem; // 384px
$nb-spacing-120: 30rem; // 480px
$nb-spacing-128: 32rem; // 512px
$nb-spacing-160: 40rem; // 640px
$nb-spacing-192: 48rem; // 768px
