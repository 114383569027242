@import '~styles/_theme';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: $spacing-96;

  .header {
    @include nb-h2;

    margin: $spacing-0;
    margin-top: $spacing-6;
    margin-bottom: $spacing-4;
  }

  .subHeader {
    @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);

    margin-bottom: $spacing-4;
    max-width: $spacing-96;

    .highlight {
      color: $nb-red-700;
    }
  }
}

.container.inModal {
  .header {
    @include setPrint($nb-text-xl, $nb-grey-900, $nb-font-medium);

    margin: $spacing-0;
    margin-bottom: $spacing-2;
  }

  .subHeader {
    @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);

    margin-bottom: $spacing-6;
  }

  .doneBtn {
    margin-bottom: $spacing-4;
  }
}

@include mobile {
  .container,
  .container.inModal {
    .header {
      @include setPrint($nb-text-2xl, $nb-dark-violet, $nb-font-regular);

      margin: $spacing-0;
      margin-bottom: $spacing-2;
      text-align: center;
    }

    .subHeader {
      @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);

      align-self: flex-start;
    }

    .doneBtnGroup {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: $spacing-4;
      flex-direction: column;
      border-top: $nb-border-light-grey-1px;
      background-color: $white;
    }
  }

  .container.inModal {
    .header {
      @include setPrint($nb-text-xl, $nb-grey-900, $nb-font-medium);

      text-align: left;
    }
  }
}
