@import '~styles/_theme';

.FullPage {
  background: $nb-warm-grey-200;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: initial;
  border-radius: initial;
  overflow-y: auto;

  &.whiteBG {
    background-color: $white;
  }

  &.cleanFullPage {
    background-color: $white;

    .FullPage_content {
      border: none;
      box-shadow: none;
    }
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin: $spacing-0 !important;
    width: 100%;

    .headerContainerMobile,
    .headerContainerDesktop {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .headerContainerDesktop {
      flex-direction: row;
      padding: $spacing-5 112px;

      @media (min-width: 575px) and (max-width: 768px) {
        padding: $spacing-5 $spacing-8 $spacing-0 $spacing-8;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        padding: $spacing-5 $spacing-8;
      }

      @media (max-width: 575px) {
        display: none;
      }

      @media (min-width: 575px) and (max-width: 768px) {
        .title {
          display: none;
        }
      }

      .title {
        @include fontStyles($text-lg);
      }

      .headerRightContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .languageSwitcher {
          color: $nb-svg-grey;

          &::after {
            content: '';
            border: $nb-border-grey-1px;
            height: $spacing-8;
            margin-left: $spacing-4;
          }
        }

        .closeButton {
          color: $nb-svg-grey;
          margin-left: $spacing-4;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .progressBarTablet {
      display: none;

      @include tabletAndUp {
        @include tablet {
          display: block;
          width: 100%;
          max-width: 375px;
          margin-bottom: $spacing-6;
        }
      }
    }

    .headerContainerMobile {
      @include tabletAndUp {
        display: none;
      }

      flex-direction: column;

      .dialogTitleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $spacing-16;
        background-color: $nb-white !important;
        color: $nb-grey-900;
        box-shadow: $nb-inner-1px-bottom-gray-300;
        width: 100%;
        padding: 0 $spacing-5;
        position: relative;

        @include mobile {
          background-color: $nb-warm-grey-200;
        }

        .titleBackBtnContainer {
          @include fontStyles($text-sm);

          font-weight: $font-medium;
          display: flex;
          align-items: center;
          color: $nb-red-700;

          svg {
            color: $nb-red-600;
          }
        }

        // stylelint-disable-next-line no-descending-specificity
        svg {
          color: $nb-svg-grey;
        }

        // stylelint-disable-next-line no-descending-specificity
        .closeButton {
          max-width: $spacing-6;
        }

        .dialogTitle {
          position: absolute;
          color: $nb-dark-violet;
          left: 50%;
          transform: translateX(-50%);
          line-height: $leading-normal;
          font-size: $text-lg;
          font-weight: $nb-font-regular;
        }
      }

      .title {
        width: 100%;
      }
    }
  }

  .testPaymentBlock {
    text-align: right;

    @include mobile {
      text-align: left;
      padding-left: $spacing-4;
    }
  }

  // stylelint-disable-next-line no-descending-specificity
  .FullPage_content {
    max-width: $spacing-192;
    overflow: visible;
    width: 100%;

    @media (min-width: 575px) and (max-width: 1200px) {
      max-width: 576px;
      &.isExpanded {
        max-width: 786px;
      }
    }

    background-color: $white;
    justify-content: center;
    display: flex;
    border: $nb-border-light-grey-1px;
    box-shadow: $shadow-sm;

    &.testPayment {
      border-color: $nb-orange-400;
      flex-direction: column;
      padding: $spacing-3;
      padding-bottom: $spacing-12;

      @include mobile {
        padding-top: $spacing-6;
      }
    }

    .children {
      display: flex !important;
      justify-content: center;

      @include desktop {
        margin-top: $spacing-5;
      }
    }

    .staticContent {
      display: contents;
    }

    @media (min-width: 575px) {
      margin: $spacing-6 auto;
      padding: $spacing-12;
      border-radius: $rounded-lg;
      box-shadow: $shadow-sm;
      margin-bottom: $spacing-6;
    }
  }
}

@media (max-width: 575px) {
  .FullPage {
    background: $white !important;

    .FullPage_content {
      padding: 0;
      width: 90%;
      margin: $spacing-0 10px $spacing-0;
      box-shadow: $shadow-none;
      border: $border-0;
      &.isExpanded {
        width: 100%;
      }
    }
  }
}

.showTopBanner {
  flex-direction: column;
  margin-top: 10px;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testPaymentDone {
  .testPaymentBlock {
    text-align: center;
  }
}
