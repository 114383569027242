@import '~styles/_theme';

.ctaMenuItem {
  align-items: center;

  @include setPrint($nb-text-sm, $nb-grey-800, $nb-font-regular);

  :global(.bp3-menu-item-label) {
    color: $nb-grey-600;
    order: -1;
    margin-right: $spacing-4;
    line-height: 12px;
  }
}
