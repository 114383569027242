$black: #000;
$white: #FFF;

// "True" grey
$nb-grey-1000: #171717;
$nb-grey-900: #262626;
$nb-grey-800: #404040;
$nb-grey-700: #525252;
$nb-grey-600: #737373;
$nb-grey-500: #A3A3A3;
$nb-grey-400: #D4D4D4;
$nb-grey-300: #E5E5E5;
$nb-grey-200: #F5F5F5;
$nb-grey-100: #FAFAFA;

// "Warm" grey
$nb-warm-grey-1000: #1C1717;
$nb-warm-grey-900: #292424;
$nb-warm-grey-800: #443C3C;
$nb-warm-grey-700: #574E4E;
$nb-warm-grey-600: #786C6C;
$nb-warm-grey-500: #A89E9E;
$nb-warm-grey-400: #D7D1D1;
$nb-warm-grey-300: #E9E4E4;
$nb-warm-grey-200: #F6F3F3;
$nb-warm-grey-100: #FBF9F9;

// Shades
$nb-black: #000;
$nb-white: #FFF;
$nb-dark-purple: #362042;
$nb-dark-violet: #3C2A59;
$nb-dark-violet-75: rgba($nb-dark-violet, 0.75);
$nb-earth-teal: #C5CDCB;
$nb-earth-light-blue: #CAD3D8;
$nb-warm-gray-200: #F6F3F3;

// Primary
$nb-red-1000: #861322;
$nb-red-900: #A11223;
$nb-red-800: #BF1220;
$nb-red-700: #E21D27;
$nb-red-600: #FF4E4E;
$nb-red-500: #FB706E;
$nb-red-400: #FDA8A4;
$nb-red-300: #FECFCD;
$nb-red-200: #FFE8E5;
$nb-red-100: #FFF2F1;

// Supporting
// Orange
$nb-orange-1000: #7C1412;
$nb-orange-900: #981614;
$nb-orange-800: #BB1B13;
$nb-orange-700: #E7280F;
$nb-orange-600: #FF5029;
$nb-orange-500: #FB653C;
$nb-orange-400: #FD9B74;
$nb-orange-300: #FFC3A9;
$nb-orange-200: #FFE3D5;
$nb-orange-100: #FFF3ED;

// Yellow (Amber)
$nb-yellow-1000: #78300F;
$nb-yellow-900: #923A0E;
$nb-yellow-800: #B44B09;
$nb-yellow-700: #D96C06;
$nb-yellow-600: #F5940B;
$nb-yellow-500: #FBBA37;
$nb-yellow-400: #FFD56B;
$nb-yellow-300: #FFE494;
$nb-yellow-200: #FFF4D1;
$nb-yellow-100: #FFFAEB;

// Green (Emerald)
$nb-green-1000: #064E3B;
$nb-green-900: #065F46;
$nb-green-800: #047857;
$nb-green-700: #059669;
$nb-green-600: #10B981;
$nb-green-500: #34D399;
$nb-green-400: #6EE7B7;
$nb-green-300: #A7F3D0;
$nb-green-200: #D1FAE5;
$nb-green-100: #ECFDF5;

// Teal
$nb-teal-1000: #134E48;
$nb-teal-900: #115E56;
$nb-teal-800: #0F766A;
$nb-teal-700: #0D9484;
$nb-teal-600: #14B89F;
$nb-teal-500: #2DD4B8;
$nb-teal-400: #73EDD6;
$nb-teal-300: #AFF8E7;
$nb-teal-200: #D9FCF3;
$nb-teal-100: #F0FDFA;

// Light blue
$nb-light-blue-1000: #0C486E;
$nb-light-blue-900: #075785;
$nb-light-blue-800: #0367A1;
$nb-light-blue-700: #0282C7;
$nb-light-blue-600: #0EA0E9;
$nb-light-blue-500: #38BBF8;
$nb-light-blue-400: #7DD2FC;
$nb-light-blue-300: #BAE4FD;
$nb-light-blue-200: #E0F2FE;
$nb-light-blue-100: #F0F9FF;

// Indigo
$nb-indigo-1000: #312E81;
$nb-indigo-900: #3730A3;
$nb-indigo-800: #4338CA;
$nb-indigo-700: #4F46E5;
$nb-indigo-600: #6366F1;
$nb-indigo-500: #818CF8;
$nb-indigo-400: #A5B4FC;
$nb-indigo-300: #C7D2FE;
$nb-indigo-200: #E0E7FF;
$nb-indigo-100: #EEF2FF;

// Violet
$nb-violet-1000: #4C1D95;
$nb-violet-900: #5B21B6;
$nb-violet-800: #6D28D9;
$nb-violet-700: #7C3AED;
$nb-violet-600: #8B5CF6;
$nb-violet-500: #A78BFA;
$nb-violet-400: #C4B5FD;
$nb-violet-300: #DDD6FE;
$nb-violet-200: #EDE9FE;
$nb-violet-100: #F5F3FF;

// Purple (Mauve)
$nb-purple-1000: #621C87;
$nb-purple-900: #7621A8;
$nb-purple-800: #8C22CE;
$nb-purple-700: #A133EA;
$nb-purple-600: #B655F7;
$nb-purple-500: #CA84FC;
$nb-purple-400: #DEB4FE;
$nb-purple-300: #EDD5FF;
$nb-purple-200: #F5E8FF;
$nb-purple-100: #FBF5FF;

// Pink
$nb-pink-1000: #8B1037;
$nb-pink-900: #AB093F;
$nb-pink-800: #D20450;
$nb-pink-700: #FF006A;
$nb-pink-600: #FD3A8B;
$nb-pink-500: #FC6AA9;
$nb-pink-400: #FEA3CD;
$nb-pink-300: #FECCE4;
$nb-pink-200: #FFE4F1;
$nb-pink-100: #FFF0F7;

// Fuchsia
$nb-fuchsia-1000: #701A75;
$nb-fuchsia-900: #86198F;
$nb-fuchsia-800: #A21CAF;
$nb-fuchsia-700: #C026D3;
$nb-fuchsia-600: #D946EF;
$nb-fuchsia-500: #E879F9;
$nb-fuchsia-400: #F0ABFC;
$nb-fuchsia-300: #F5D0FE;
$nb-fuchsia-200: #FAE8FF;
$nb-fuchsia-100: #FDF4FF;

// Rose
$nb-rose-1000: #881338;
$nb-rose-900: #9F123C;
$nb-rose-800: #BE1240;
$nb-rose-700: #E11D4B;
$nb-rose-600: #FE4365;
$nb-rose-500: #FB7188;
$nb-rose-400: #FDA4B0;
$nb-rose-300: #FECDD4;
$nb-rose-200: #FFE4E7;
$nb-rose-100: #FFF1F2;

// light-violet
$light-violet-1000: #3C2A59;
$light-violet-900: #554474;
$light-violet-800: #6B578E;
$light-violet-700: #806AAA;
$light-violet-600: #9981C5;
$light-violet-500: #B096D9;
$light-violet-400: #CAB7EB;
$light-violet-300: #E2D6F5;
$light-violet-200: #F0EAFA;
$light-violet-100: #F9F7FD;

$nb-gradient-pink-700-yellow-400: linear-gradient(
  252deg,
  $nb-pink-700 0%,
  $nb-yellow-400 100%
);
$nb-gradient-pink-100-orange-100: linear-gradient(
  108deg,
  $nb-pink-100 0%,
  $nb-orange-100 100%
);
$nb-gradient-pink-200-orange-200: linear-gradient(
  108deg,
  $nb-pink-200 0%,
  $nb-orange-200 100%
);
$nb-gradient-pink-700-orange-700: linear-gradient(
  108deg,
  $nb-pink-700 0%,
  $nb-orange-700 100%
);

// Semantic vars
$nb-brand-primary: $nb-red-600;
$nb-bp-primary: $light-violet-800;
$nb-accent-primary: linear-gradient(
  252.43deg,
  $nb-orange-600 0%,
  $nb-pink-600 100%
);
$nb-accent-primary-disabled: linear-gradient(
  252.43deg,
  rgba($nb-orange-600, 0.5) 0%,
  rgba($nb-pink-600, 0.5) 100%
);
$nb-accent-primary-hovered: linear-gradient(
  252.43deg,
  $nb-orange-500 0%,
  $nb-pink-500 100%
);

// params
$nb-button-primary: $nb-rose-700;
$nb-button-primary-disabled: rgba($nb-button-primary, 0.5);
$nb-button-primary-hovered: $nb-rose-800;
$nb-button-primary-svg: $nb-rose-600;
$nb-button-primary-border-color: $nb-rose-400;
$nb-button-primary-bg: $nb-rose-100;

$nb-svg-grey: $nb-grey-600;
$nb-svg-red: $nb-red-600;

$nb-backdrop-color: rgba($nb-grey-1000, 0.25);
$nb-anchor-primary: $light-violet-800;

// inputs
$nb-input-placeholder-color: $nb-grey-500;
$nb-input-color: $nb-grey-900;
