@import '~styles/_theme';

.container {
  background: $nb-white;
  border: none;
  box-shadow: $shadow-sm;
  border-radius: $rounded-lg;
  display: flex;
  flex-direction: row;
  padding: $spacing-8;

  .descriptionAndTitleContainer {
    margin-right: $spacing-12;
    flex: 1;
    max-width: $spacing-88;

    .header {
      @include nb-h3;
    }

    .description {
      margin-top: $spacing-2;

      @include nb-paragraph;

      max-width: $spacing-86;
    }
  }

  .settingFormContainer {
    flex: 2;
  }
}

@include settingMediumDevice {
  .container {
    border: $border-0;
    border-radius: $rounded-none;
    flex-direction: column;
    padding: $spacing-5;

    .descriptionAndTitleContainer {
      width: 100%;
      max-width: 100%;

      .header {
        @include setPrint($text-lg, $nb-grey-900);
      }
    }

    .settingFormContainer {
      flex: 1;
    }
  }
}

@include tablet {
  .container {
    .descriptionAndTitleContainer {
      .description {
        width: 100%;
        max-width: inherit;
      }
    }
  }
}
