@import '~styles/_theme';

form.stepContainer {
  max-width: $spacing-96;
  width: $spacing-96;
  
  button + button {
    margin-top: $spacing-2;
  }
}

.nameContainer {
  margin-bottom: $spacing-3;
  :global(.equallySpaced) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  
    > div {
      width: 100%;
    }

    :global(.bp3-input-group:first-of-type){
      :global(.bp3-input) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
      }
    }

    :global(.bp3-input-group:last-of-type) {
      :global(.bp3-input) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }


    :global(.bp3-input:last-child) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left: none;
    }
  }
}

.submitBtn {
  @include setPrint($nb-text-base, $white, $nb-font-medium);

  color: $white !important;
  width: 100%;
  height: $spacing-12;
  background-color: $nb-rose-700 !important;
  border: none !important;
  box-shadow: $nb-shadow-sm !important;
  padding: $spacing-4 $spacing-8;
  border-radius: $rounded-base !important;

  &:disabled {
    background-color: $nb-rose-400 !important;
  }
}

p.labelWithToolTip {
  display: flex;
  align-items: center;

  .inlineTip {
    margin-left: $spacing-1;
    height: $spacing-4;
    cursor: pointer;

    svg {
      width: $spacing-4;
      height: $spacing-4;
      color: $nb-grey-600;
    }
  }
}

.tipPortal {
  background-color: $white !important;

  > div {
    margin-bottom: $spacing-2;
  }
}

.tip {
  padding: $spacing-4 !important;
  background-color: $white !important;
  width: $spacing-70;
  box-shadow: $nb-shadow-lg;

  :global(.bp3-popover-content) {
    padding: 0;
  }

  p.tipHeader {
    @include setPrint($nb-text-xs, $nb-grey-600, $nb-font-medium);

    text-transform: uppercase;
    margin-bottom: $spacing-2;
  }

  p.tipContent {
    @include setPrint($nb-text-sm, $nb-grey-900, $nb-font-regular);
  }
}

.btnOutline {
  @include setPrint($nb-text-lg, $nb-rose-700, $nb-font-medium);

  width: 100%;
  height: $spacing-12;
  padding: 0 $spacing-6;
  border-radius: $rounded-lg;
  border: $border-1 solid $nb-rose-400;
  background: $white !important;
  box-shadow: $nb-shadow-sm !important;
  margin-bottom: $spacing-4;
  color: $nb-rose-700 !important;
}

footer.formFooter {
  @include setPrint($nb-text-sm, $nb-grey-600, $nb-font-regular);

  margin-top: $spacing-6;
  text-align: center;

  a {
    text-decoration: underline;
  }
}

.phoneVerify {
  @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);

  text-align: center;

  strong {
    @include setPrint($nb-text-sm, $nb-grey-800, $nb-font-medium);
  }
}

.verifyContainer {
  max-width: $spacing-96;
  width: $spacing-96;
  margin-top: $spacing-24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    margin-top: $spacing-2;

    @include setPrint($nb-text-base, $nb-grey-900, $nb-font-bold);
  }
}

.successfailContainer {
  max-width: $spacing-96;
  width: $spacing-96;
  margin-top: $spacing-10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  // stylelint-disable-next-line no-descending-specificity
  button + button {
    margin-top: $spacing-2;
  }

  img {
    margin-bottom: $spacing-8;
  }

  p {
    margin-bottom: $spacing-9;

    @include setPrint($nb-text-base, $nb-grey-800, $nb-font-regular);
  }

  footer.successfailFooter {
    margin-top: $spacing-4;

    p {
      @include setPrint($nb-text-sm, $nb-grey-600, $nb-font-regular);
    }

    a {
      @include setPrint($nb-text-sm, $nb-rose-700, $nb-font-regular);
    }
  }
}

.headerGroup {
  strong {
    @include setPrint($nb-text-base, $nb-grey-800, $nb-font-bold);
  }

  margin: $spacing-8 0;

  // stylelint-disable-next-line no-descending-specificity
  p {
    margin-top: unset;
    margin-bottom: unset;
  }

  // stylelint-disable-next-line no-descending-specificity
  p + p {
    margin-top: $spacing-1;
  }
}

.cardBox {
  height: 186px;

  &.shadowCards::after {
    position: relative;
    content: '';
    display: flex;
    border: $nb-border-grey-1px;
    box-shadow: $nb-shadow-md;
    width: 285px;
    height: 186px;
    border-radius: 0.75rem;
    background: linear-gradient(180deg, #F4F4F4 34.68%, #DEDEDE 100%);
    top: -186px;
    left: -15px;
  }
}

.card {

  // absolutes
  text-align: left;
  position: relative;
  width: 285px;
  height: 186px;
  border: $nb-border-grey-1px;
  box-shadow: $shadow-md;
  padding: $spacing-4 $spacing-5;
  border-radius: $rounded-2xl;
  background: linear-gradient(180deg, #FFF 34.68%, rgba(250, 250, 250, 0.8) 100%);
  z-index: 100;

  // stylelint-disable-next-line no-descending-specificity
  p {
    margin-top: unset;
    margin-bottom: unset;
  }

  .gfx {
    position: absolute;
    top: 0;
    right: 0;
  }

  .cardText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 80%;
  }

  .titleLimit {
    @include setPrint($nb-text-2xl, $nb-grey-900, $nb-font-regular);

    line-height: 32px; // absolutes
    color: $nb-grey-900;
  }

  .titleInvite {
    @include setPrint($nb-text-sm, $nb-grey-600, $nb-font-regular);
  }

  .titleExpires {
    @include setPrint($nb-text-sm, $nb-grey-600, $nb-font-regular);

    margin-top: $spacing-1;
    font-weight: normal;

    .expireDate {
      margin-left: $spacing-1;
      color: $nb-grey-900;
      font-style: normal;
    }
  }

  .titleName {
    @include nb-h4;

    font-weight: normal;
    margin-top: $spacing-2;
  }

  // stylelint-disable-next-line no-descending-specificity
  svg {
    margin-left: $spacing-2;
  }
}

.link {
  border: none !important;
  box-shadow: none !important;
  background-color: unset !important;
  color: $nb-red-700 !important;
}
