@import '~styles/_theme';

.popoverContainer {
  width: 312px;

  // stylelint-disable-next-line no-descending-specificity
  :global(.bp3-popover-content) {
    padding: $spacing-4;
  }

  .popoverTitle {
    margin-bottom: $spacing-2;

    @include nb-h6;
    h4 {
      @include nb-h4;
      letter-spacing: normal;
      font-weight: 500;
      text-transform: none;
    }
  }

  

  .popoverContent {
    @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);
  }

  .closePopoverBtn {
    position: absolute;
    top: 14px;
    right: $spacing-4;
    color: $nb-svg-grey;
  }
}
