@import '~styles/_theme';

.alert {
  :global {
    .bp3-callout {
      box-shadow: $shadow-sm;
      border-radius: $rounded-base;
      border: $border-1 solid $nb-grey-400;
      background-color: $nb-grey-100;

      &.bp3-callout-icon {
        padding: $spacing-4 $spacing-4 $spacing-4 $spacing-13;
      }

      .rightElement {
        position: absolute;
        right: 20px;
        top: 10px;
        max-width: $spacing-32;
      }

      > svg:first-child {
        position: absolute;
        left: $spacing-5;
        top: $spacing-4;
      }

      h4.bp3-heading {
        @include setPrint($text-sm, $nb-grey-900);

        margin: 0;
      }

      .alertContent {
        margin-right: 0;

        &.hasRightElement {
          padding-right: $spacing-32;
        }

        a {
          @include setPrint($text-sm, $nb-light-blue-600);

          text-decoration: underline;
        }

        @include setPrint($text-sm, $nb-grey-900, $nb-font-medium);
      }

      &.bp3-intent-primary {
        background-color: $light-violet-100;
        border: none;

        h4.bp3-heading {
          color: $light-violet-900;
        }

        .alertContent {
          color: $light-violet-900;

          a {
            color: $nb-light-blue-600;
          }

          .desc {
            @include setPrint($text-sm, $light-violet-900, $nb-font-medium);
          }
        }
      }

      &.bp3-intent-success {
        background-color: $nb-green-100;
        border-color: $nb-green-400;

        h4.bp3-heading {
          color: $nb-grey-900;
        }

        .alertContent {
          a {
            color: $nb-green-700;
          }
        }
      }

      &.bp3-intent-secondary {
        background-color: $nb-grey-100;
        border-color: $nb-grey-300;

      }

      &.bp3-intent-danger {
        background-color: $nb-red-100;
        border-color: $nb-red-400;

        .alertContent {
          a {
            color: $nb-red-700;
          }
        }
      }

      &.bp3-intent-warning {
        background-color: $nb-yellow-100;
        border-color: $nb-yellow-400;

        .alertContent {
          color: $nb-yellow-900;
        }
      }

      @include mobile {
        .alertContent.hasRightElement {
          padding-right: 0;

          .content {
            margin-bottom: $spacing-2;
          }

          .rightElement {
            position: relative;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }

  .desc {
    margin-top: $spacing-1;
    font-size: $nb-text-sm;
    font-weight: $nb-font-regular;
  }

  .alertAction {
    padding: 0;
    position: absolute;
    height: $spacing-5;
    width: $spacing-5;
    min-width: $spacing-5;
    min-height: $spacing-5;
    background-color: transparent;
    box-shadow: none;
    color: $nb-warm-grey-500;
    right: $spacing-4;
    top: $spacing-4;
  }
}
