@import '~styles/_theme';

.deletePopup {
  margin: 20px;
  font-size: $text-base;
  .buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    button {
      width: 100%;
    }
  }
}


.recipientCard {
  margin-bottom: $spacing-2;
  width: 100%;

  &:hover {
    cursor: default;

    &.canCreatePayment {
      cursor: pointer;
    }
  }
}

.recipientsContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .paginateContainer {
    margin-top: $spacing-6;
    width: 100%;

    .pageDetails {
      @media (max-width: 1000px) {
        display: none;

        @include mobile {
          display: block;
        }
      }
    }

    .paginateContainer {
      width: auto;
      margin-top: $spacing-0;
    }
  }
}

.recipientPage {
  .filterTagBar {
    margin-bottom: $spacing-2;
  }
  .titleContainer {
    .titleFloating {
      .filterButton {
        display: none;
      }
    }
  }
}
