//
// Reap Design System – Typography
//

// Font Size
$text-xs: 0.75rem; // 12px, $leading-normal
$text-sm: 0.875rem; // 14px, $leading-normal
$text-base: 1rem; // 16px, $leading-normal
$text-lg: 1.125rem; // 18px, $leading-normal
$text-xl: 1.25rem; // 20px, $leading-snug
$text-2xl: 1.5rem; // 24px, $leading-tight, $tracking-tight
$text-3xl: 1.875rem; // 30px, $leading-tighter, $tracking-tight
$text-4xl: 2.25rem; // 36px, $leading-tight, $tracking-tight
$text-5xl: 3rem; // 48px, $leading-tightest, $tracking-tighter
$text-6xl: 3.75rem; // 60px, $leading-none, $tracking-tighter
$text-7xl: 4.5rem; // 72px, $leading-none, $tracking-tightest

// Font Weight
$font-normal: 400; // $text-2xl and larger
$font-medium: 500;
$font-semibold: 600;

// Letter Spacing
$tracking-tightest: -0.03em; // $text-7xl
$tracking-tighter: -0.02em; // $text-5xl, $text-6xl
$tracking-tight: -0.01em; // $text-2xl, $text-3xl, $text-4xl
$tracking-normal: 0; // $text-xs, $text-sm, $text-base, $text-lg, $text-xl
$tracking-wide: 0.02em;
$tracking-wider: 0.03em; // use for most letter-spaced uppercase text

// Line Height
$leading-none: 1; // $text-6xl, $text-7xl
$leading-tightest: 1.125; // $text-5xl
$leading-tighter: 1.2; // $text-3xl
$leading-tight: 1.25; // $text-2xl, $text-4xl
$leading-snug: 1.4; // $text-xl
$leading-normal: 1.5; // $text-xs, $text-sm, $text-base, $text-lg
$leading-relaxed: 1.625;
$leading-loose: 2;
