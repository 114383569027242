@import '~styles/_theme';

$add-on-height: 42px;

.container {
  @include fontStyles($text-base);

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $nb-grey-700;
  list-style: none;
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  &:global(.bp3-intent-danger .bp3-input) {
    box-shadow: $nb-shadow-danger;
  }

  .moneyInput {
    width: 100%;
    float: left;
    position: relative;
    z-index: 2;
    background: transparent;
    padding-right: $spacing-16;

    &.withLeftCurrency {
      padding-left: $nb-spacing-15;
      padding-right: $nb-spacing-4;
    }

    &:focus {
      // border: $border-1 solid $nb-red-400 !important;
      outline: 0;
      box-shadow: $shadow-outline !important;
    }

    &:disabled {
      background-color: $nb-grey-200 !important;
      border-color: $nb-grey-400 !important;

      @include setPrint($nb-text-base, $nb-grey-900, $nb-font-medium);
    }

    &:focus + span {
      border-color: $nb-grey-300;
    }

    &::placeholder {
      color: $nb-grey-500;
    }
  }

  .prefixDollarSign {
    position: absolute;
    left: $spacing-4;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);
  }

  .checkWrapper {
    &.multiCurrency {
      right: $spacing-26 !important;
    }

    position: absolute;
    z-index: 2;
    right: $spacing-18;
    line-height: $add-on-height;
    top: 0;
    bottom: 0;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: $nb-green-700;
    }
  }
}

.addon {
  position: absolute;
  z-index: 3;
  padding: 0 11px;
  right: 0;
  width: $spacing-16;
  line-height: $add-on-height;
  top: 0;
  bottom: 0;
  text-align: center;
  transition: all 0.3s;
  white-space: nowrap;
  margin: $spacing-px;
  background-color: $nb-grey-100;
  color: $nb-grey-700;
  border: none;
  box-shadow: $nb-inner-1px-left-gray-400;
  border-bottom-right-radius: $rounded-lg;
  border-top-right-radius: $rounded-lg;

  &.disabled {
    background-color: $nb-grey-200;
  }

  &.leftCurrency {
    left: -2px;
    background-color: $white;
    border-bottom-left-radius: $rounded-lg;
    border-top-left-radius: $rounded-lg;
    max-width: $nb-spacing-15;

    &.disabled {
      background-color: $nb-grey-200;
    }
  }
}

.dropDownPosition {
  position: absolute;
  margin: 0 !important;
  z-index: 10;
  height: $add-on-height;
  width: $spacing-24 !important;
  box-shadow: $shadow-inner-sm !important;
  border-radius: 0 $rounded-base $rounded-base 0 !important;
  background: $nb-grey-100 !important;
  border: none !important;
  top: 1px;
  right: 1px;
}

@media (min-width: 575px) {
  .searchDropdownOffset {
    margin-top: $spacing-14 !important;
  }
}
