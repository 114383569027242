@import '~styles/_theme';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $white;
  align-items: center;

  .headerContainer {
    width: 100%;
    background-color: $nb-warm-grey-200;
    display: flex;
    justify-content: center;

    &.inModal {
      border-top-right-radius: $rounded-lg;
      border-top-left-radius: $rounded-lg;
    }

    .headerImage {
      margin: $spacing-11 auto;
    }
  }

  .childrenContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: $spacing-16;

    &.inModal {
      height: fit-content;
      padding: $spacing-6 $spacing-10;
    }
  }

  .buttonGroup {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.inModal {
      align-self: flex-end;
      justify-content: flex-end;
      padding: $spacing-10;
      padding-top: $spacing-0;
    }
  }
}

.container.inModal {
  border-radius: $rounded-lg;

  .headerContainer {
    border-top-right-radius: $rounded-lg;
    border-top-left-radius: $rounded-lg;
  }

  .childrenContainer {
    height: fit-content;
    padding: $spacing-6 $spacing-10;
  }

  .buttonGroup {
    align-self: flex-end;
    justify-content: flex-end;
    padding: $spacing-10;
    padding-top: $spacing-0;

    .leftBtn {
      margin-right: $spacing-3;
    }
  }
}

@include mobile {
  .container,
  .container.inModal {
    position: relative;

    .headerContainer {
      border-radius: $rounded-none;
    }

    .childrenContainer {
      padding: $spacing-6;
      padding-bottom: $spacing-35;
    }

    .buttonGroup {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: $spacing-4;
      flex-direction: column;
      border-top: $nb-border-light-grey-1px;
      background-color: $white;

      button {
        width: 100%;

        &.leftBtn {
          margin-right: $spacing-0;
          margin-top: $spacing-2;
          order: 2;
        }
      }
    }
  }
}

@include tablet {
  .container:not(.inModal) {
    .buttonGroup {
      max-width: $spacing-96;
    }
  }
}
