@import '~styles/_theme';

.logo,
.logoImage {
  width: $spacing-16;
  height: $spacing-16;

  @include mobile {
    width: $spacing-22;
    height: $spacing-22;
    margin-top: -$spacing-9;
  }
}

.logoImage {
  width: $spacing-32;
  height: auto;
}
