@import '~styles/_theme';

:global {
  :local(.toaster) {
    max-width: $spacing-96;
    width: 100%;

    &.bp3-toast {
      box-shadow: $shadow-md;
      border-radius: $rounded-base;
      border: $nb-border-grey-1px;
      background: $white;
      margin-top: $spacing-2;

      &:first-child {
        margin-top: $spacing-6;

        @include mobile {
          max-width: auto;
          margin-top: $spacing-5;
        }
      }

      &:focus {
        outline: none;
      }

      .bp3-button-group.bp3-minimal {
        box-shadow: none;

        .bp3-button {
          top: $spacing-2;
          right: $spacing-2;
          box-shadow: none;

          svg {
            opacity: 0;
          }
        }
      }

      .bp3-toast-message {
        padding: $spacing-4 $spacing-13;
        padding-right: $spacing-3;
      }

      // stylelint-disable-next-line no-descending-specificity
      > svg:first-child {
        position: absolute;
        left: $spacing-5;
        top: $spacing-4;
      }

      h4.bp3-heading {
        @include setPrint($text-sm, $nb-grey-900, $font-medium);

        margin-bottom: $spacing-2;
      }

      .close {
        position: absolute;
        right: $spacing-5;
        top: $spacing-4;

      }

      .toastContent {
        a {
          @include setPrint($text-sm, $nb-red-700, $font-medium);
          text-decoration: underline;
        }

        @include setPrint($text-sm, $nb-grey-700, $font-normal);
      }
  
    }
  }
}
