@import 'styles/theme';

body {
  background-color: $nb-warm-grey-200;

  @include nb-paragraph;

  :focus {
    outline: none;
  }
}

a {
  color: $nb-grey-900;
}

.dialog-form {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.authHeader {
  margin: $spacing-12;
}

.small-container {
  &.two-cols {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &.fitAll {
    justify-content: stretch;

    > div:first-child {
      flex: 1;
      margin-right: $spacing-3;
    }
  }

  &.align-left {
    justify-content: flex-start;

    // stylelint-disable-next-line no-descending-specificity
    > div {
      margin-right: $spacing-5;
      display: inline-flex;
    }
  }
}

.text-center {
  text-align: center;
}

.card-list {
  margin-top: $spacing-3;
  list-style: none;
  padding: 0;
  display: inline-block;
  text-align: center;

  li {
    display: inline-block;
    background-image: url('./assets/images/card-brands.svg');
    height: 25px;
    margin: $spacing-2 $spacing-5 $spacing-2 0;
    overflow: hidden;
    text-indent: -500px;

    &.cardBrand-visa {
      width: 44px;
    }

    &.cardBrand-unionpay {
      background-position: -908px 0;
      width: 32px;
    }

    &.cardBrand-masterCard {
      background-position: -48px 0;
      width: 84px;
    }

    &.cardBrand-americanExpress {
      background-position: -138px 0;
      width: 52px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.main-footer {
  ul {
    text-align: center;
  }

  padding: $spacing-3;
  align-self: flex-end;
  width: 100%;
}

.StripeElement,
.submit-card-form input {
  background-color: $white;
  height: 40px;
  padding: $spacing-3 $spacing-4;
  border-radius: $rounded-sm;
  border: $border-1 solid transparent;
  box-shadow: $shadow-base;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  width: 100%;
}

.StripeElement--focus {
  box-shadow: $shadow-base;
}

.StripeElement--invalid {
  border-color: #FA755A;
}

.StripeElement--webkit-autofill {
  background-color: #FEFDE5 !important;
}

.padding {
  &.bottom-12 {
    padding-bottom: $spacing-3;
  }

  &.zero {
    padding: 0;
  }

  &.padding-20 {
    padding: $spacing-5;
  }
}

.inlineForm {
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: center;

  .bp3-form-group.bp3-inline {
    align-items: center;
    flex-direction: row;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .bp3-label {
    flex: 35% 0 0;
    color: $nb-grey-900;
    line-height: 38px;
    text-align: right;
  }

  .bp3-form-content {
    align-items: flex-start;
    display: flex;
    flex: 0 0 55%;
    flex-direction: column;
    max-width: 350px;
    padding-left: 5px;

    // stylelint-disable-next-line no-descending-specificity
    > div,
    select,
    input {
      width: 100%;
    }
  }
}

.align-center {
  text-align: center;
}

.bp3-card {
  &.activated {
    box-shadow: $nb-shadow-outline;
    cursor: pointer;
  }
}

.bp3-dialog {
  padding-bottom: $spacing-8;

  &.sizeLarge {
    width: 800px;
  }

  &.sizeMedium {
    width: 630px;
  }
}

.main-logo {
  font-weight: bold;
  color: $white;

  img {
    width: 80px;
  }
}

.normalCard {
  padding: 0;
  min-width: 650px;
  max-width: 700px;
  background: #FFF;
  width: 50%;

  .bp3-tab-list {
    padding: $spacing-3 $spacing-5;
  }

  @media (max-width: 575px) {
    min-width: 0;
    width: 90vw;
  }
}

// stylelint-disable-next-line no-descending-specificity
.cardHeader {
  border-bottom: $nb-border-light-grey-1px;
  padding: $spacing-3 $spacing-5;

  // stylelint-disable-next-line no-descending-specificity
  h2 {
    padding: 0;
    margin: 0;
  }
}

.cardContent {
  padding: $spacing-3 $spacing-5;
}

.cardFooter {
  padding: $spacing-3 $spacing-5;

  button {
    margin-left: $spacing-3;
  }
}

.uppercase {
  text-transform: uppercase;
}

.popOverTooltip {
  box-shadow: none;

  .bp3-popover-content,
  .bp3-popover2-content {
    background: $nb-white;
    color: $nb-grey-600;
    box-shadow: $shadow-lg;
    border-radius: $rounded-sm;
  }

  .bp3-tooltip {
    box-shadow: none;
  }

  .bp3-popover2-arrow {
    width: 30px;
  }

  .bp3-popover-arrow-border,
  .bp3-popover2-arrow-border {
    fill: $white;
  }

  .bp3-popover2-arrow-border {
    stroke: $nb-grey-300;
  }

  .bp3-popover-arrow-fill,
  .bp3-popover2-arrow-fill {
    fill: $white;
  }
}
