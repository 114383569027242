@import '~styles/_theme';

.filterListWrapper {
  display: flex;
  justify-content: space-between;
  background: $nb-warm-grey-100;
  box-shadow: $shadow-sm;
  padding: $spacing-4 $spacing-6;
  list-style: none;
  margin-bottom: $spacing-4;

  @include mobile {
    padding-left: $spacing-4;
    padding-right: $spacing-4;
    flex-direction: column;
  }

  .filterList {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$spacing-px-6;
    margin-right: -$spacing-px-6;

    @include mobile {
      margin-bottom: -$spacing-2;
    }
  }

  .option,
  .selectedOption {
    display: flex;
    align-items: center;
    margin: $spacing-0 $spacing-px-6;
    padding: $spacing-px-6 $spacing-3;
    border: $nb-border-grey-1px;
    border-radius: $rounded-base;

    @include setPrint($nb-text-sm, $nb-grey-800);

    svg {
      margin-left: $spacing-2;
    }

    @include mobile {
      margin-bottom: $spacing-2;
    }
  }

  .option {
    background: $nb-white;
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }

  .selectedOption {
    background: $nb-warm-grey-200;

    svg {
      cursor: pointer;
      margin-top: -$spacing-px;
    }
  }

  .editSection {
    @include mobile {
      display: flex;
      justify-content: space-between;
      margin-top: $spacing-4;
    }

    .editFilterBtn {
      display: none;
      padding-left: $spacing-0;

      @include mobile {
        display: flex;
      }
    }

    &.withActiveFilter {
      @include mobile {
        border-top: $nb-border-warm-grey-1px;
      }

      .clearAllBtn {
        padding-right: $spacing-0;
      }
    }

    &:not(.withActiveFilter) {
      @include mobile {
        margin-top: $spacing-0;
        margin-bottom: -$spacing-2;
      }

      .editFilterBtn {
        width: 100%;
      }

      .clearAllBtn {
        display: none;
      }
    }
  }

  .mobileFilterBtn {
    padding: $spacing-0;
    margin: $spacing-0;
    height: unset;

    span > li {
        margin-bottom: unset;
      }
  }
}
