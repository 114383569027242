@import '~styles/_theme';

.balanceHeading {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > p {
    margin-right: $spacing-6;
  }

  .switch {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    @include setPrint($text-sm, $nb-grey-900, $font-medium);

    span {
      height: 20px !important;
    }

    input:checked ~ span {
      border-radius: $rounded-2xl !important;
    }
  }
}

.dialogContainer {
  width: $spacing-120;
  box-shadow: $shadow-xl;
  background-color: $white;
  border-radius: $rounded-lg;
  padding: 0;

  > div:first-of-type {
    min-height: $spacing-6 !important;
    padding: $spacing-6 $spacing-6 0 $spacing-6 !important;
    box-shadow: none;

    h4 {
      margin: 0 0 $spacing-2 0 !important;
    }
  }
}

.dialogHeader {
  display: flex;
  justify-content: space-between;

  @include setPrint($text-xl, $nb-grey-1000, $font-semibold);

  svg {
    color: $nb-svg-grey;
    stroke-width: 2px;

    &:hover {
      cursor: pointer;
    }
  }
}

.contentContainer {
  padding: 0 $spacing-6 $spacing-6 $spacing-6;
}

.drawer {
  padding-top: $spacing-6;
  border-top-left-radius: $rounded-2xl;
  border-top-right-radius: $rounded-2xl;

  .dialogHeader {
    align-items: baseline;
  }


  .xBtn {
    border-radius: $rounded-full;
    background-color: $nb-grey-100;
    padding: $spacing-1;
    width: $spacing-7;
    height: $spacing-7;
  }
}

.header {
  @include setPrint($text-lg, $nb-grey-900, $font-semibold);
  @include textOverflow;

  max-width: $spacing-32;
}

.mobileInfoPane {
  display: none;
}

.disabled {
  opacity: 0.5;
}

.textBlock {
  overflow: hidden;
}

.cardContainer {
  display: flex;
  padding-top: $spacing-3;
  padding-bottom: $spacing-3;

  .balanceCard {
    width: 100%;

    .innerCard {
      background-color: $white;
      box-shadow: $shadow-base;
      padding: $spacing-3;
      border-radius: $rounded-lg;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
    }

    .badge {
      display: flex;
      overflow: hidden;
    }

    &:first-child {
      margin-right: $spacing-6;
    }

    &:last-child {
      margin-left: $spacing-6;
    }

    .leftImg {
      background-color: $nb-warm-grey-200; //fallback color
      border-radius: $rounded-lg;
      padding: $spacing-3;
      width: $spacing-12;
      height: $spacing-12;
      width: $spacing-12;
      margin: $spacing-0;
      margin-right: $spacing-4;
      display: flex;
      justify-content: center;
      align-items: center;

      // stylelint-disable-next-line no-descending-specificity
      svg {
        color: $nb-svg-grey; //fallback color
        font-size: 2rem;
      }

      &.highlight {
        background: $nb-accent-primary;

        svg {
          color: $white;
        }
      }
      &.violet {
        background: $nb-violet-200;

        svg {
          color: $nb-violet-600;
        }
      }
      &.rose {
        background: linear-gradient(252.43deg, #FFF3ED 0%, #FFF1F2 50.98%, #FFF0F7 100%);

        svg {
          color: $nb-rose-600;
        }
      }
    }

    .cardHeading {
      @include setPrint($text-sm, $nb-grey-700, $font-medium);
      @include textOverflow;
    }

    .amount {
      @include setPrint($text-lg, $nb-grey-900, $font-semibold);
      @include textOverflow;
    }

    .amountSymbol {
      @include setPrint($text-lg, $nb-grey-700, $font-semibold);
    }

    .amountCurrency {
      @include setPrint($text-base, $nb-grey-700, $font-semibold);

      text-transform: uppercase;
    }
  }
}

// tablet and mobile view are now the same
@include tablet {
  .leftImg {
    display: none;
  }

  .balanceCard {
    .additionalInfoPane {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &.hideOnMobile {
      display: none;
    }

    &:first-child,
    &:last-child {
      margin: 0 !important;
    }
  }

  .mobileInfoPane {
    background-color: $nb-grey-200;
    box-shadow: $shadow-base;
    padding: $spacing-3;
    display: flex;
    justify-content: space-between;
    border-bottom-right-radius: $rounded-lg;
    border-bottom-left-radius: $rounded-lg;
    overflow: hidden;

    div:last-child > p {
      text-align: right;
    }
  }
}
