@import '~styles/_spacing';
@import '~styles/_typography';
@import '~styles/_theme';

.container {
  width: 285px;
  min-height: 186px;
  padding: $spacing-2;
}

.card {
  box-shadow: $shadow-md;
  border-radius: $rounded-2xl;
  padding: $spacing-6;
  padding-top: $spacing-16;
  line-height: $leading-normal;
}

.title {
  font-size: $text-lg;
  width: 165px;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: $spacing-4;

  .description {
    font-size: $text-base;
    width: 132px;
  }

  .reminder {
    width: 94px;
    margin-top: $spacing-1;
    font-size: $text-sm;
  }

  .holder {
    width: 48px;
    height: 40px;
  }
}
