// New branding typography

// Font Size
$nb-text-xs: 0.75rem; // 12px, $leading-normal
$nb-text-sm: 0.875rem; // 14px, $leading-normal
$nb-text-base: 1rem; // 16px, $leading-normal
$nb-text-lg: 1.125rem; // 18px, $leading-normal
$nb-text-xl: 1.25rem; // 20px, $leading-snug
$nb-text-2xl: 1.5rem; // 24px, $leading-tight, $tracking-tight
$nb-text-3xl: 1.75rem; // 28px, $leading-tighter, $tracking-tight
$nb-text-4xl: 2rem; // 32px, $leading-tight, $tracking-tight
$nb-text-5xl: 3rem; // 48px, $leading-tightest, $tracking-tighter
$nb-text-6xl: 4rem; // 64px, $leading-none, $tracking-tighter
$nb-text-7xl: 5rem; // 80px, $leading-none, $tracking-tightest
$nb-text-7xl: 6.25rem; // 100px, $leading-none, $tracking-tightest

// Font Weight
$nb-font-regular: 400;
$nb-font-medium: 500;
$nb-font-semibold: 600;
$nb-font-bold: 700;

// Mixins for typography styles
$nb-orbikular-font: 'Orbikular', -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
$nb-aeonik-font: 'Aeonik Pro', -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
