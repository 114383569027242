/* stylelint-disable no-descending-specificity */
@import '~styles/_theme';

.attention {
  color: $white;
  font-size: $text-xs;
  line-height: 12px;
  width: 12px;
  height: 12px;
  border: 1px solid $white;
  border-radius: 50%;
  padding-left: 3px;
  margin-left: 5px;
}

.collectPage .titleContainer h1.title {
  display: inline;
  margin-right: $spacing-3 !important;
}

.reapCollectLinkBtnContainer {
  display: inline-flex;
  margin-right: auto;
}

.container {
  .paymentRecord {
    margin-bottom: 15px;
    position: relative;
    border: $border-2 solid $white;
    box-shadow: $shadow-md;
    border-radius: $rounded-sm;
  }

  .expireCallout {
    background: $nb-purple-500;
    color: $white;
    font-size: $text-base;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    margin-top: -10px;

    .expireCalloutMsg {
      margin-left: 8px;
    }
  }

  .paymentListContainer {
    display: block;

    .tabsContainer {
      width: 100%;

      > div:first-child {
        background: $nb-grey-100;
        overflow-x: auto;

        .tab:first-of-type {
          margin-left: 30px;
        }
      }

      .tab {
        height: 62px;
        font-size: $text-base;
        line-height: 62px;
        vertical-align: middle;
        padding: 0 11px;

        &:focus {
          outline: none;
        }

        &[aria-expanded='false'] {
          color: $nb-grey-300;
          margin-right: 29px;
        }

        &[aria-expanded='true'] {
          color: $nb-red-500;
          box-shadow: inset 0 -3px 0 $nb-red-500;
          margin-right: 29px;
        }
      }

      .tabPanel {
        padding: 0;
        padding-top: 30px;
        margin-top: 0;
      }
    }
  }

  .signupContainer {
    max-width: 740px;
    margin: 0 auto;
  }

  .supplementForm {
    margin-bottom: $spacing-6;

    .completeAccountBtn {
      padding: $spacing-0;
      min-height: max-content;
      vertical-align: bottom;

      &:hover {
        background: transparent;
      }
    }
  }
}

@media (max-width: 900px) {
  .container {
    .expireCallout {
      margin-top: 0;
      font-size: $text-xs;
      line-height: 16px;
    }

    .paymentListContainer {
      display: block;

      .tabsContainer {
        width: calc(100% + 40px);
        margin-left: -20px;

        > div:first-child {
          .tab:first-of-type {
            margin-left: 20px;
          }
        }

        .tab {
          height: 40px;
          font-size: $text-sm;
          line-height: 40px;
        }

        .tabPanel {
          padding: 15px 20px;
        }
      }
    }
  }

  .hide {
    display: none !important;
  }
}

:global {
  .bp3-overlay.bp3-overlay-container.bp3-overlay-inline {
    width: 0;
    height: 0;
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  box-shadow: $nb-inner-1px-bottom-warm-gray-300;
  margin-top: $spacing-3;
  margin-bottom: $spacing-4;

  @include mobile {
    margin-top: -$spacing-4;
    margin-bottom: $spacing-2;
  }

  .tab {
    display: flex;
    align-items: center;
    padding: 18px $spacing-1;
    font-weight: $nb-font-medium;
    font-size: $nb-text-sm;
    line-height: 20px;

    .tabBadge {
      min-width: 24px;
      height: 20px;
      line-height: 20px !important;
      padding-top: $spacing-0;
      margin-left: $spacing-3;
      text-align: center;

      &.active {
        background-color: $nb-red-300;
        color: $nb-red-900;
      }
    }
  }

  :global(.bp3-tab-list) {
    width: 100%;
  }

  :global(.bp3-tab-list > *:not(:last-child)) {
    margin-right: $spacing-8;

    @include mediumDevice {
      margin-right: $spacing-4;
    }
  }

  :global(.bp3-tab-list > div:nth-child(2)) {
    // skip the tab indicator element, hide the badge for first tab for mobile
    .tabBadge {
      @include mobile {
        display: none;
      }
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacing-2;

  @include tablet {
    justify-content: space-between;
  }

  .checkbox {
    margin: 0;
  }

  .buttonGroup {
    margin-left: $spacing-2;

    > * {
      margin-left: $spacing-3;
    }
  }

  .smallButton {
    padding-top: $spacing-2;
    padding-bottom: $spacing-2;
    font-size: $nb-text-sm;
    box-shadow: $nb-shadow-sm !important;
    background: $white;
    border: $border-1 solid $nb-grey-400;
    border-radius: $rounded-lg;
    min-height: auto;
    height: 32px;

    &:disabled {
      background: $white;
    }

    svg {
      color: $nb-grey-600;
      stroke: 2px;
    }

    @include mobile {
      padding-left: $spacing-3;
      padding-right: $spacing-3;
    }
  }

  .filterButton {
    background-color: $white !important;
  }
}

.menuRed {
  color: $nb-red-800 !important;

  svg {
    color: $nb-red-800 !important;
  }
}

.recurringItemMenu {
  box-shadow: $nb-shadow-lg;
  padding: 0 !important;

  @include mobile {
    margin-top: $spacing-3;
    box-shadow: none !important;
  }

  li {
    padding: 10px !important;
    border-radius: $rounded-lg;
  }

  > div {
    padding: 6px;
  }

  > div:last-child {
    margin-top: 6px;
    border-top: $nb-border-light-grey-1px;

    @include mobile {
      border-top: none !important;
    }
  }

  > div:only-child {
    border-top: 0;
  }

  > div:not(:last-child) {
    padding-bottom: 0;

    @include mobile {
      border-bottom: none !important;
    }
  }
}

.menuItem {
  display: flex;
  align-items: center;
  color: $nb-grey-800;

  @include setPrint($nb-text-sm, $nb-grey-800, $nb-font-regular);

  @include mobile {
    padding: $spacing-2 $spacing-5;

    @include setPrint($nb-text-base, $nb-grey-800, $nb-font-regular);
  }

  svg {
    color: $nb-svg-grey;
    margin-right: $spacing-4;
  }
}

.drawer {
  border-top-left-radius: $rounded-lg;
  border-top-right-radius: $rounded-lg;
}

.deletePopup {
  padding: $spacing-4;
  background-color: $white;

  .buttonContainer {
    margin-top: $spacing-10;
    display: flex;

    button {
      width: 100%;
    }
  }
}

.exportDialog {
  :global(.bp3-dialog-header) {
    padding: $nb-spacing-8;
    padding-bottom: $nb-spacing-0;
  }

  .exportDialogContent {
    margin: $nb-spacing-0 $nb-spacing-8;
    margin-top: $nb-spacing-2;

    .exportDialogDesc {
      @include nb-paragraph();
    }

    .exportSelectionContent {
      @include nb-h5($nb-grey-800);

      margin-top: $nb-spacing-4;

      .exportDialogRadioGroup {
        label {
          @include nb-paragraph($nb-grey-600);

          padding-top: $nb-spacing-0;
          padding-bottom: $nb-spacing-0;
        }

        margin-top: $nb-spacing-4;
        display: flex;
        flex-direction: row;

        label.selectedRadio {
          @include nb-paragraph($nb-grey-800);
        }

        :global(.bp3-control-indicator) {
          &::before {
            transform: translate(-1px, -1px);
          }
        }
      }
    }
  }

  .exportDialogBtnGroup {
    margin-top: $nb-spacing-6;
    margin-right: $nb-spacing-8;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button:nth-of-type(2) {
      margin-left: $nb-spacing-2;
    }
  }
}

@include mobile {
  .exportDialog {
    :global(.bp3-dialog-header) {
      padding: $nb-spacing-5 $nb-spacing-4;
      box-shadow: $nb-inner-1px-bottom-warm-gray-300;

      :global(.bp3-heading) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .exportDialogContent {
      padding: $nb-spacing-5 $nb-spacing-4;
      margin: $nb-spacing-0;

      .exportDialogRadioGroup {
        label {
          margin-top: $nb-spacing-0;
        }
      }
    }

    .exportDialogBtnGroup {
      margin-top: $nb-spacing-0;
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      box-shadow: $nb-inner-1px-top-warm-gray-300;
      width: 100%;
      padding: $nb-spacing-4;

      button {
        width: 100%;
      }

      button:nth-of-type(2) {
        order: -1;
        margin-left: $nb-spacing-0;
      }

      button:nth-of-type(1) {
        margin-top: $nb-spacing-3;
      }
    }
  }
}
