@import "~styles/_theme";

.brandingFooter {
  > img {
    display: block;
    margin: 0 auto;
  }
}

.securityContainer {
  @include setPrint($text-xs, $nb-grey-600, $nb-font-regular);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: $spacing-6 0;

  .shieldContainer {
    display: flex;
    flex-direction: row;

    .securityDesc {
      margin: 0 $spacing-3;
    }
  }

  .pciDSSImg {
    height: 30px;
    opacity: 0.6;
  }

  @include mobile {
    & {
      padding: 0 10px;

      .shieldContainer {
        .securityDesc {
          font-size: 10px;
        }
      }
    }
  }
}
