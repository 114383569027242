@import "~styles/_theme";

.inputContainer {
  height: $spacing-11;
}

.checkIcon,
.verifyBtn {
  position: relative;
  height: 34px;
  bottom: 39px;
  left: 100%;
}

.checkIcon {
  color: $nb-green-700;
  transform: translateX(calc(-100% - #{$spacing-4}));
}

.verifyBtn {
  @include fontStyles($text-sm);

  color: $nb-grey-800 !important;
  border-left: $nb-border-grey-1px;
  background: $white;
  border-radius: $rounded-none;
  padding: $spacing-0 $spacing-4;
  transform: translateX(calc(-100% - 2px));

  &:hover {
    background: $white !important;
  }
}

.phoneInputContainer {
  height: $spacing-11 !important;
  width: 100%;
}

.error {
  border: $border-1 solid $nb-red-600 !important;

  &:focus {
    border-color: transparent !important;
  }
}

.warning {
  border: $border-1 solid $nb-yellow-600 !important;

  &:focus {
    border-color: transparent !important;
  }
}

.dialog {
  border-radius: $rounded-none;
  position: absolute;
  width: 100vw !important;
  height: 100%;
  background-color: $nb-white;
  margin: $spacing-0;
  overflow: auto;

  > div:first-child {
    flex-direction: column-reverse;
    padding: $spacing-6 $spacing-6 $spacing-0 $spacing-6;
    border-radius: $rounded-none;
    box-shadow: $shadow-none;

    h4 {
      margin-top: $spacing-8;

      @include nb-dialog-title;
    }

    button[aria-label='Close'] {
      padding: $spacing-0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      left: calc(50% - #{$spacing-4});

      span {
        margin: $spacing-0 !important;

        svg {
          stroke: $nb-svg-grey;
          stroke-width: 0;
          width: $spacing-6;
          height: $spacing-6;
        }
      }
    }
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .contentBlock {
    display: flex;
    flex-direction: column;
    width: 384px;

    @include mobile {
      width: unset;
      max-width: 384px;
    }

    header {
      margin-top: $spacing-4;
      padding: $spacing-0 $spacing-11;
      text-align: center;

      @include nb-paragraph;

      strong {
        @include setPrint($nb-text-sm, $nb-red-700);
      }
    }

    header + img {
      margin: $spacing-8 $spacing-0;
    }
  }
}

.verificationForm {
  margin: $spacing-0 $spacing-5;

  .phoneField {
    margin-bottom: $spacing-2 !important;
  }

  .fieldHelperText {
    margin-top: -$spacing-1;

    @include setPrint($text-sm, $nb-grey-600, $nb-font-regular);

    // stylelint-disable-next-line no-descending-specificity
    strong {
      @include setPrint($text-sm, $nb-grey-600, $nb-font-regular);
    }
  }

  a {
    @include setPrint($text-sm, $nb-red-700, $font-medium);

    padding: 0;
    display: inline;
    min-height: auto;
    vertical-align: inherit;

    &:hover {
      @include setPrint($text-sm, $nb-red-600, $font-medium);

      background: none;
      padding: 0;
      text-decoration: underline;
    }
  }

  .waOptions {
    display: flex;
    flex-direction: column;
    text-align: center;

    .sendOptionsHeader {
      @include setPrint($text-sm, $nb-grey-700, $nb-font-medium);
    }

    .waModule {
      margin: $spacing-5 0;
    }
  }

  button.btnLoading:global(.bp3-loading) {
    &,
    &:hover {
      background: rgba($nb-red-600, 0.75) !important;
    }

    div:first-child {
      position: relative;
      left: -$spacing-2;
      right: 0;

      svg {
        stroke-width: 8px;

        :global(.bp3-spinner-track) {
          stroke: $nb-white;
          opacity: 0.5;
        }
      }
    }

    &.loadInverted {
      background: $white !important;

      div:first-child {
        position: relative;
        left: -$spacing-2;
        right: 0;
        filter: brightness(0.8);
      }
    }

    // stylelint-disable-next-line no-descending-specificity
    span {
      visibility: visible;
    }
  }

  button {
    margin-bottom: $spacing-4;
    text-align: center;
    padding: $spacing-3 $spacing-6;
    border-radius: $rounded-base;

    &:disabled {
      opacity: 0.75;
    }

    &.secondaryOutlineBtn {
      background: $white !important;
    }

    &.btnText {
      padding: 0;

      &:hover {
        box-shadow: none;
        text-decoration: none;
        border-bottom: $nb-red-600;
      }
    }

    &.btnLink {
      height: 19px;
      min-height: unset;
      align-items: flex-end;
      border: none;
      box-shadow: none;
      background: $white;
      margin-bottom: 0;
      text-align: center;
      padding: 0;
      border-radius: $rounded-none;

      @include setPrint($text-sm, $nb-anchor-primary, $nb-font-medium);

      &:hover {
        box-shadow: none;
        text-decoration: underline;
      }
    }
  }

  p {
    @include setPrint($text-sm, $nb-grey-700, $font-medium);

    margin-bottom: $spacing-6;
  }

  .sendOptions {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: $spacing-6;
  }

  .codeSendOptions {
    @include mobile {
      align-items: center;
      width: 100%;
    }
  }
}

.boxedInputContainer {
  margin: $spacing-8 0 $spacing-3 0;

  .label {
    @include setPrint($nb-text-sm, $nb-grey-800, $nb-font-regular);
  }
}

.boxedInput {
  display: flex;
  justify-content: center;


  // stylelint-disable-next-line no-descending-specificity
  > div + div {
    margin: 0 0 0 $spacing-3;
  }

  .error input {
    border: $border-1 solid $nb-red-600 !important;
  }

  // stylelint-disable-next-line no-descending-specificity
  input {
    background: $white !important;

    @include setPrint($text-base, $nb-grey-900, $font-medium);

    border: $nb-border-grey-1px;
    box-sizing: border-box;
    border-radius: $rounded-base;
    padding: $spacing-2;
    text-align: center;
    max-width: $spacing-11;
    width: $spacing-11 !important;
    box-shadow: $shadow-none;
  }
}
