@import '~styles/_theme';

.dialogContainer {
  width: $spacing-128;
  min-width: $spacing-120;
  box-shadow: $shadow-2xl;
  background-color: $white;
  border-radius: $rounded-lg;
  padding: $spacing-0;

  > div:first-of-type {
    min-height: $spacing-6 !important;
    padding: $spacing-6 $spacing-6 $spacing-0 $spacing-0 !important;
    box-shadow: none;

    h4 {
      margin: $spacing-0 !important;
      padding: $spacing-0 !important;
    }
  }

  .mobileHeader,
  .whiteMobileHeader,
  .desktopHeader,
  .mobileHeader .backContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .backContainer:hover {
    cursor: pointer;
  }

  .desktopHeader {
    justify-content: flex-end;
    padding-left: $spacing-8;

    .customTitle {
      margin-right: auto;
    }

    svg {
      color: $nb-svg-grey;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .mobileHeader,
  .whiteMobileHeader,
  .actionSheetCloseIconContainer {
    display: none;
  }

  .contentContainer {
    background-color: $white;
    padding: $spacing-0 $spacing-12 $spacing-12;
    border-radius: $rounded-lg;
  }
}

.backdrop {
  background: $nb-backdrop-color;
}

@media (max-width: 575px) {
  .dialogContainer {
    height: 100%;
    min-height: 100vh;
    margin: 0;
    min-width: 100vw;
    width: 100%;
    border-radius: $rounded-none;

    > div:first-of-type {
      padding: $spacing-0 !important;
    }

    .desktopHeader {
      display: none;
    }

    .mobileHeader {
      display: flex;
      justify-content: flex-start;
      background-color: $nb-red-600;
      color: $nb-grey-200;

      @include fontStyles($text-sm);

      padding-left: $spacing-5;
      height: $spacing-16;

      .customTitle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include setPrint($nb-text-lg, $nb-white, $nb-font-semibold);
      }
    }

    .whiteMobileHeader {
      display: flex;
      justify-content: flex-start;
      background-color: $white;
      color: $light-violet-1000;
      
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        > svg {
          color: $light-violet-1000;
          margin-right: $spacing-2;
        }
      }

      padding-left: $spacing-5;
      height: $spacing-16;

      .customTitle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include setPrint($nb-text-lg, $nb-white, $nb-font-semibold);
      }
    }

    .contentContainer {
      padding: $spacing-6 $spacing-5;
    }
  }

  .actionSheetDialog {
    box-shadow: $shadow-2xl;
    position: fixed;
    bottom: -50%;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    max-height: 50% !important;
    height: fit-content !important;

    .actionSheetCloseIconContainer {
      background-color: $nb-grey-100;
      width: $spacing-8;
      height: $spacing-8;
      border-radius: $rounded-full;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: $nb-svg-grey;
      }
    }

    > div:first-of-type {
      background-color: $white;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      position: fixed;
      right: $spacing-3;
      margin-top: $spacing-3;
    }

    .actionSheetDialogContent {
      padding: $spacing-4 $spacing-6;
    }
  }
}
