@import '~styles/_theme';

.selectFilter {
  margin: 0;

  .label {
    @include setPrint($nb-text-sm, $nb-grey-800, $nb-font-semibold);

    display: block;
    margin-bottom: $spacing-2;
  }

  & :global(.bp3-popover-content) {
    padding: 0;
  }
}
