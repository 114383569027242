@import './colors';

// same as the old shadows
$nb-shadow-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
$nb-shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
$nb-shadow-base: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
$nb-shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$nb-shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$nb-shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
$nb-shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

$nb-shadow-inner: inset 0 2px 4px rgba(0, 0, 0, 0.06);

$nb-shadow-danger: inset 0 0 0 1px $nb-red-700;

$nb-shadow-tooltip: 0 0 0 1px $nb-grey-300;

// new shadows
$nb-inner-1px-top-grey-300: inset 0 1px 0 $nb-grey-300;
$nb-inner-1px-right-gray-300: inset -1px 0 0 $nb-grey-300;
$nb-inner-1px-bottom-gray-300: inset 0 -1px 0 $nb-grey-300;
$nb-inner-1px-left-gray-300: inset 1px 0 0 $nb-grey-300;
$nb-inner-1px-top-gray-400:  inset 0 1px 0 $nb-grey-400;
$nb-inner-1px-right-gray-400: inset -1px 0 0 $nb-grey-400;
$nb-inner-1px-bottom-gray-400: inset 0 -1px 0 $nb-grey-400;
$nb-inner-1px-left-gray-400: inset 1px 0 0 $nb-grey-400;
$nb-inner-1px-top-warm-gray-300: inset 0 1px 0 $nb-warm-grey-300;
$nb-inner-1px-bottom-warm-gray-300: inset 0 -1px 0 $nb-warm-grey-300;

$nb-shadow-outline: 0 0 0 2px $light-violet-700;
$nb-shadow-outline-lg: 0 0 0 10px $light-violet-700;
$nb-shadow-outline-rose: 0 0 0 2px $nb-rose-400;

$nb-border-grey-1px: $border-1 solid $nb-grey-400;
$nb-border-warm-grey-1px: $border-1 solid $nb-warm-grey-300;
$nb-border-light-grey-1px: $border-1 solid $nb-grey-300;
$nb-border-primary-1px: $border-1 solid $nb-button-primary-border-color;

$container-width: 1280px;