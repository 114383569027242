// stylelint-disable no-descending-specificity
@import '~styles/_theme';

.container {
  width: 100%;

  strong {
    font-weight: $font-semibold;
  }

  :global {
    input.bp3-input {
      color: $nb-grey-900 !important;
    }
  }
}

.searchSuggestContainer {
  position: relative;
}

.stickyBottomCreatable {
  position: absolute;
  background-color: $white;
  left: 0;
  right: 0;
  height: $spacing-9;
  bottom: $spacing-1;
}

.resultContainer.resultContainer {
  max-height: $spacing-50;
  width: $spacing-96;
  border-radius: $rounded-base;
  box-shadow: $shadow-lg;
  overflow: hidden;
  margin-top: $spacing-1 !important;

  :global {
    .bp3-popover-content {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      padding: 0;
      border-radius: $rounded-base;
      background: $white;
      position: static;
      padding-bottom: $spacing-10;

      ul.bp3-menu {
        padding: $spacing-1 0;

        li a {
          padding: $spacing-2 $spacing-4;
          border-radius: 0;

          &:hover,
          &.bp3-active {
            background-color: $nb-dark-violet;
            color: $white;

            strong {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.leftAccessory,
.rightAccessory {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: $spacing-4;
  z-index: 999;
  background-color: transparent;
  border: none;
  position: absolute;
  top: $spacing-2;
}

.searchControlContainer {
  position: relative;

  .rightAccessory {
    right: $spacing-2;
    z-index: 2;
  }
}

.placeholder {
  span {
    color: $nb-grey-900;
  }
}

.stickyBottomCreatableMobile {
  height: $spacing-14;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: $spacing-4;

  @include setPrint($text-base, $nb-grey-800, $font-normal);

  cursor: pointer;

  svg {
    color: $nb-dark-violet;
    margin-right: $spacing-5;
  }

  mark {
    font-weight: $font-semibold;
    background-color: transparent;
  }
}

.searchInputContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: $spacing-7;
  flex: 1;
  padding-left: 55px;

  svg {
    width: $spacing-5;
  }

  .searchInput {
    input {
      max-width: $spacing-60 !important;
    }
  }

  .rightAccessory {
    margin-left: auto;
    justify-content: center;
  }
}

.resultItem {
  @include setPrint($text-sm, $nb-grey-800, $font-normal);

  strong {
    font-weight: $font-semibold;
    color: $nb-grey-900;
  }

  height: $spacing-9;
}

.rightIcons {
  left: 50%;
  display: flex;
  color: $nb-svg-grey !important;
  margin-left: auto;

  svg {
    stroke-width: 2;
    color: $nb-svg-grey !important;
  }

  > .successIcon {
    color: $nb-green-700 !important;
    margin-right: $spacing-3;
  }

  > .clearIcon {
    color: $nb-svg-grey;
    background: $nb-grey-100;
    padding: $spacing-1;
    border-radius: $rounded-full;
    width: $spacing-5;
    height: $spacing-5;
  }
}

.triggerButton {
  position: absolute;
  top: $spacing-2;
  right: $spacing-1;

  &:hover {
    background-color: transparent !important;
  }
}

.desktopInput {
  position: relative;

  input {
    padding-left: $spacing-12 !important;
    border: none;
  }

  &.wBorder input {
    border: $nb-border-grey-1px;
  }

  svg {
    position: absolute;
    top: $spacing-3;
    left: $spacing-3;
    z-index: 2;
  }
}

.searchResultItem {
  height: $spacing-14;
  display: flex;
  justify-content: space-between;
  padding: $spacing-4;
  align-items: center;
  cursor: pointer;

  @include setPrint($text-base, $nb-grey-600, $font-normal);

  svg {
    width: $spacing-5;
  }

  .iconAndText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  :global {
    .searchDropdown__option.searchDropdown__option {
      padding: 0 !important;
      padding-left: $spacing-6 !important;

      span {
        color: $nb-grey-600;
      }

      span mark {
        background-color: transparent;
        color: $nb-grey-900;
      }
    }
  }
}

.triggerOpen {
  box-shadow: $nb-shadow-outline !important;
}

$buttonCornerRadius: 6px;

.backdrop {
  color: $nb-backdrop-color;
}

.drawer {
  height: 100%;

  :global(.searchDropdown__control) {
    @include setPrint($text-base, $nb-grey-500);

    background-color: transparent;
    border: none;
    cursor: text;
    margin-bottom: 0;
    padding: $spacing-1 $spacing-0;
    height: $spacing-16;
    box-shadow: inset 0 -1px 0 #E5E5E5;
  }

  :global(.searchDropdown__value-container) {
    padding: 0;
  }

  :global(.searchDropdown__input) {
    @include setPrint($text-base, $nb-input-color);

    padding-left: $spacing-1;
  }

  :global(.searchDropdown__single-value),
  :global(.searchDropdown__placeholder) {
    @include setPrint(
      $text-base,
      $nb-input-placeholder-color,
      $nb-font-regular
    );

    padding-left: $spacing-8;
    margin: unset 0;
    margin-left: $spacing-7;
  }

  :global(.searchDropdown__menu-list) {
    margin: $spacing-1 0;
    padding-top: 0;
    max-height: 100vh;

    @media (max-width: 575px) {
      height: 100vh;
      padding-bottom: 0;
    }
  }

  :global(.searchDropdown__menu) {
    margin: 0;
    border-radius: 0;
    border: none;
    bottom: 0;
    box-shadow: none;
  }

  :global(.searchDropdown__option) {
    padding: $spacing-3 $spacing-6;

    @include setPrint($nb-text-base, $nb-grey-900, $nb-font-regular);
  }

  :global(.searchDropdown__option--is-focused) {
    background: transparent;
    color: $nb-grey-800;
  }

  :global(.searchDropdown__option--is-selected) {
    background: transparent;
    color: $nb-white;
  }

  :global(.bp3-icon.searchIcon) {
    margin-right: 10px;
    transform: translateY(2px);
    vertical-align: baseline;
  }
}

.searchDropdownPopover {
  @include setPrint($text-base, $nb-grey-800);

  > button > div {
    display: flex;
    justify-content: center;
  }

  :global(.searchDropdown__control) {
    @include setPrint($text-base, $nb-grey-500);

    background: $nb-white;
    border-radius: 0;
    border: none;
    cursor: text;
    margin-bottom: 0;
    padding: $spacing-2 $spacing-6;
    height: $spacing-16;
    box-shadow: $nb-shadow-outline;
  }

  :global(.searchDropdown__value-container) {
    padding: 0;
  }

  :global(.searchDropdown__input) {
    @include setPrint($text-base, $nb-input-color);

    padding-left: $spacing-1;
  }

  :global(.searchDropdown__single-value),
  :global(.searchDropdown__placeholder) {
    @include setPrint($text-base, $nb-input-placeholder-color);

    padding-left: $spacing-8;
    margin: unset 0;
  }

  :global(.searchDropdown__menu-list) {
    margin: $spacing-1 0;
    padding-top: 0;
    max-height: 50vh;

    @media (max-width: 575px) {
      height: 50vh;
      padding-bottom: 3rem;
    }
  }

  :global(.searchDropdown__menu) {
    margin: 0;
    border-radius: 0;
    border: none;
  }

  :global(.searchDropdown__option) {
    padding: $spacing-3 $spacing-6;

    @include setPrint($nb-text-base, $nb-grey-900, $nb-font-regular);
  }

  :global(.searchDropdown__option--is-selected) {
    background: $nb-dark-violet;
    color: $nb-white;
  }

  :global(.searchDropdown__option--is-focused) {
    background: $nb-warm-grey-200;
    color: $nb-grey-800;
  }

  :global(.bp3-icon.searchIcon) {
    margin-right: 10px;
    transform: translateY(1px);
    vertical-align: baseline;
  }

  :global(.bp3-button.bp3-minimal) {
    background: $nb-white;
    border-color: $nb-border-light-grey-1px;
    border-radius: $buttonCornerRadius;
    justify-content: flex-start;
    padding: 10px 16px;
    width: 100%;
    box-shadow: $shadow-base;

    &:focus,
    &:hover {
      border-color: transparent;
      outline: 0;
      box-shadow: $nb-shadow-outline;
    }

    :global(.bp3-button-text) {
      border-color: $nb-grey-800;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include setPrint($text-base, $nb-grey-500, $nb-font-regular);
    }

    :global(.bp3-icon),
    svg {
      color: $nb-svg-grey;
    }

    @include mobile {
      &:focus,
      &:hover {
        border-color: transparent;
        outline: 0;
        box-shadow: $shadow-outline;
      }
    }
  }

  :global(.bp3-button.bp3-minimal).placeholder {
    .bp3-button-text {
      @include setPrint($text-base, $nb-grey-900);

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  :global(.bp3-button.bp3-minimal.bp3-active) {
    border-color: $nb-button-primary-border-color;
    outline: 0;
    box-shadow: $shadow-outline;
    color: $nb-grey-800;
  }

  :global(.bp3-button.bp3-disabled) {
    background: $nb-grey-100;
    outline: 0;
    box-shadow: $shadow-none;

    span {
      color: $nb-grey-400 !important;
    }

    &:focus,
    &:hover {
      background: $nb-grey-100;
      border-color: $nb-grey-300;
      outline: 0;
      box-shadow: $shadow-none;
    }
  }

  :global(.bp3-overlay) {
    display: block;
    position: relative;
  }

  :global(.bp3-transition-container) {
    transform: none !important;
    will-change: unset !important;
    width: 100%;

    :global(.bp3-popover) {
      border: none;
      border-radius: 14px 14px 0 0;
      box-shadow: none;
      width: 100%;

      :global(.bp3-popover-content) {
        border-radius: 14px 14px 0 0;
        padding: $spacing-0;
      }
    }
  }
}

.hidden {
  display: none;
}
