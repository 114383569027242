@import '~styles/_theme';

@mixin primaryBtn($backgroundColor) {
  background: $backgroundColor;

  &:hover {
    background: $backgroundColor;
  }

  &:disabled,
  &:disabled:hover {
    background: $backgroundColor;
    opacity: 0.5;
  }

  /* stylelint-disable-next-line  no-descending-specificity */
  &:global(.bp3-loading) {
    background: $nb-grey-200;

    &:hover,
    &:disabled:hover,
    &:global(.bp3-button-spinner):hover {
      background: $nb-grey-200;
    }
  }
}

@mixin nbPrimaryBtn($backgroundColor) {
  background: $backgroundColor;

  &:hover {
    background: $backgroundColor;
  }

  &:disabled,
  &:disabled:hover {
    background: $backgroundColor;
    opacity: 0.5;
  }

  /* stylelint-disable-next-line  no-descending-specificity */
  &:global(.bp3-loading) {
    background: rgba($backgroundColor, 0.75) !important;

    &:hover,
    &:disabled:hover,
    &:global(.bp3-button-spinner):hover {
      background: rgba($backgroundColor, 0.75) !important;
    }

    :global(.bp3-spinner-track) {
      stroke: $nb-grey-600;
    }

    :global(.bp3-spinner-head) {
      stroke: $nb-grey-200;
    }
  }
}

.baseButton {
  font-weight: $nb-font-regular !important;
  border-radius: 6px;
  white-space: pre;

  /* stylelint-disable-next-line  no-descending-specificity */
  &:not(.tertiary):global(.bp3-loading) {
    opacity: 0.5;
  }
}

.small {
  font-size: $text-sm;
  height: 32px;
  padding: 9px 14px;
}

.base {
  font-size: $text-sm;
  height: 38px;
  padding: 5px 16px;
}

.large {
  height: 44px;
  padding: 10px 16px;

  @include mobile {
    height: $nb-spacing-12;
  }
}

.extraLarge {
  height: 56px;
  padding: 16px auto;
}

.fullWidth {
  width: 100%;
}

.blue {
  border-color: $nb-button-primary-border-color;

  span {
    color: $nb-grey-800;
  }
}

.rose {
  border-color: $nb-rose-400;

  span {
    color: $nb-grey-800;
  }
}

.grey {
  span {
    color: $nb-grey-800;
  }
}

.red {
  span {
    color: $nb-red-700;
  }
}

.tertiary {
  background: none !important;
  box-shadow: none !important;

  &.grey {
    span {
      color: $nb-grey-800;
    }
  }

  &.darkViolet {
    span {
      color: $nb-dark-violet;
    }
  }

  &.lightViolet {
    span {
      color: $light-violet-800;
    }

    svg {
      color: $light-violet-800;
    }

    &:global(.bp3-button:not([class*='bp3-intent-']):not([class*='bp3-minimal']):hover) {
      background: $light-violet-100 !important;
    }
  }

  &.red {
    span {
      color: $nb-red-700;
    }

    svg {
      color: $nb-red-700;
    }
  }

  &.rose {
    span {
      color: $nb-rose-700;
    }

    svg {
      color: $nb-rose-700;
    }
  }
}

.primary {
  box-shadow: $shadow-base !important;
  background: $white;

  /* stylelint-disable-next-line  no-descending-specificity */
  span {
    color: $white;
  }

  /* stylelint-disable-next-line  no-descending-specificity */
  svg {
    color: $white;
  }

  :global {
    svg.phosphor {
      stroke: transparent;
      color: $white;
    }
  }

  &.blue {
    @include nbPrimaryBtn($nb-button-primary);

    span {
      color: $nb-white;
    }
  }

  &.grey {
    @include nbPrimaryBtn($nb-grey-600);
  }

  &.cyan {
    @include nbPrimaryBtn($nb-teal-900);
  }

  &.red {
    @include nbPrimaryBtn($nb-red-600);
  }

  &.purple {
    @include nbPrimaryBtn($nb-purple-500);
  }

  &.pinkOrange {
    @include primaryBtn($nb-gradient-pink-700-orange-700);
  }

  &.rose {
    @include nbPrimaryBtn($nb-rose-700);

    span {
      color: $nb-white;
    }
  }

  &.green {
    @include nbPrimaryBtn($nb-green-600);
  }

  &.white {
    @include nbPrimaryBtn($white);

    border: $nb-border-grey-1px;
    box-shadow: $shadow-none !important;

    span {
      color: $nb-dark-violet;
    }

    &:global(.bp3-button:not([class*='bp3-intent-']):active) {
      background: white;
    }
  }

  &.lightViolet {
    &:global(.bp3-button:not([class*='bp3-intent-']):not([class*='bp3-minimal']):hover) {
      background: $light-violet-800 !important;
    }

    @include nbPrimaryBtn($light-violet-900);
  }
}

.secondaryOutline {
  box-shadow: $shadow-sm !important;
  border-width: 1px !important;
  border-style: solid;

  /* stylelint-disable-next-line  no-descending-specificity */
  svg {
    color: $nb-svg-grey;
  }

  &:global(.bp3-button) {
    background-color: white;
  }

  &:global(.bp3-button:not([class*='bp3-intent-']):active) {
    background: white;
  }

  &.grey {
    border-color: $nb-grey-400;

    &:hover, &:global(.bp3-active) {
      background-color: $nb-grey-100;
    }
  }

  &.yellow {
    border-color: $nb-yellow-900;
    color: $nb-yellow-900;
  }

  &.darkViolet {
    border-color: $nb-grey-400;
    color: $nb-dark-violet;
    border-radius: $rounded-lg;
  }
}

.iconOnly {
  padding: $spacing-0;

  &.large.tertiary,
  &.large.tertiary > span {
    height: $spacing-6;
    min-height: $spacing-6;
    min-width: $spacing-6;
  }

  &.base.tertiary,
  &.base.tertiary > span {
    height: $spacing-5;
    min-height: $spacing-5;
    min-width: $spacing-5;
  }

  &.small.tertiary,
  &.small.tertiary > span {
    height: $spacing-4;
    min-height: $spacing-4;
    min-width: $spacing-4;
  }

  &.primary {
    padding: $spacing-3 $spacing-4;
  }

  :global(.bp3-button-text) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.grey {
    color: $nb-svg-grey;
  }
}
