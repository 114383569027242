@import '~styles/_theme';

$buttonCornerRadius: $rounded-sm;
$buttonMargin: $spacing-1;

.sidebarHeader {
  display: flex;
  flex-direction: row;
  padding: $spacing-7 $spacing-3 $spacing-5;
  align-items: center;

  &.collapsed {
    padding-left: $spacing-6;
  }

  @include tablet {
    padding: $spacing-6 $spacing-2 $spacing-4;
  }

  .logo {
    margin-left: $spacing-4;
  }

  .logoLink {
    display: flex;
    background-image: url('~assets/images/nb-logo-new.svg');
    margin-left: $spacing-4;
    background-repeat: no-repeat;
    height: $spacing-4;
    width: $spacing-27;

    &.collapsed {
      width: $spacing-5;
      margin-left: $spacing-0;
    }

    @include tablet {
      margin-left: $spacing-2;
    }
  }

  .closeIcon {
    svg {
      color: $nb-svg-grey;
    }

    &:hover,
    &:active {
      background: none;
      box-shadow: $shadow-none;
    }

    &.collapsed {
      margin-right: $spacing-4;
      margin-left: -$spacing-1;
    }
  }
}

.sidebarWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .navtabs {
    overflow-y: auto;
    padding: $spacing-0 $spacing-3;
    height: 100%;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0;
      background: transparent; /* Chrome/Safari/Webkit */
    }

    > ul {
      &:first-child {
        padding-top: $spacing-0;
      }

      &:not(:last-child)::after {
        content: ' ';
        position: absolute;
        right: $spacing-3;
        bottom: 0;
        left: $spacing-3;
        height: 1px;
        background: $nb-grey-300;
      }
    }
  }

  .sidebarMenuGroup {
    position: relative;
    padding: $spacing-6 $spacing-0;
    list-style: none;
    margin-bottom: $spacing-0;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    &.sidebarLogoGroup {
      margin: $spacing-0;
      padding: $spacing-0;
    }

    li {
      margin-bottom: $spacing-2;

      span {
        @include fontStyles($text-sm);

        .subLabel {
          display: block;
          font-size: $text-xs;
        }
      }

      .linkItem {
        border-radius: $buttonCornerRadius;
        font-weight: bold;
        color: $nb-grey-800;
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        transition: background-color 0.15s linear 0s;
        text-align: left;
        padding: $spacing-3 $spacing-4;

        &:hover {
          background: $nb-warm-grey-200;
        }

        svg {
          color: $nb-svg-grey;
          margin-bottom: 1px;
          margin-right: $spacing-4;
        }
      }

      &.itemActivated {
        background: $nb-warm-grey-200;
        border-radius: $buttonCornerRadius;
        position: relative;

        span {
          color: $nb-grey-900;
        }

        svg {
          color: $nb-button-primary-svg;
        }

        .linkItem:hover {
          background: rgba($nb-grey-200, 0.3);
        }
      }

      &.spendOverviewTab {
        margin-bottom: -$spacing-2;
      }

      &.collapseMenu {
        margin-top: $spacing-4;

        + .collapseMenu {
          margin-top: $spacing-0;

          + .collapseMenu {
            margin-top: $spacing-0;
          }
        }
      }

      .divider {
        border-color: $nb-white;
        margin-top: $spacing-3;
        margin-bottom: $spacing-3;
      }

      &.emptyDivider {
        margin-top: $spacing-7;
      }

      .collapseTrigger {
        position: relative;
        justify-content: flex-start;
        width: 100%;
        background: none;
        height: 45px;

        > :global(.bp3-button-text) {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        &:hover {
          background: none;
        }

        .collapseIcon {
          color: $nb-grey-600;
          transition: all 0.2s ease;
        }

        &.collapsed {
          .collapseIcon {
            transform: rotate(180deg);
          }
        }

        &.active {
          .collapseTriggerContent svg {
            color: $nb-button-primary-svg;
          }
        }
      }

      .collapseTriggerContent {
        display: flex;
        align-items: center;

        // stylelint-disable-next-line no-descending-specificity
        svg {
          color: $nb-grey-600;
          margin-right: $spacing-4;
        }
      }

      .collapseList {
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin-top: $spacing-1;

        li {
          margin-bottom: $spacing-1;

          a {
            padding-left: $spacing-13;
            color: $nb-grey-600;
          }
        }
      }

      .adminDashboardTitle {
        padding: $spacing-3 $spacing-4;
      }
    }
  }

  &.collapsedContainer {
    .navtabs {
      padding: $spacing-0 $spacing-3;
    }

    .sidebarMenuGroup {
      min-width: $spacing-11;

      .sidebarHeader {
        padding: $spacing-3 $spacing-0 $spacing-1 $spacing-0;
        justify-content: center;

        .logoLink {
          margin-left: $spacing-0;
          background-image: url('~assets/svg/reap-symbol-violet.svg');
          background-size: contain;
          width: 24px;
          height: 35px;

          @include tablet {
            display: none;
          }
        }

        .closeIcon {
          margin-right: $spacing-4;
          margin-left: -$spacing-1;

          @include tablet {
            margin-left: $spacing-px-2;
            margin-right: $spacing-0;
          }
        }
      }

      // stylelint-disable-next-line no-descending-specificity
      .linkItem {
        padding: $spacing-3;

        .newBadge {
          display: none;
        }
      }

      .collapseList {
        li a {
          padding: $spacing-3;
        }
      }

      // stylelint-disable-next-line no-descending-specificity
      .collapseTrigger {
        padding: $spacing-3;
      }
    }
  }
}

.collapseMenuBtn {
  border-radius: $buttonCornerRadius;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  transition: background-color 0.15s linear 0s;
  text-align: left;
  padding: $spacing-3 $spacing-0;
  padding-left: $spacing-6;
  margin-bottom: $spacing-4;
  max-height: $spacing-11;
  height: $spacing-11;
  align-items: center;
  line-height: normal;
  margin-top: $spacing-3;

  // stylelint-disable-next-line no-descending-specificity
  span {
    font-weight: $nb-font-medium;
    color: $nb-grey-800;
  }

  &:hover {
    background: $nb-grey-200 !important;
  }

  // stylelint-disable-next-line no-descending-specificity
  svg {
    color: $nb-svg-grey;
    margin-right: $spacing-3;
  }
}

a.menuLink {
  color: inherit;
  margin-bottom: $buttonMargin;
  text-decoration: inherit;
}

.alignBottom {
  margin-top: auto;
}

.alignRight {
  margin-left: auto;
}

.newBadge.newBadge {
  float: right;
  margin-left: auto;
  text-transform: uppercase;
  background-color: transparent !important;
  color: $nb-rose-700 !important;
  border: $border-1 solid $nb-rose-600;
  font-size: $nb-text-xs !important;
}

.categoryLabelWithNotifications {
  display: flex;
  align-items: center;
  > span:first-child {
    margin-right: $spacing-4;
  }
  > span:nth-child(2) {
    display: flex;
    align-items: center;
    span {
      font-size: $nb-text-xs;
    }
  }
}
