@import '~styles/_theme';

.filterTagBar {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    margin-right: $spacing-4;

    @include setPrint($text-sm, $nb-grey-700, $font-semibold);
  }

  .tag {
    margin-right: $spacing-2;

    &:last-of-type {
      margin-right: 0;
    }

    @include mobile {
      margin-top: $spacing-2;
    }
  }

  .clearBtn :global(.bp3-button-text) {
    @include setPrint($text-sm, $nb-grey-800);
  }
}
