@import '~styles/_theme';


.filterTab {
  margin-bottom: $spacing-6;
  border-bottom: $border-1 solid $nb-warm-grey-300;

  :global(.bp3-tab) {
    padding-bottom: $spacing-3;
    padding-left: $spacing-1;
    padding-right: $spacing-1;
  }

  @include mobile {
    margin-bottom: $spacing-5;
  }
}
