@import '~styles/_theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    @include nb-h2;

    margin: $spacing-0;
    margin-top: $spacing-6;
    margin-bottom: $spacing-4;
  }

  .subHeader {
    @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);

    margin-bottom: $spacing-4;
    max-width: $spacing-96;

    .highlight {
      color: $nb-red-700;
    }
  }

  .storesGroup {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacing-6;

    img {
      &:hover {
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-right: $spacing-3;
      }
    }
  }

  .qrCodeContainer {
    width: $spacing-40;
    height: $spacing-40;
    background-color: $black;

    .qrCode {
      width: 100%;
      height: 100%;
    }
  }

  .codeDisplayInput {
    width: 100%;
    max-width: $spacing-96;

    input {
      border: $nb-border-grey-1px;
      background-color: $nb-grey-100;
    }
  }

  .switchMethod {
    @include setPrint($nb-text-sm, $nb-red-700, $nb-font-medium);

    margin-top: $spacing-6;
    padding: $spacing-0;
    min-height: fit-content;
    height: fit-content;
  }

  .verifyCodeFormGroup {
    margin: $spacing-0;

    > div > div {
      margin: $spacing-0;
    }
  }
}

@include mobile {
  .container,
  .container.inModal {
    max-width: 100%;

    .header {
      @include setPrint($nb-text-xl, $nb-grey-900, $nb-font-medium);

      margin: $spacing-0;
      margin-bottom: $spacing-2;
      align-self: flex-start;
    }

    .subHeader {
      @include setPrint($nb-text-sm, $nb-grey-700, $nb-font-regular);

      align-self: flex-start;
    }

    .switchMethod {
      @include setPrint($nb-text-sm, $nb-red-700, $nb-font-medium);

      margin-top: $spacing-4;
      padding: $spacing-0;
    }
  }
}
