@import '~styles/_theme';

.container {
  & > :global(.bp3-input) {
    border: $nb-border-grey-1px;
    border-radius: $rounded-base;
  }

  & :global(.bp3-input-left-container) {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: $spacing-4;
    padding-right: $spacing-3;
  }

  &:global(.bp3-intent-danger) :global(.bp3-input) {
    border: $border-1 solid $nb-red-600;
    border-radius: $rounded-lg;
  }

  .actions {
    padding-top: $spacing-3;
    padding-right: $spacing-4;

    .checkIcon {
      color: $nb-green-700;
    }

    .clearIcon {
      color: $nb-grey-600;
      background: $nb-grey-100;
      padding: 4px;
      border-radius: 9999px;
      margin-left: $spacing-3;
      cursor: pointer;

      &:global(.bp3-icon) {
        color: $nb-grey-600;
        margin: 9px 11px 9px 10px;
      }
    }
  }
}
