@import '~styles/_theme';

.container {
  display: flex;

  .startInput {
    :global(.bp3-input) {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      box-shadow: none;
    }
    margin-right: 1px;
  }

  .endInput {
    :global(.bp3-input) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      box-shadow: none;
    }
  }

  .amPmSelect {
    width: 80px;
    box-shadow: none;
  }

  .leftTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: $nb-spacing-3;
    padding-right: $nb-spacing-3;

    @include setPrint($nb-text-sm, $nb-grey-900, $nb-font-regular);
  }

  :global(.bp3-input-left-container) {
    max-width: 100%;
    height: 100%;
    display: flex;
  }

  .mobileContainer {
    width: 100%;

    & > div:first-of-type {
      margin-bottom: $nb-spacing-4;
    }
    .startContainer,
    .endContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftTxt {
        min-width: 30px;
      }

      .startInput,
      .endInput {
        :global(.bp3-input) {
          border-radius: $nb-spacing-2;
          box-shadow: none;
          text-align: center;
          letter-spacing: $nb-spacing-1;
        }
        margin-right: $nb-spacing-4;
        margin-left: $nb-spacing-4;
      }

      .btnGroupToggle {
        display: flex;

        .leftOption {
          vertical-align: middle;
          border: 1px solid #d7d1d1;
          border-top-left-radius: $nb-spacing-2;
          border-bottom-left-radius: $nb-spacing-2;
          padding: 10px 19px;

          &.selected {
            background-color: $light-violet-100;
            border: 1px solid #9981c5;
          }
        }
        .rightOption {
          vertical-align: middle;
          border: 1px solid #d7d1d1;
          border-top-right-radius: $nb-spacing-2;
          border-bottom-right-radius: $nb-spacing-2;
          padding: 10px 19px;

          &.selected {
            background-color: $light-violet-100;
            border: 1px solid #9981c5;
          }
        }
      }
    }
  }
}
