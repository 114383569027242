@import '~styles/_theme';

.sidebarClassName {
  box-shadow: $nb-shadow-2xl !important;

  @include desktop {
    box-shadow: none !important;
  }
}

.mainContainer {
  min-height: 100vh;
}

.pageContainer {
  display: flex;
  flex-direction: column;

  >div {
    flex: 1;
  }
}

.sidebarContainer {
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  background-color: $nb-white;
  height: 100%;
  width: $spacing-64;
  padding-bottom: $spacing-0;
  overflow: auto;
  z-index: 1;

  &.collapsed {
    width: $spacing-17;
  }
}

.sidebarContentClassName {
  background-color: $nb-warm-grey-200;

  &.withSecondaryNavbar {
    @include tablet {
      overflow-y: hidden !important;
    }
  }
}

.sidebarButtonContainer {
  background-color: $nb-white;
  box-shadow: $nb-inner-1px-bottom-gray-300;
  display: none;
  flex-direction: row;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  bottom: auto;
  width: auto;
  height: $spacing-16;

  .closeIcon {
    svg {
      color: $nb-grey-600;
    }
  }
}

.sidebarAndCloseButtonContainer {
  height: 100%;
  display: flex;

  &.withSecondaryNavbar {
    width: $spacing-77;
  }
}

.loginAsBannerContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  font-size: $text-base;
  padding: 0 20px;
  background: $nb-red-600;
  color: $nb-white;

  .bannerUserInfo {
    span:first-of-type {
      margin-right: 10px;
    }
  }

  .logoutBtn {
    &:hover {
      background: none;
      text-decoration: underline;
      text-decoration-color: $white;
    }

    // stylelint-disable-next-line no-descending-specificity
    span {
      color: $white;
    }
  }

  +.maintenanceAlertContainer {
    top: $spacing-5;
  }
}

.maintenanceAlertContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: $text-sm;
  font-weight: bold;
  padding: 1rem;
  background: $nb-red-200;
  color: $nb-red-800;
  max-height: $spacing-18;
  overflow: hidden;

  a {
    white-space: nowrap;
  }
}

.bottomBannerContainer {
  @include fontStyles($text-base);

  position: fixed;
  bottom: $spacing-6;
  left: $spacing-12;
  width: calc(90% - #{$spacing-12} - #{$spacing-12});
  min-height: $spacing-16;
  background-color: $nb-grey-1000;
  box-shadow: $shadow-lg;
  border-radius: $rounded-base;
  z-index: 2147483001;
  padding: $spacing-3 $spacing-6;
  color: $white;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.dockedSidebar {
    left: $spacing-64 + $spacing-12;
    width: calc(90% - #{$spacing-64} - #{$spacing-12} - #{$spacing-12});
  }

  &.hideBanner {
    display: none;
  }

  .bannerContent {
    padding: $spacing-2 $spacing-0;
    margin-right: $spacing-4;

    p {
      font-weight: bold;
      display: inline;
    }

    a {
      color: $white;

      &:hover {
        color: $white;
        text-decoration: underline;
      }
    }
  }

  .closeBannerBtn {
    background-color: $nb-grey-1000;
    padding: 0;
    box-shadow: $shadow-none !important;

    &:hover {
      background: rgba($white, 0.25);
    }

    // stylelint-disable-next-line no-descending-specificity
    span {
      height: $spacing-6;
    }

    svg {
      color: $white;
    }
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  flex: 1;

  @include mobile {
    justify-content: space-between;
  }

  .logo {
    color: $nb-red-600;
    flex-shrink: 0;

    @include mobile {
      width: 84px;
    }
  }

  .appSearch {
    @include tablet {
      @include tabletAndUp {
        margin-left: $spacing-10;
        margin-right: $spacing-10;
      }
    }
  }
}

/**
* Responsive small screens
* ============================================================================
*/

@include tablet {
  .mainContainer {
    padding-top: $spacing-16;

    &.withSecondaryNavbar {
      padding-top: $spacing-0;
    }
  }

  .sidebarButtonContainer {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    padding: $spacing-0 $spacing-6 $spacing-0 $spacing-5;

    &.hide {
      display: none;
    }

    &.withTopBanner {
      margin-top: 56px;

      @include mobile {
        margin-top: 84px;
      }

      &.withMaintenanceAlert {
        margin-top: $spacing-23;

        @include mobile {
          margin-top: $spacing-23;
        }
      }
    }

    &.withMaintenanceAlert {
      margin-top: $spacing-18;

      @include mobile {
        margin-top: $spacing-18;
      }
    }

    .openSidebarButton {
      margin-right: $spacing-3;

      >span {
        display: flex;
        justify-content: space-between;
        width: 20px;
        height: 12px;
        flex-direction: column;

        >span {
          display: block;
          width: 20px;
          height: 2px;
          position: relative;
          background: $nb-grey-600;
          border-radius: 1px;
        }

        span:last-of-type {
          width: 10px;
        }
      }
    }

    .leftSidebar {
      display: flex;
      align-items: center;
      flex: 1;
      padding-right: $spacing-3;

      button {
        padding-left: $spacing-1;
      }

      .customTitleContainer {
        @include setPrint($text-lg, $nb-grey-900);

        margin: $spacing-0 auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .rightSidebar {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $nb-grey-200;
    }
  }

  .loginAsBannerContainer {
    height: 60px;

    .bannerUserInfo {
      overflow-y: auto;

      // stylelint-disable-next-line no-descending-specificity
      span:first-of-type {
        margin-right: 10px;
      }
    }

    .logoutBtn {
      &:hover {
        background: none;
        text-decoration: underline;
        text-decoration-color: $white;
      }

      // stylelint-disable-next-line no-descending-specificity
      span {
        color: $white;
      }
    }
  }

  .bottomBannerContainer {
    @include fontStyles($text-sm);

    bottom: $spacing-6;
    left: $spacing-3;
    width: calc(80% - #{$spacing-3} - #{$spacing-3});
    min-height: $spacing-16;
    padding: $spacing-3;

    .bannerContent {
      padding: $spacing-0;
    }
  }

  .pageContainer {

    &.withTopBanner,
    &.withMaintenanceAlert {
      margin-top: $spacing-4;

      @include mobile {
        margin-top: $spacing-4;
      }
    }
  }
}