@import '~styles/_theme';

.add svg {
  color: $nb-red-600;
}

.input.transparent {
  box-shadow: none;
  border: none;
  background-color: transparent;
  min-height: 0;

  // TODO: remove override blueprint important
  &:global(.bp3-active),
  &:focus {
    box-shadow: none !important;
    background-color: transparent;
  }

  > :global(.bp3-tag-input-values) {
    margin: 0;

    > * {
      margin-bottom: $spacing-1;
    }
  }

  .add:global(.bp3-button) {
    padding: 0;
    margin: 0;
  }
}

.tag {
  &:global(.bp3-tag.bp3-minimal) {
    padding: 2px 10px;
    font-size: $text-xs;
    min-height: 24px;
  }

  &:global(.bp3-tag.bp3-minimal.bp3-intent-danger) {
    color: $nb-red-900;
    background-color: $nb-red-200;
  }

  &:global(.bp3-tag.bp3-minimal.bp3-intent-primary) {
    color: $nb-grey-900;
    background-color: $nb-warm-grey-200;
  }
}
