@import '~styles/_theme';

.smContainer.smContainer {
  @include setPrint($text-sm, $nb-grey-800, $font-medium);

  display: flex;
  flex-flow: row;
  height: 44px;

  :global {
    label.bp3-control {
      margin: 0;
      padding: 0 !important;
      justify-content: center;
      align-items: center;
      display: flex;
      flex: 1;
      border: $nb-border-grey-1px;
      border-top-width: 1px;
      border-left-width: 0;
      box-shadow: none;
      border-radius: 0;
      transition: color 0.3s, background 0.3s, border-color 0.3;

      &:first-child {
        border-left: $nb-border-grey-1px;
        border-radius: 6px 0 0 6px;

        &::before {
          background: none;
        }
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        color: $nb-red-700;
      }

      // stylelint-disable-next-line no-descending-specificity
      &::before {
        background-color: $nb-rose-400;
      }

      &:local(.selected) {
        background: $nb-button-primary-bg;
        border-color: $nb-button-primary-border-color;

        &::before {
          position: absolute;
          top: -1px;
          left: -1px;
          display: block;
          -webkit-box-sizing: content-box;
          box-sizing: content-box;
          width: 1px;
          height: 100%;
          padding: 1px 0;
          transition: background-color 0.3s;
          content: '';
        }
      }
    }

    .bp3-control-indicator {
      display: none;
    }
  }
}
