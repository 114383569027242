@import '~styles/_colors.module.scss';
@import '~styles/_theme';
@import '~styles/_spacing';
@import '~styles/mixins';

.emptyStateContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  h4.bp3-heading {
    text-align: center;
    margin: 30px auto;
  }
}

.container {
  display: flex;
  flex-direction: column;

  .listPayment {
    margin-bottom: $spacing-6;
  }

  .paginateContainer {
    // align-self: center;
    // width: 100%;

    .pageDetails {
      @media (max-width: 1000px) {
        display: none;

        @include mobile {
          display: block;
        }
      }
    }
  }
}
